<template>
  <GmapMap ref="map" :center="center" :zoom="9" map-type-id="roadmap" style="width: 100%; height: 400px"></GmapMap>
</template>

<script>
import i18n from '@/i18n/i18n';
import { getGoogleMapsAPI } from 'gmap-vue';

export default {
  data() {
    return {
      center: { lat: this.hq_lat || 1.1, lng: this.hq_lng || 1.1 }, // Center map on provided location or default
      map: null,
      marker: null,
      location: null, // Stores the selected location
      google: null,
    };
  },
  props: ['hq_lat', 'hq_lng'],
  async mounted() {
    await this.loadGoogleMaps();
    this.addMapClickListener();
    this.placeInitialMarker();
  },
  methods: {
    async waitForGoogleMapsAPI() {
      return new Promise((resolve) => {
        const checkExist = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100);
      });
    },

    async loadGoogleMaps() {
      try {
        await this.waitForGoogleMapsAPI();
        this.google = await getGoogleMapsAPI();

        // Assign the actual Google Map instance from the ref
        this.map = this.$refs.map.$mapObject;

        this.directionsService = new this.google.maps.DirectionsService();
        this.directionsRenderer = new this.google.maps.DirectionsRenderer({
          map: this.map, // Attach renderer to the map
          suppressMarkers: true, // Prevent default markers
        });
      } catch (error) {
        this.$store.dispatch('showSnackbar', {
          text: i18n.t('snackbar.anErrorOccured'),
          color: 'red',
        });
      }
    },

    addMapClickListener() {
      if (!this.map) return;

      // Add a click listener to the map
      this.map.addListener('click', (event) => {
        const location = event.latLng;
        this.placeMarker(location);
      });
    },

    placeMarker(location) {
      // Convert plain object to google.maps.LatLng if necessary
      const latLng =
        location instanceof this.google.maps.LatLng
          ? location
          : new this.google.maps.LatLng(location.lat, location.lng);

      // Remove the existing marker
      if (this.marker) {
        this.marker.setMap(null);
      }

      // Create a new marker
      this.marker = new this.google.maps.Marker({
        position: latLng,
        map: this.map,
      });

      // Save the location as a plain object
      this.location = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };
    },

    placeInitialMarker() {
      if (this.hq_lat !== null && this.hq_lng !== null) {
        const initialLocation = new this.google.maps.LatLng(this.hq_lat, this.hq_lng);
        this.placeMarker(initialLocation);
      }
    },
  },
};
</script>

<style scoped>
#map {
  border: 1px solid #ddd;
}
</style>
