<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.organisationsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100, 500, 2000, 5000],
            }"
            :items-per-page="50"
            :headers="organisationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="organisations"
            :search="searchString"
            :item-class="addCustomClass"
            class="elevation-1"
            @click:row="editItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.organisations') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.newOrganisation') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>

                    <organisations-form
                      :editedItem="editedItem"
                      :subscriptionTypes="subscriptionTypes"
                      :countries="countries"
                      :referents="referents"
                      :dialog="dialog"
                      @loadAllOrganisations="loadAllOrganisations"
                      @closeDialog="close"></organisations-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogImage" height="auto" width="auto">
                  <v-card>
                    <v-img
                      v-if="itemImage"
                      :src="itemImage"
                      max-height="600"
                      max-width="600"
                      style="background-size: cover"></v-img>
                    <v-img
                      v-else
                      aspect-ratio="2"
                      contain
                      height="250px"
                      src="@/assets/default-user.png"
                      style="background-size: cover"
                      width="250px"></v-img>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDetails(item)">
                        <v-icon class="mx-1" small v-bind="attrs"> mdi-arrow-expand</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showDetails') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showDetails') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showLogo(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-image</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showImage') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showImage') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="saasAdminCompanyUpdate(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-office-building</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.selectOrganisation') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.selectOrganisation') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="toggleDeactivationModal(item)">
                        <v-icon v-if="item.active" class="mx-1" small v-bind="attrs">mdi-toggle-switch-outline</v-icon>
                        <v-icon v-if="!item.active" class="mx-1" small v-bind="attrs"
                          >mdi-toggle-switch-off-outline</v-icon
                        >
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ item.active ? $t('buttons.deactivate') : $t('buttons.activate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ item.active ? $t('tooltips.deactivate') : $t('tooltips.activate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDeleteOrganisationModal(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="detailsModal" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ detailsData.name }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="detailsModal = false"></v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <table class="stats">
              <tbody>
                <tr>
                  <td class="cell-style">{{ $t('organisationsPage.allDrivings') }}</td>
                  <td class="cell-style" style="text-align: center">
                    <b>{{ detailsData.totalDrivingsCount }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="cell-style">{{ $t('organisationsPage.allDrivingsAccepted') }}</td>
                  <td class="cell-style" style="text-align: center">
                    <b>{{ detailsData.acceptedDrivingsCount }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="cell-style">{{ $t('organisationsPage.allDrivingsCanceled') }}</td>
                  <td class="cell-style" style="text-align: center">
                    <b>{{ detailsData.canceledDrivingsCount }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="cell-style">{{ $t('organisationsPage.allVehicles') }}</td>
                  <td class="cell-style" style="text-align: center">
                    <b>{{ detailsData.vehiclesCount }}</b>
                  </td>
                </tr>
                <tr>
                  <td class="cell-style">{{ $t('organisationsPage.allUsers') }}</td>
                  <td class="cell-style" style="text-align: center">
                    <b>{{ detailsData.usersCount }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deactivationModal" max-width="700px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ editedItem.name }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeactivationModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('organisationsPage.deactivateMessage') }}
              <b v-if="editedItem.active" style="color: red">
                {{ $t('organisationsPage.deactivate') }}
              </b>
              <b v-else style="color: green">
                {{ $t('organisationsPage.activate') }}
              </b>
              {{ $t('organisationsPage.organisation') }} {{ editedItem.name }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="editedItem.active" class="primary" text @click="toggleOrganisationsActiveStatus">
            {{ $t('buttons.deactivate') }}
          </v-btn>
          <v-btn v-else class="primary" text @click="toggleOrganisationsActiveStatus">
            {{ $t('buttons.activate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteOrganisationModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('organisationsPage.deleteOrganisation') }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteOrganisationModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('organisationsPage.deleteMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="deleteOrganisation">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { organisationsHeaders } from '@/mixins/data-table-headers';
import { defaultOrganisation } from '@/mixins/default-items';
import OrganisationsForm from '@/components/organisations/OrganisationsForm.vue';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'OrganisationsPage',
  components: { OrganisationsForm },
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    organisations: [],
    subscriptionTypes: [],
    subscriptionTypeFilter: null,
    formTitle: 'organisationsPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    itemImage: null,
    showDetailsModal: false,
    detailsModal: false,
    deactivationModal: false,
    showDeactivationModal: false,
    deleteOrganisationModal: false,
    detailsData: {
      name: '',
    },
    dialogImage: false,
    loading: false,
    vehicleTab: 0,
    countries: [],
    referents: [],
  }),
  created() {
    this.editedItem = Object.assign({}, defaultOrganisation);
    this.loadAllOrganisations();
    this.loadAllSubscriptionTypes();
    this.loadAllCountries();
    this.loadAllReferents();
  },
  computed: {
    organisationsHeaders() {
      return organisationsHeaders(i18n);
    },
  },
  methods: {
    async loadAllOrganisations() {
      let path = '';
      if (this.$store.getters['auth/user'].role.id == 8) {
        path = 'organisations/getAllOrganisationsForSales';
      } else {
        path = 'organisations/getAllOrganisations';
      }

      await this.$store.dispatch(path).then((res) => {
        this.organisations = res.data.map((item) => {
          return {
            ...item,
            active: item.active == '1' ? true : false,
            billing_date: formatDate(item.billing_date),
            trial_ends_at: formatDate(item.trial_ends_at),
            country_id: item.country?.id,
            referent_id: item?.referent_id ? parseInt(item?.referent_id) : null,
            gnetExists: item?.gnet_id ? true : false,
            created_at: formatDate(item.created_at),
            subscription_type_id: parseInt(item.subscription_type_id),
            referral_name: item?.referralUser
              ? item?.referralUser?.username + '(' + item?.referralUser?.organisation.name + ')'
              : '',
          };
        });
      });
    },
    async loadAllSubscriptionTypes() {
      await this.$store.dispatch('subscriptionPackages/getAllPackages').then((res) => {
        this.subscriptionTypes = res.data;
      });
    },
    async loadAllCountries() {
      await this.$store.dispatch('pricingRegions/getAllCountries').then((res) => {
        this.countries = res.data;
      });
    },
    async loadAllReferents() {
      await this.$store.dispatch('organisations/getAllReferents').then((res) => {
        this.referents = res.data;
      });
    },

    editItem(item) {
      this.editedItem = this.organisations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async deleteOrganisation() {
      this.loading = true;

      await this.$store
        .dispatch('organisations/deleteOrganisation', this.editedItemDeleted)
        .then(() => {
          this.loadAllOrganisations();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closeDeleteOrganisationModal();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultOrganisation);
      });
      this.formTitle = 'organisationsPage.newItem';
      this.vehicleTab = 0;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultOrganisation);
      });
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },

    showLogo(item) {
      this.dialogImage = true;
      if (item.logo_path) {
        if (!item.logo_path.includes('https://') && !item.logo_path.includes('http://')) {
          this.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.logo_path);
          this.itemImage = this.itemImage.replace('public', 'storage');
        } else {
          this.itemImage = item.logo_path;
        }
      } else {
        this.itemImage = null;
      }
    },
    closeShowDetailsModal() {
      this.detailsModal = false;
    },
    showDeleteOrganisationModal(item) {
      this.editedItemDeleted = item;
      this.deleteOrganisationModal = true;
    },

    closeDeleteOrganisationModal() {
      this.deleteOrganisationModal = false;
    },
    async showDetails(item) {
      this.detailsData.name = item.name;
      try {
        let response = await this.$store.dispatch('organisations/loadOrganisationViewOnlyData', item.id);
        this.detailsData.totalDrivingsCount = response.total;
        this.detailsData.acceptedDrivingsCount = response.accepted;
        this.detailsData.canceledDrivingsCount = response.canceled;
        this.detailsData.vehiclesCount = response.vehicles;
        this.detailsData.usersCount = response.users;
        this.detailsModal = true;
      } catch (e) {
        this.$store.dispatch('showSnackbar', { text: e.response.data.message, color: 'red' });
      }
    },
    async toggleOrganisationsActiveStatus() {
      try {
        await this.$store.dispatch('organisations/toggleOrganisationsActiveStatus', this.editedItem);
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
        this.editedItem.active = !this.editedItem.active;
        this.loadAllOrganisations();
        this.deactivationModal = false;
      } catch (e) {
        this.$store.dispatch('showSnackbar', { text: e.response.data.message, color: 'red' });
      }
    },
    toggleDeactivationModal(item) {
      this.editedItem = item;
      this.deactivationModal = true;
    },
    closeDeactivationModal() {
      this.deactivationModal = false;
      this.editedItem = Object.assign({}, defaultOrganisation);
    },

    saasAdminCompanyUpdate(organisation) {
      localStorage.removeItem('licenceLastCheck');
      this.$store
        .dispatch('organisations/assignSaasAdminOrganisation', { organisation_id: organisation.id })
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationChanged'), color: 'green' });
            res.user.role_id = parseInt(res.user.role.id);
            this.$store.dispatch('auth/setUser', res.user);
            localStorage.setItem('LimoExpressUser', JSON.stringify(res.user));
            this.$router.go(0);
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.changeFailed'), color: 'red' });
        });
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.close();
      }
    },
    detailsModal(val) {
      val || this.closeShowDetailsModal();
    },
    deactivationModal(val) {
      val || this.closeDeactivationModal();
    },
  },
};
</script>

<style>
.stats {
  font-family: 'Inter', 'Roboto', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
