<template>
  <div>
    <v-menu open-on-hover open-on-click bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" v-bind="attrs" v-on="on">
          {{ $t('buttons.add') }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="newPricingZoneDialog = true">
          <v-list-item-title>
            {{ $t('buttons.createNew') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="clonePricingZoneDialog = true">
          <v-list-item-title>
            {{ $t('buttons.cloneExisting') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="newPricingZoneDialog" max-width="800px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t(pricingZoneRelationFormTitle) }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeNewPricingZoneRelationEdit"></v-icon>
        </v-card-title>

        <validation-observer ref="formNew" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(savePricingZoneRelation)">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="4" sm="12">
                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="zone_one">
                      <v-autocomplete
                        v-model="editedPricingZoneRelationItem.zone_one_id"
                        :items="pricingZones"
                        clearable
                        :label="$t('pricingZoneRelationsPage.zoneOne')"
                        :no-data-text="$t('select.noDataAvailable')"
                        @focus="$event.target.click()"
                        :error-messages="errors"
                        item-value="id"
                        item-text="name">
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <validation-provider rules="required|max:200" v-slot="{ errors }" name="zone_two">
                      <v-autocomplete
                        v-model="editedPricingZoneRelationItem.zone_two_id"
                        :items="pricingZones"
                        clearable
                        :label="$t('pricingZoneRelationsPage.zoneTwo')"
                        :no-data-text="$t('select.noDataAvailable')"
                        @focus="$event.target.click()"
                        :error-messages="errors"
                        item-value="id"
                        item-text="name">
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-autocomplete
                      v-model="editedPricingZoneRelationItem.client_id"
                      :items="clients"
                      clearable
                      item-text="name"
                      item-value="id"
                      :label="$t('pricingZoneRelationsPage.chooseClient')"
                      :no-data-text="$t('select.noDataAvailable')"
                      class="clients-filter mr-5"
                      @focus="$event.target.click()"></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                    v-if="editedPricingZoneRelationItem.zone_one_id && editedPricingZoneRelationItem.zone_two_id">
                    <v-card class="px-5 my-5" v-for="(currency, currencyIndex) in currencies" :key="currencyIndex">
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <span> {{ getCurrencyName(currency.id) }} </span>
                        </v-col>

                        <v-col
                          cols="12"
                          lg="4"
                          md="4"
                          sm="4"
                          v-for="(item, index) in filteredPrices(
                            editedPricingZoneRelationItem.zonePricingRelationPrices,
                            currency
                          )"
                          :key="'price' + index">
                          <validation-provider
                            rules="required|numeric_decimal|max:200"
                            v-slot="{ errors }"
                            :name="'pricing_zone' + index"
                            :custom-messages="{
                              required: $t('customValidations.fieldRequired', {
                                field: $t('names.price'),
                              }),
                              max: $t('customValidations.fieldLengthMax', {
                                field: $t('names.price'),
                                length: 200,
                              }),
                              numeric_decimal: $t('customValidations.numericDecimal', {
                                field: $t('names.price'),
                                length: 200,
                              }),
                            }">
                            <v-text-field
                              v-model="item.price"
                              clearable
                              counter="200"
                              name="price"
                              :label="getVehicleClassName(item.vehicle_class_id)"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clonePricingZoneDialog" max-width="800px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t(pricingZoneRelationFormTitle) }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeClonePricingZoneRelationEdit"></v-icon>
        </v-card-title>

        <validation-observer ref="formClone" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(clonePricingZoneRelation)">
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- First Section: Checkboxes (Scrollable) -->
                  <v-col cols="12" sm="7">
                    <strong class="info-label d-block mb-1">
                      {{ $t('pricingZoneRelationsPage.zoneRelations') }}
                    </strong>
                    <div class="section-border scrollable-checkboxes" :key="clientCloneFrom">
                      <!-- Select All Checkbox -->
                      <v-checkbox
                        v-model="selectAll"
                        :label="$t('buttons.selectAll')"
                        @change="toggleSelectAll"
                        :disabled="pricingZoneRelations.length === 0"
                        hide-details></v-checkbox>

                      <hr />

                      <!-- Individual Checkboxes -->
                      <validation-provider rules="required" v-slot="{ errors }" name="zones">
                        <div>
                          <v-checkbox
                            v-for="item in pricingZoneRelations"
                            :key="item.id"
                            v-model="clonedPricingZoneRelationItem.zone_pricing_relation_ids"
                            :label="item.name"
                            :value="item.id"
                            hide-details></v-checkbox>
                          <div v-if="errors[0]" v class="v-text-field__details">
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </validation-provider>
                    </div>
                  </v-col>

                  <!-- Second Section: Choose Client From and To -->
                  <v-col cols="12" sm="5">
                    <strong class="info-label d-block mb-1">
                      {{ $t('pricingZoneRelationsPage.fromTo') }}
                    </strong>
                    <div class="section-border">
                      <v-autocomplete
                        v-model="clientCloneFrom"
                        :items="clients"
                        clearable
                        item-text="name"
                        item-value="id"
                        :label="$t('pricingZoneRelationsPage.chooseClientFrom')"
                        :no-data-text="$t('select.noDataAvailable')"
                        class="clients-filter my-0"
                        @focus="$event.target.click()"></v-autocomplete>

                      <validation-provider rules="required" v-slot="{ errors }" name="client">
                        <v-autocomplete
                          v-model="clonedPricingZoneRelationItem.client_id"
                          :items="clients"
                          clearable
                          item-text="name"
                          item-value="id"
                          :label="$t('pricingZoneRelationsPage.chooseClientTo')"
                          :no-data-text="$t('select.noDataAvailable')"
                          class="clients-filter my-3"
                          @focus="$event.target.click()"
                          :error-messages="errors"></v-autocomplete>
                      </validation-provider>

                      <validation-provider
                        rules="numeric_decimal|min_value:0|max_value:100"
                        v-slot="{ errors }"
                        name="percentage">
                        <v-text-field
                          v-model="clonedPricingZoneRelationItem.percentage"
                          name="percentage"
                          clearable
                          :label="$t('pricingZoneRelationsPage.discountPercentage')"
                          type="text"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <button-submit :failed="failed" :loading="loading" buttonText="buttons.clone"></button-submit>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultPricingZoneRelation, defaultPricingZoneRelationClone } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'PricingZoneRelationsPage',
  components: { ButtonSubmit },
  props: ['selectedItem', 'clients', 'currencies', 'vehicleClasses', 'pricingZones', 'clientFilter'],
  data() {
    return {
      editedPricingZoneRelationItem: {},
      clonedPricingZoneRelationItem: {},
      newPricingZoneDialog: false,
      clonePricingZoneDialog: false,
      pricingZoneRelationFormTitle: 'pricingZoneRelationsPage.newItem',
      loading: false,
      clientCloneFrom: '',
      clientCloneTo: '',
      pricingZoneRelations: [],
      selectAll: false,
    };
  },
  async created() {
    this.editedPricingZoneRelationItem = Object.assign(
      {},
      { ...defaultPricingZoneRelation, zonePricingRelationPrices: [] }
    );
    this.clonedPricingZoneRelationItem = Object.assign({}, { ...defaultPricingZoneRelationClone });
  },
  computed: {},
  methods: {
    async loadAllPricingZoneRelations(data) {
      await this.$store.dispatch('zonePricing/getAllPricingZoneRelations', data).then((res) => {
        this.pricingZoneRelations = res.data.map((e) => {
          e.name = e.zone_one?.name + ' - ' + e.zone_two?.name;

          return e;
        });
      });
    },

    toggleSelectAll() {
      if (this.selectAll) {
        // Select all items
        this.clonedPricingZoneRelationItem.zone_pricing_relation_ids = this.pricingZoneRelations.map((item) => item.id);
      } else {
        // Deselect all items
        this.clonedPricingZoneRelationItem.zone_pricing_relation_ids = [];
      }
    },

    editPricingZoneRelationItem(item) {
      this.editedPricingZoneRelationItem = Object.assign({}, item);
      this.pricingZoneRelationFormTitle = item.name;
      this.newPricingZoneDialog = true;
    },

    async savePricingZoneRelation() {
      this.loading = true;
      let path = 'savePricingZoneRelation';

      await this.$store
        .dispatch('zonePricing/' + path, this.editedPricingZoneRelationItem)
        .then(() => {
          this.$emit('loadAllPricingZoneRelations');
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeNewPricingZoneRelationEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.code)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
          this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
        });
    },

    closeNewPricingZoneRelationEdit() {
      this.newPricingZoneDialog = false;
      this.$nextTick(() => {
        this.editedPricingZoneRelationItem = Object.assign(
          {},
          { ...defaultPricingZoneRelation, zonePricingRelationPrices: [] }
        );
      });
      this.$refs.formNew.reset();
      this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
    },

    createClonePayload() {
      return {
        client_id: this.clonedPricingZoneRelationItem.client_id,
        zone_pricing_relation_ids: this.clonedPricingZoneRelationItem.zone_pricing_relation_ids,
        percentage: this.clonedPricingZoneRelationItem.percentage
          ? parseFloat(this.clonedPricingZoneRelationItem.percentage)
          : 0,
      };
    },

    async clonePricingZoneRelation() {
      this.loading = true;
      let path = 'clonePricingZoneRelation';

      let payload = this.createClonePayload();

      await this.$store
        .dispatch('zonePricing/' + path, payload)
        .then(() => {
          this.$emit('loadAllPricingZoneRelations');
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeClonePricingZoneRelationEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
          this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
        });
    },

    closeClonePricingZoneRelationEdit() {
      this.clonePricingZoneDialog = false;
      this.$nextTick(() => {
        this.clonedPricingZoneRelationItem = Object.assign({}, { ...defaultPricingZoneRelationClone });
      });
      this.$refs.formClone.reset();
      this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
      this.clientCloneFrom = '';
    },

    handleZoneSelected() {
      if (
        this.editedPricingZoneRelationItem.zone_one_id &&
        this.editedPricingZoneRelationItem.zone_two_id &&
        !this.editedPricingZoneRelationItem.id
      ) {
        this.editedPricingZoneRelationItem.zonePricingRelationPrices = [];
        this.currencies.forEach((currency) => {
          this.vehicleClasses.forEach((vehicleClass) => {
            this.editedPricingZoneRelationItem.zonePricingRelationPrices.push({
              id: null,
              currency_id: currency.id,
              vehicle_class_id: vehicleClass.id,
              price: 0,
            });
          });
        });
      }
    },

    getCurrencyName(item) {
      let index = this.currencies.findIndex((x) => x.id == item);
      return this.currencies[index]?.name
        ? this.currencies[index]?.name + '(' + this.currencies[index]?.symbol + ')'
        : '';
    },

    getVehicleClassName(item) {
      let index = this.vehicleClasses.findIndex((x) => x.id == item);
      return this.vehicleClasses[index]?.name ? this.vehicleClasses[index]?.name : '';
    },

    filteredPrices(prices, currency) {
      return prices.filter((item) => item.currency_id == currency.id);
    },
  },

  watch: {
    newPricingZoneDialog(val) {
      if (val) {
        if (!this.editedPricingZoneRelationItem.id && this.clientFilter) {
          this.editedPricingZoneRelationItem.client_id = this.clientFilter;
        }
      } else {
        this.closeNewPricingZoneRelationEdit();
      }
    },
    clonePricingZoneDialog(val) {
      if (val) {
        this.clientCloneFrom = this.clientFilter;
        this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.cloneItem';
      } else {
        this.closeClonePricingZoneRelationEdit();
      }
    },
    'editedPricingZoneRelationItem.zone_one_id': {
      handler() {
        this.handleZoneSelected();
      },
      deep: false,
    },
    'editedPricingZoneRelationItem.zone_two_id': {
      handler() {
        this.handleZoneSelected();
      },
      deep: false,
    },
    selectedItem: {
      handler() {
        this.editPricingZoneRelationItem(this.selectedItem);
      },
    },
    clientCloneFrom: {
      handler() {
        if (this.clientCloneFrom) {
          this.loadAllPricingZoneRelations({ client_id: this.clientCloneFrom || '' });
        } else {
          this.loadAllPricingZoneRelations({ global_only: 1 || '' });
        }
        // Unselect select all to keep old
        this.selectAll = false;
        this.clonedPricingZoneRelationItem.zone_pricing_relation_ids = [];
      },
    },
    // Watch for changes in selected checkboxes to update "Select All" state
    'clonedPricingZoneRelationItem.zone_pricing_relation_ids'(newVal) {
      this.selectAll = newVal.length === this.pricingZoneRelations.length && newVal.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
.section-border {
  border: 1px solid #ddd; /* Light gray border */
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px; /* Rounded corners */

  .v-input {
    margin: 0.5rem 0;
  }

  hr {
    height: 1px;
    color: #ddd;
  }
}

.scrollable-checkboxes {
  max-height: 600px; /* Set maximum height for the checkboxes section */
  overflow-y: auto; /* Enable vertical scrolling */
}

.clients-filter {
  width: 100%;
  max-width: unset;
}
</style>
