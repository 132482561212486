import {
  loadDrivingDetails,
  getAllVehicleClasses,
  getOrganisationCurrencies,
  saveDriving,
  cancelDriving,
  getAllInvoices,
  getDriversGpsData,
  requestDriverLocation,
  searchPassengers,
} from '@/api/customers';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async loadDrivingDetails(state, item) {
      return loadDrivingDetails(item);
    },
    async getAllVehicleClasses(state, item) {
      return getAllVehicleClasses(item);
    },
    async getOrganisationCurrencies(state, item) {
      return getOrganisationCurrencies(item);
    },
    async saveDriving(state, item) {
      return saveDriving(item);
    },
    async cancelDriving(state, item) {
      return cancelDriving(item);
    },
    async getAllInvoices(state, item) {
      return getAllInvoices(item);
    },
    async getDriversGpsData(state, item) {
      return getDriversGpsData(item);
    },
    async requestDriverLocation(state, item) {
      return requestDriverLocation(item);
    },
    async searchPassengers(state, item) {
      return searchPassengers(item);
    },
  },
  getters: {},
};
