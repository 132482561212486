import {
  calculatePriceViaZonePricing,
  calculatePriceViaDistanceScales,
  calculatePriceViaZonePricingCustomer,
} from '@/api/priceCalculations';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async calculatePriceViaZonePricing(state, item) {
      return calculatePriceViaZonePricing(item);
    },
    async calculatePriceViaDistanceScales(state, item) {
      return calculatePriceViaDistanceScales(item);
    },
    async calculatePriceViaZonePricingCustomer(state, item) {
      return calculatePriceViaZonePricingCustomer(item);
    },
  },
  getters: {},
};
