import { getHttpClient } from './client';

export const getAllDrivings = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving';
  let queryString = '?';
  if (data) {
    if (data.from) {
      queryString += `from=${data.from}`;
    }
    if (data.to) {
      queryString += `&to=${data.to}`;
    }
    if (data.vehicle_id) {
      queryString += `&vehicle_id=${data.vehicle_id}`;
    }
    if (data.driver_id) {
      queryString += `&driver_id=${data.driver_id}`;
    }
    if (data.client_id) {
      queryString += `&client_id=${data.client_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

// WE USE THIS API TO GET DRIVING DATA, BUT SHORTET VERSION THEN getAllDrivings
export const getAllDrivingsData = async () => {
  let baseUrl = process.env.VUE_APP_API_URL + '/all-drivings';

  return await getHttpClient(baseUrl).then((response) => response.data);
};

export const getAllCustomerDrivings = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/driving';
  let queryString = '?';
  if (data) {
    if (data.from) {
      queryString += `from=${data.from}`;
    }
    if (data.to) {
      queryString += `&to=${data.to}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllDrivingsForClient = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving';
  let queryString = '?';
  if (data) {
    if (data.client_id) {
      queryString += `client_id=${data.client_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllDrivingsForInvoices = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/drivings/invoice-create';
  let queryString = '?';
  if (data) {
    if (data.client_id) {
      queryString += `client_id=${data.client_id}`;
    }
    if (data.all) {
      queryString += `&all=${data.all}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving/' + data).then((response) => response.data);
};

export const saveDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/save-driving-and-proposal', 'POST', data).then(
    (response) => response.data
  );
};

export const sendPublicReservation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/public/booking', 'POST', data).then(
    (response) => response.data
  );
};

export const getPublicBookingData = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/public/booking-data/' + data.id).then(
    (response) => response.data
  );
};

export const getVehicleClassPrices = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/public/prices';

  let queryString = '?';
  if (data) {
    if (data.public_booking_id) {
      queryString += `public_booking_id=${data.public_booking_id}`;
    }
    if (data.from_lat) {
      queryString += `&from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.distance) {
      queryString += `&distance=${data.distance}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const updateDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving', 'POST', data).then((response) => response.data);
};

export const deleteDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const getDrivingProposals = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving-proposal';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveDrivingProposal = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/driving-proposal';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const cancelDrivingProposal = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/cancel-driving-proposal/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const cancelDriving = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/cancel-driving/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const finishDriving = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/drivings/mark-as-done';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const markAsStarted = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/drivings/mark-as-started/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const noShowDriving = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/drivings/mark-as-no-show';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const deleteDrivingAttachment = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/delete-driving-attachment/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const saveDrivingPassenger = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/passenger';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const deleteDrivingCheckpoint = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/checkpoint/' + data.id;
  return await getHttpClient(baseUrl, 'DELETE', data).then((response) => response.data);
};

export const deleteDrivingPassenger = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/delete-driving-has-passenger';
  return await getHttpClient(baseUrl, 'DELETE', data).then((response) => response.data);
};

export const generateContract = async (id) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/generate-passenger-list/' + id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const generateDrivingOrder = async (id) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/generate-order-pdf/' + id;
  return await getHttpClient(baseUrl, 'GET').then((response) => response.data);
};

export const printInvoice = async (id) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/generate-order-invoice/' + id;
  return await getHttpClient(baseUrl, 'GET').then((response) => response.data);
};

export const exportExcel = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/export-drivings';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const userExportPreferences = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/user-export-preferences';
  return await getHttpClient(baseUrl, 'GET', data).then((response) => response.data);
};

export const getFlightInfo = async (number) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/track-flight/' + number;
  return await getHttpClient(baseUrl).then((response) => response.data);
};

export const generateDrivingPaymentLink = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/stripe/generate-driving-checkout-link';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const sendSms = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/send-sms';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};

export const updateAffiliateDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/affiliate/update-parent-driving', 'POST', data).then(
    (response) => response.data
  );
};
