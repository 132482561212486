import { deletePassenger, getAllPassengers, savePassenger, updatePassenger, searchPassengers } from '@/api/passengers';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllPassengers(state, item) {
      return getAllPassengers(item);
    },
    async savePassenger(state, item) {
      return savePassenger(item);
    },
    async updatePassenger(state, item) {
      return updatePassenger(item);
    },
    async deletePassenger(state, item) {
      return deletePassenger(item);
    },
    async searchPassengers(state, item) {
      return searchPassengers(item);
    },
  },
  getters: {},
};
