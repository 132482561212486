export default {
  sidebar: {
    fleet: 'Flotta',
    vehicles: 'Veicoli',
    vehiclesClasses: 'Classi dei veicoli',
    administration: 'Amministrazione',
    currencies: 'Valute',
    currenciesAdministration: 'Amministrazione delle valute',
    paymentTypes: 'Metodi di pagamento',
    paymentTypesAdministration: 'Amministrazione dei metodi di pagamento',
    users: 'Utenti',
    usersAdministration: 'Amministrazione degli utenti',
    clients: 'Clienti',
    clientsAdministration: 'Amministrazione dei clienti',
    organizations: 'Organizzazioni',
    organizationsAdministration: 'Amministrazione delle organizzazioni',
    saasAdmin: 'Amministrazione Saas',
    administrators: 'Amministratori',
    saasAdminAdministration: "Amministrazione dell'amministratore Saas",
    statistics: 'Statistiche',
    generalOverview: 'Report generale',
    payment: 'Pagamento',
    pricingRegions: 'Regioni di tariffazione',
    pricingRegionsAdministration: 'Amministrazione regioni di tariffazione',
    help: 'Aiuto',
    drivings: 'Ordini di guida',
    calendar: 'Calendario',
    tabularView: 'Vista tabellare',
    expenses: 'Costi',
    vehicleExpensesAdministration: 'Amministrazione dei costi del veicolo',
    vehicleMaintenance: 'Malfunzionamenti del veicolo',
    vehicleMaintenanceAdministration: 'Amministrazione dei malfunzionamenti del veicolo',
    organization: 'Organizzazione',
    organizationAdminstration: "Amministrazione dell'organizzazione",
    packages: 'Pacchetti',
    packagesChange: 'Cambia pacchetti',
    logout: 'Disconnettersi',
    logoutText: 'Sei sicuro di voler uscire?',
    unaccessibleRoutes: 'Non puoi accedere a questa pagina. Aggiorna il pacchetto per avere accesso completo.',
    transactions: 'Transazioni',
    gpsMap: 'Mappa GPS',
    team: 'Squadra',
    teamAdministration: 'Amministrazione della squadra',
    invoices: 'Fatture',
    invoicesAdministration: 'Gestione delle fatture',
    drivingOffers: 'Offerte',
    drivingOffersAdministration: 'Gestione delle offerte',
    dashboard: 'Cruscotto',
    dashboardAdministration: 'Amministrazione del cruscotto',
    resourceOverview: 'Panoramica delle risorse',
    resourceOverviewAdministration: 'Amministrazione della panoramica delle risorse',
    rides: 'Corse',
    passengerReviews: 'Recensioni dei passeggeri',
    passengerReviewsAdministration: 'Amministrazione delle recensioni dei passeggeri',
    pricingAndRates: 'Prezzi e tariffe',
    pricingZones: 'Zone e aree',
    pricingZonesAdministration: 'Amministrazione di zone e aree',
    pricingPerZones: 'Prezzi per zone',
    pricingPerZonesAdministration: 'Amministrazione dei prezzi per zone',
    pricingPerHour: 'Prezzi all’ora',
    pricingPerHourAdministration: 'Amministrazione dei prezzi all’ora',
    availability: 'Disponibilità',
    availabilityAdministration: 'Disponibilità di conducenti e veicoli',
    distanceScales: 'Scale di distanza',
    distanceScalesAdministration: 'Gestione delle scale di distanza',
    passengers: 'Passeggeri',
    passengersAdministration: 'Gestione dei passeggeri',
  },
  header: {
    organization: 'Organizzazione',
    newDriving: 'Ordine di guida',
    dailyRent: 'Noleggio giornaliero',
    transfer: 'Trasferimento',
    notifications: 'Notifiche',
    changeLanguage: 'Cambia lingua',
    help: 'Aiuto',
  },
  footer: {
    poweredBy: 'Powered by',
    subscriptionTo: 'La tua sottoscrizione a',
    trialEndsAt: 'l tuo periodo di prova per',
    packageExpiresIn: 'Il pacchetto scade in',
    clickToChangePackage: 'Clicca per cambiare pacchetto',
    clickToActivatePackage: 'Clicca per attivare il pacchetto',
    clickToPay: 'Clicca per il pagamento',
    packageHasExpired: 'pacchetto è scaduto',
    affiliateUser: 'Se desideri sbloccare tutte le funzionalità della piattaforma, è necessario abbonarsi ',
    here: 'QUI',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Sì',
    no: 'No',
    save: 'Salva',
    send: 'Invia',
    sendMessage: 'Invia messaggio',
    edit: 'Modifica',
    add: 'Aggiungi',
    confirm: 'Conferma',
    cancel: 'Annulla',
    cancelDriving: 'Annulla guida',
    markAsDone: 'Contrassegna come completato',
    markAsNoShow: 'Segna come no show',
    deleteDriving: 'Elimina guida',
    newOrganisation: 'Nuova organizzazione',
    changePackage: 'Cambia pacchetti',
    downloadStats: 'Scarica statistiche',
    downloadPDF: 'Scarica PDF',
    today: 'Oggi',
    choose: 'Scegli',
    login: 'Accedi',
    generateFile: 'Genera contratto',
    generateDrivingOrder: 'Stampa ordine di guida',
    downloadFile: 'Scarica file',
    activate: 'Attiva',
    deactivate: 'Disattiva',
    showImage: 'Mostra immagine',
    showDetails: 'Mostra dettagli',
    actions: 'Azioni',
    delete: 'Elimina',
    open: 'Apri',
    show: 'Mostra',
    options: 'Opzioni',
    prev: 'Precedente',
    next: 'Successivo',
    skip: 'Salta',
    finish: 'Termina',
    settings: 'Impostazioni',
    cancelSubscription: 'Annulla abbonamento',
    backToEditOrganization: 'Torna a modifica organizzazione',
    payNow: 'Paga ora',
    back: 'Torna indietro',
    integrationRoutes: 'Itinerari di integrazione',
    transactions: 'Transazioni',
    articlesOfLaw: 'Articoli di legge',
    export: 'Esporta',
    duplicate: 'Fare una copia',
    makeReturnTrip: 'Fare un viaggio di ritorno',
    selectOrganisation: 'Seleziona organizzazione',
    advancedSettings: 'Impostazioni avanzate',
    accept: 'Accettare',
    reject: 'Rifiutare',
    sendViaGnet: 'Invia tramite GNET',
    printInvoice: 'Stampa fattura',
    enable: 'Abilita',
    disable: 'Disabilita',
    generatePaymentLink: 'Genera link di pagamento',
    addItem: '+ Aggiungi elemento',
    visitWebsite: 'Visita il nostro sito web',
    buyMoreSms: 'Acquista più SMS',
    forward: 'Inoltra',
    sendViaAffiliate: 'Invia tramite Affiliate',
    markInvoiceAsPaid: 'Segna fattura come pagata',
    markDrivingOfferAsPaid: 'Segna l’offerta di guida come pagata',
    selectAll: 'Seleziona tutto',
    viaAffiliateId: 'Aggiungi tramite ID affiliato',
    registerPartner: 'Registrare partner',
    createNew: 'Crea nuovo',
    clone: 'Clona',
    cloneExisting: 'Clona esistente',
    copyLink: 'Copia link',
    markAsStarted: 'Segna come iniziato',
  },
  tooltips: {
    save: 'Salva',
    edit: 'Modifica',
    delete: 'Elimina',
    showImage: 'Mostra immagine',
    open: 'Apri',
    show: 'Mostra',
    showDetails: 'Mostra dettagli ',
    downloadFile: 'Scarica file',
    generateFile: 'Genera contratto',
    generateDrivingOrder: 'Stampa ordine di guida',
    activate: 'Attiva',
    deactivate: 'Disattiva',
    cancel: 'Annulla',
    cancelDriving: 'Annulla guida',
    cannotMarkAsCanceled: "L'ordine non può essere contrassegnato come annullato perché è già stato accettato",
    markAsDone: 'Contrassegna come completato',
    cannotMarkAsDone: "L'ordine non può essere marcato come completato, perché non si è ancora verificato",
    markAsNoShow: 'Segna come no show',
    cannotMarkAsNoShow: "L'ordine non può essere contrassegnato come no show, perché non è ancora avvenuto",
    deleteDriving: 'Elimina guida',
    cannotDelete:
      'Ci dispiace, ma per motivi di sicurezza, gli utenti non possono essere eliminati mentre sono attualmente connessi.',
    duplicate: 'Fare una copia',
    makeReturnTrip: 'Fare un viaggio di ritorno',
    refresh: 'Aggiorna',
    selectOrganisation: 'Seleziona organizzazione',
    sendViaGnet: 'Invia tramite GNET',
    receivedViaGnet: 'Ricevuto tramite GNET',
    sentViaGnet: 'Inviato tramite GNET',
    printInvoice: 'Stampa fattura',
    optionsDisabled: "È necessario rifiutare o accettare l'ordine per accedere al menu delle opzioni",
    cannotMakeReturnTrip: 'Non è possibile fare un viaggio di ritorno per il noleggio giornaliero',
    cannotSendGnetOrderViaGnet: "Non è possibile inviare l'ordine tramite Gnet",
    cannotSendViaGnetWithoutGnetId: "Non è possibile inviare l'ordine tramite Gnet senza ID Gnet",
    cannotPrintInvoiceWithoutClient: 'Non è possibile stampare la fattura senza selezionare un cliente',
    cannotMarkAsDoneStatusRejected:
      "L'ordine non può essere contrassegnato come completato perché è già stato contrassegnato come rifiutato",
    cannotMarkAsDoneStatusCanceled:
      "L'ordine non può essere contrassegnato come completato perché è già stato contrassegnato come annullato",
    cannotMarkAsDoneStatusDone:
      "L'ordine non può essere contrassegnato come completato perché è già stato contrassegnato come completato",
    cannotMarkAsDoneStatusNoShow:
      "L'ordine non può essere contrassegnato come completato perché è già stato contrassegnato come mancata presentazione",
    cannotMarkAsNoShowStatusRejected:
      "L'ordine non può essere contrassegnato come mancata presentazione perché è già stato contrassegnato come rifiutato",
    cannotMarkAsNoShowStatusCanceled:
      "L'ordine non può essere contrassegnato come mancata presentazione perché è già stato contrassegnato come annullato",
    cannotMarkAsNoShowStatusDone:
      "L'ordine non può essere contrassegnato come mancata presentazione perché è già stato contrassegnato come completato",
    cannotMarkAsNoShowStatusNoShow:
      "L'ordine non può essere contrassegnato come mancata presentazione perché è già stato contrassegnato come mancata presentazione",
    cannotMarkAsCanceledGnet:
      "L'ordine non può essere contrassegnato come annullato perché è stato ricevuto tramite Gnet",
    cannotMarkAsCanceledStatusRejected:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come rifiutato",
    cannotMarkAsCanceledStatusExpired:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come scaduto",
    cannotMarkAsCanceledStatusCanceled:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come annullato",
    cannotMarkAsCanceledStatusDraft:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come bozza",
    cannotMarkAsCanceledStatusCompleted:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come completato",
    cannotMarkAsCanceledStatusNoShow:
      "L'ordine non può essere contrassegnato come annullato perché è già stato contrassegnato come mancata presentazione",
    cannotDeleteDrivingSentViaGnet: "L'ordine non può essere eliminato perché è stato inviato tramite Gnet",
    cannotDeleteDrivingReceivedViaGnet:
      "L'ordine non può essere eliminato perché è stato ricevuto tramite Gnet e accettato",
    generatePaymentLink: 'Genera link di pagamento',
    cannotGeneratePaymentLInk: 'Il link di pagamento non può essere generato perché non hai attivato Stripe',
    disabledExport: 'Puoi esportare i dati una volta ogni 24 ore',
    sendSms: 'Invia SMS',
    accept: 'Accetta',
    reject: 'Rifiuta',
    sendViaAffiliate: 'Invia tramite Affiliate',
    receivedViaAffiliate: 'Ricevuto tramite Affiliate',
    sentViaAffiliate: 'Inviato tramite Affiliate',
    cannotSendViaAffiliate: "Non puoi inviare l'ordine di guida tramite affiliate.",
    cannotForwardDrivingOrder: 'Non puoi inoltrare un ordine di guida che è nel passato.',
    cannotMarkAsCanceledAffiliate:
      'L’ordine di guida non può essere contrassegnato come annullato perché è stato ricevuto tramite affiliate.',
    cannotMarkAsCanceled12Hours:
      "L'ordine non può essere contrassegnato come annullato perché sono trascorse 12 ore dall'orario di ritiro.",
    cannotDeleteActiveDriving: 'L’ordine di guida attivo non può essere eliminato.',
    noImageToShow: 'Nessuna immagine da mostrare',
    disabledForAffiliateUser:
      "Il tuo account è di tipo 'Affiliate'. Questa azione è disponibile per gli account [Regolari] abbonati. Vai al link nel piè di pagina per iscriverti.",
    markInvoiceAsPaid: 'Segna fattura come pagata',
    markDrivingOfferAsPaid: 'Segna l’offerta di guida come pagata',
    invoiceIsPaid: 'La fattura è già contrassegnata come pagata',
    drivingOfferIsPaid: 'L’offerta di guida è già contrassegnata come pagata',
    selectAreaToDelete: 'Seleziona l’area che desideri eliminare',
    deleteSelectedArea: 'Elimina l’area selezionata',
    markAsStarted: 'Segna come iniziato',
    cannotMarkAsStarted: "L'ordine non può essere contrassegnato come iniziato perché non è ancora avvenuto",
    cannotMarkAsStartedStatusStarted:
      "L'ordine non può essere contrassegnato come iniziato perché è già stato contrassegnato come iniziato",
    cannotMarkAsStartedStatusRejected:
      "L'ordine non può essere contrassegnato come iniziato perché è già stato contrassegnato come rifiutato",
    cannotMarkAsStartedStatusCanceled:
      "L'ordine non può essere contrassegnato come iniziato perché è già stato contrassegnato come annullato",
    cannotMarkAsStartedStatusDone:
      "L'ordine non può essere contrassegnato come iniziato perché è già stato contrassegnato come completato",
    cannotMarkAsStartedStatusNoShow:
      "L'ordine non può essere contrassegnato come iniziato perché è già stato contrassegnato come assente",
  },
  snackbar: {
    close: 'Chiudi',
    savedSuccessfully: 'Salvato con successo',
    excelDownloadedSuccessfully: 'Scaricato con successo',
    deletedSuccessfully: 'Eliminato con successo',
    sentSuccessfully: 'Inviato con successo',
    statusChanged: 'Stato modificato con successo',
    activeStatusChanged: 'Stato attivo modificato con successo',
    defaultStatusChanged: 'Stato predefinito modificato con successo',
    primaryStatusChanged: 'Stato principale modificato con successo',
    anErrorOccured: 'Si è verificato un errore, segnala al supporto',
    loadingCommentsFailed: 'Caricamento dei commenti fallito',
    drivingCanceled: 'Ordine di guida annullato con successo',
    drivingFinished: 'Ordine di guida completato con successo',
    drivingStarted: "L'ordine di guida è stato contrassegnato come iniziato con successo",
    drivingNoShow: "L'ordine di guida è stato segnato con successo come no show.",
    drivingDeleted: "l'ordine di guida è stato eliminato con successo",
    drivingAccepted: "L'ordine di guida è stato accettato con successo",
    drivingRejected: "L'ordine di guida è stato rifiutato con successo",
    planChanged: 'Piano cambiato con successo',
    planChangeFailed: 'Impossibile cambiare il piano',
    saveChangesFirst: 'Prima salva le modifiche apportate',
    makeRoutesPublic: 'Le rotte sono accessibili pubblicamente',
    makeRoutesNotPublic: 'Le rotte non sono più accessibili pubblicamente',
    makePublicLinkActive: 'Il link pubblico è ora disponibile',
    makePublicLinkInactive: 'Il link pubblico non è più disponibile',
    integrationKeyCopied: 'Chiave di integrazione copiata',
    copyToken: 'Copia manualmente il token',
    publicLinkCopied: 'Link pubblico copiato',
    copyPublicLink: 'Copia manualmente il link pubblico',
    organisationChanged: 'Organizzazione cambiata con successo',
    changeFailed: 'Cambio fallito, riprova',
    loginFailed: 'Nome utente o password errati',
    organisationSuspended: 'La tua organizzazione è sospesa',
    userEmailDoesNotExists: "L'utente con questa email non esiste",
    canceledSubscrSuccessfully: 'Hai annullato la sottoscrizione con successo',
    toManyRequests: 'Troppe richieste. Riprova tra un minuto.',
    visibleStatusChanged: 'Stato visibile cambiato con successo',
    noInternetConnection: 'Nessun internet. Per favore controlla la tua connessione internet.',
    backOnline: 'Sei di nuovo online.',
    driverNotifiedToTurnGpsOn: 'Autista avvisato di accendere il GPS',
    savedSuccessfullyWithGnetError: 'Sincronizzazione con GNET fallita, ordine di guida salvato con successo.',
    logoutFromAnotherDevice: 'Sei stato disconnesso da un altro dispositivo.',
    stripeEnabled: 'Stripe attivato con successo',
    stripeDisabled: 'Stripe disattivato con successo',
    finishStripeConnect: 'Si prega di completare la configurazione di Stripe',
    requestSentSuccessfully: 'Richiesta inviata con successo',
    pricingZoneNoMatch: 'Non riusciamo a trovare le zone di prezzo corrispondenti per calcolare il prezzo.',
    areaAlreadyExists: 'Quest’area esiste già',
    noDataForThisPostalCode: 'Nessun dato disponibile per questo CAP',
    affiliateRequestSentSuccessfully: "L'organizzazione esiste già, la richiesta di affiliazione è stata inviata",
    organizationRegisteredSuccessfully: 'Organizzazione registrata con successo',
    organizationIsAlreadyAPartner: "L'organizzazione è già un partner",
    recordAlreadyExists: 'Questo record esiste già.',
    tooManyUsers: 'Hai troppi utenti per questo pacchetto',
    priceCalculationFailed: 'Non è stato possibile calcolare il prezzo',
  },
  filters: {
    filter: 'Filtro',
    currentYear: 'Anno corrente',
    lastYear: 'Anno scorso',
    lastQuarter: 'Ultimo trimestre',
    lastThreeYears: 'Ultimi tre anni',
    filterByDriver: 'Filtra per autista',
    filterByVehicle: 'Filtra per veicolo',
    filterByClient: 'Filtra per clienti"',
    day: 'Giorno',
    week: 'Settimana',
    month: 'Mese',
    vehicle: 'Veicolo',
    driving: 'Guida',
    user: 'Utente',
    fromDate: 'Dalla data',
    toDate: 'Alla data',
    threeMonths: 'Tre mesi',
    halfYear: 'Mezzo anno',
    year: 'Anno',
  },
  months: {
    jan: 'Gennaio',
    feb: 'Febbraio',
    mar: 'Marzo',
    apr: 'Aprile',
    may: 'Maggio',
    jun: 'Giugno',
    jul: 'Luglio',
    aug: 'Agosto',
    sep: 'Settembre',
    oct: 'Ottobre',
    nov: 'Novembre',
    dec: 'Dicembre',
    January: 'Gennaio',
    February: 'Febbraio',
    March: 'Marzo',
    April: 'Aprile',
    May: 'Maggio',
    June: 'Giugno',
    July: 'Luglio',
    August: 'Agosto',
    September: 'Settembre',
    October: 'Ottobre',
    November: 'Novembre',
    December: 'Dicembre',
  },
  usersPageHeaders: {
    firstName: 'Nome',
    lastName: 'Cognome',
    username: 'Nome utente',
    email: 'Email',
    phoneNumber: 'Numero di telefono',
    role: 'Ruolo',
    active: 'Attivo',
    actions: 'Azioni',
  },
  usersRoles: {
    masterAdmin: 'Amministratore principale',
    manager: 'Responsabile',
    driver: 'Autista',
    operator: 'Operatore',
    externalAssociate: 'Associato esterno',
    sales: 'Vendite',
    salesLead: 'Capo vendite',
    gnet: 'GNET',
    customer: 'Utente del portale',
    publicUser: 'Utente pubblico',
  },
  masterUsersPageHeaders: {
    firstName: 'Nome',
    lastName: 'Cognome',
    username: 'Nome utente',
    email: 'Email',
    phoneNumber: 'Numero di telefono',
    role: 'Ruolo',
    organisation: 'Organizzazione',
    active: 'Attivo',
    actions: 'Azioni',
  },
  usersPage: {
    newItem: 'Nuova voce utente',
    editItem: 'Modifica utente',
    id: 'ID',
    firstName: 'Nome',
    lastName: 'Cognome',
    username: 'Nome utente',
    password: 'Password',
    phoneNumber: 'Numero di telefono',
    role: 'Ruolo',
    client: 'Cliente',
    email: 'Email',
    organisation: 'Organizzazione',
    percentage: 'Percentuale',
    externalAssociate: 'Associato esterno',
    image: 'Immagine',
    deleteUser: 'Elimina utente',
    deleteMessage: "Sei sicuro di voler eliminare l'utente ",
    emailChanged: 'Cambio email',
    verificationEmailSent:
      "La richiesta di cambio dell'indirizzo email è stata inviata. Per completare il processo e garantire la sicurezza dell'account utente, il nuovo indirizzo email deve essere verificato cliccando sul link di verifica inviato alla nuova email. L'indirizzo email non verrà cambiato finché non verrà verificato con successo.",
    passengersInfoAvailableToDriver: 'Informazioni sui passeggeri disponibili per il conducente',
    colorPicker:
      'Puoi selezionare un colore unico per ogni utente. Verrà utilizzato nella pagina della disponibilità per una più facile identificazione.',
  },
  clientsPageHeaders: {
    name: 'Nome',
    phoneNumber: 'Numero di telefono',
    address: 'Indirizzo',
    email: 'Email',
    companyId: 'ID azienda/ID personale',
    clientType: 'Tipo di cliente',
    percentage: 'Percentuale',
    active: 'Attivo',
    actions: 'Azioni',
  },
  clientsPage: {
    newItem: 'Nuova voce cliente',
    editItem: 'Modifica utente',
    id: 'ID',
    name: 'Nome',
    address: 'Indirizzo',
    phoneNumber: 'Numero di telefono',
    email: 'Email',
    companyId: 'ID azienda/ID personale',
    clientType: 'Tipo di cliente',
    individual: 'Persona fisica',
    business: 'Entità commerciale',
    percentage: 'Percentuale',
    deleteClient: 'Elimina cliente',
    deleteMessage: 'Sei sicuro di voler eliminare il cliente ',
    tabs: {
      general: 'Generale',
      transfers: 'Trasferimenti',
      users: 'Utenti',
      invoices: 'Fatture',
      cards: 'Carte',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Numero ordine di guida',
    drivingType: 'Tipo di prenotazione',
    drivingStatus: 'Stato',
    locationFrom: 'Punto di partenza',
    locationTo: 'Destinazione',
    vehicleClass: 'Classe del veicolo',
    vehicle: 'Veicolo',
    driversName: 'Autista',
    startTime: 'Ora di partenza',
    actions: 'Azioni',
    exportExcel: 'Esporta excel',
    price: 'Prezzo',
    totalPrice: 'Prezzo totale',
    confirmed: 'Confermato',
    paid: 'Pagato',
    numberOfDrivings: 'Numero di ordini di guida',
  },
  vehiclesHeaders: {
    plateNumber: 'Numero di registrazione',
    brand: 'Marca del veicolo',
    vehicleClass: 'Classe del veicolo',
    passengersNumber: 'Numero di passeggeri',
    fuelConsumption: 'Consumo di carburante',
    yearOfManufacture: 'Anno di fabbricazione',
    pricePerKm: 'Prezzo al chilometro',
    pricePerMi: 'Prezzo per miglio',
    pricePerHour: 'Prezzo per ora',
    pricePerWaitingHour: 'Prezzo per ora di attesa',
    active: 'Attivo',
    actions: 'Azioni',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Nuova voce veicolo',
    editItem: 'Cambia veicolo',
    vehiclePlates: 'Targhe del veicolo',
    vehicleBrand: 'Marca del veicolo',
    vehicleClass: 'Classe del veicolo',
    passengersNumber: 'Numero di passeggeri',
    fuelConsumption: 'Consumo di carburante del veicolo (100 km)',
    fuelConsumptionMi: 'Consumo di carburante dell’auto (100 miglia)',
    manufactureYear: 'Anno di fabbricazione',
    price: 'Prezzo al chilometro',
    priceMi: 'Prezzo per miglio',
    hourPrice: 'Prezzo per ora',
    image: 'Immagine',
    hourWaitingPrice: 'Prezzo per ora di attesa',
    deleteMessage: 'Sei sicuro di voler eliminare questo veicolo?',
    tabs: {
      general: 'Generale',
      transfers: 'Trasferimenti',
      costs: 'Costi',
      damages: 'Danni',
    },
    licence: 'Numero di licenza',
    color: 'Colore',
    colorPicker:
      'Puoi selezionare un colore unico per ogni veicolo. Verrà utilizzato nella pagina della disponibilità per una più facile identificazione.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Classe del veicolo',
    numberOfPassengers: 'Numero di passeggeri',
    numberOfSuitcases: 'Numero di valigie',
    pricePerKm: 'Prezzo al chilometro',
    pricePerMi: 'Prezzo per miglio',
    pricePerHour: 'Prezzo per ora',
    pricePerWaitingHour: 'Prezzo per ora di attesa',
    availableForPublicBookingForm: 'Disponibile per il modulo di prenotazione pubblico',
    active: 'Attivo',
    actions: 'Azioni',
  },
  vehicleClassesPage: {
    newItem: 'Nuova voce di classe veicolo',
    editItem: 'Modifica classe veicolo',
    id: 'Id',
    vehicleClass: 'Classe veicolo',
    numberOfPassengers: 'Numero di passeggeri',
    numberOfSuitcases: 'Numero di valigie',
    availableForPublicBookingForm: 'Disponibile per il modulo di prenotazione pubblico',
    price: 'Prezzo al chilometro',
    priceMi: 'Prezzo per miglio',
    hourPrice: 'Prezzo per ora',
    image: 'Immagine',
    hourWaitingPrice: 'Prezzo per ora di attesa',
    deleteMessage: 'Sei sicuro di voler eliminare questa classe veicolo?',
  },
  expensesHeaders: {
    name: 'Nome',
    vehicle: 'Veicolo',
    type: 'Tipo',
    description: 'Descrizione',
    price: 'Prezzo',
    expenseDate: 'Data',
    actions: 'Azione',
    driving: 'Guida',
    exportExcel: 'Esporta excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Servizio minore',
    majorService: 'Servizio maggiore',
    fuel: 'Carburante',
    tires: 'Pneumatici',
    parking: 'Parcheggio',
    other: 'Altro',
  },
  expensesPage: {
    newItem: 'Nuova voce di costo',
    editItem: 'Modifica costo',
    id: 'Id',
    name: 'Nome',
    vehicle: 'Veicolo',
    costType: 'Tipo di costo',
    price: 'Prezzo',
    currency: 'Valuta',
    costTime: 'Tempo di costo',
    description: 'Descrizione',
    deleteMessage: 'Sei sicuro di voler eliminare questo costo?',
    costTypes: {
      'Mali servis': 'Servizio minore',
      'Veliki servis': 'Servizio maggiore',
      Gorivo: 'Carburante',
      Gume: 'Pneumatici',
      Parking: 'Parcheggio',
      Ostalo: 'Altro',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Veicolo',
    details: 'Dettagli',
    dateFrom: 'Data da',
    dateTo: 'Data a',
    actions: 'Azioni',
  },
  vehicleMaintenancePage: {
    newItem: 'Nuova voce di guasto veicolo',
    editItem: 'Modifica guasto veicolo',
    id: 'Id',
    vehicle: 'Veicolo',
    description: 'Descrizione',
    dateFrom: 'Data da',
    dateTo: 'Data a',
    deleteMessage: 'Sei sicuro di voler eliminare questo guasto veicolo?',
  },
  customerCardsHeaders: {
    cardholderName: 'Nome del titolare',
    cardNumber: 'Numero di carta',
    expiryDate: 'Data di scadenza',
    cvv: 'CVV',
    actions: 'Azioni',
  },
  customerCardsPage: {
    newItem: 'Nuova voce della carta',
    editItem: 'Modifica della carta',
    cardholderName: 'Nome del titolare',
    cardNumber: 'Numero di carta',
    expiryDate: 'Data di scadenza',
    cvv: 'CVV',
    deleteCard: 'Elimina carta',
    deleteMessage: 'Sei sicuro di voler eliminare questa carta?',
  },
  pricingRegionsHeaders: {
    name: 'Nome',
    actions: 'Azioni',
  },
  pricingRegionsPage: {
    newItem: 'Nuova voce della regione di prezzo',
    editItem: 'Modifica della regione di prezzo',
    name: 'Nome',
    chooseCurrency: 'Scegli la valuta',
    soloPrice: 'Prezzo Solo - Anno',
    soloPaddlePriceId: 'ID prezzo Solo paddle - Anno',
    team5Price: 'Prezzo del team 5 - Anno',
    team5PaddlePriceId: 'ID del prezzo paddle del team 5 - Anno',
    team10Price: 'Prezzo del team 10 - Anno',
    team10PaddlePriceId: 'ID del prezzo paddle del team 10 - Anno',
    team25Price: 'Prezzo del team 25 - Anno',
    team25PaddlePriceId: 'ID del prezzo paddle del team 25 - Anno',
    team50Price: 'Prezzo del team 50 - Anno',
    team50PaddlePriceId: 'ID del prezzo paddle del team 50 - Anno',
    soloPriceMonthly: 'Prezzo Solo - Mese',
    soloPaddlePriceIdMonthly: 'ID prezzo Solo paddle - Mese',
    team5PriceMonthly: 'Prezzo del team 5 - Mese',
    team5PaddlePriceIdMonthly: 'ID del prezzo paddle del team 5 - Mese',
    team10PriceMonthly: 'Prezzo del team 10 - Mese',
    team10PaddlePriceIdMonthly: 'ID del prezzo paddle del team 10 - Mese',
    team25PriceMonthly: 'Prezzo del team 25 - Mese',
    team25PaddlePriceIdMonthly: 'ID del prezzo paddle del team 25 - Mese',
    team50PriceMonthly: 'Prezzo del team 50 - Mese',
    team50PaddlePriceIdMonthly: 'ID del prezzo paddle del team 50 - Mese',
    chooseCountries: 'Scegli i paesi',
    description: 'Descrizione',
    deletePricingRegion: 'Elimina regione di prezzo',
    deleteMessage: 'Sei sicuro di voler eliminare questa regione di prezzo?',
  },
  notificationsHeaders: {
    time: 'Ora',
    notification: 'Notifica',
  },
  notificationsPage: {
    showUnseenOnly: 'Mostra solo non letti',
    markAllAsRead: 'Segna tutto come letto',
  },
  organisationsHeaders: {
    name: 'Organizzazione',
    phoneNumber: 'Numero di telefono',
    email: 'Email',
    address: 'Indirizzo',
    companyId: 'ID azienda',
    vat: 'IVA',
    active: 'Attivo',
    createdAt: 'Registrato',
    actions: 'Azioni',
    billingDate: 'Data di fatturazione',
    referral: 'Segnalatore',
  },
  organisationsPage: {
    newItem: 'Nuova voce utente',
    editItem: 'Modifica utente',
    id: 'Id',
    name: 'Organizzazione',
    address: 'Indirizzo',
    phoneNumber: 'Numero di telefono',
    email: 'Email',
    vat: 'IVA',
    companyId: 'ID azienda',
    warrantNumber: 'Numero decisione',
    image: 'Immagine',
    signature: 'Firma',
    seal: 'Timbro',
    deactivateMessage: 'Sei sicuro di voler ',
    deactivate: 'DISATTIVARE',
    activate: 'ATTIVARE',
    organisation: 'organizzazione',
    deleteOrganisation: 'Elimina organizzazione',
    deleteMessage: 'Sei sicuro di voler eliminare questa organizzazione?',
    cancelSubscrMessage:
      "Sei sicuro di voler cancellare l'abbonamento? Si prega di essere consapevoli che confermando questa azione: 1. L'account della tua organizzazione e tutti i dati associati saranno permanentemente cancellati. Questa azione non può essere annullata. 2. Sarai automaticamente disconnesso dal sistema immediatamente dopo la cancellazione. 3. Non avrai più accesso per accedere o recuperare dati relativi alla tua organizzazione in futuro. Ti consigliamo vivamente di rivedere tutte le informazioni necessarie o esportare dati critici prima di procedere con questa azione irreversibile. Se hai domande o hai bisogno di assistenza, non esitare a contattare il nostro team di supporto. Desideri procedere con la cancellazione?",
    allDrivings: 'Numero totale di ordini di guida',
    allDrivingsAccepted: 'Numero totale di ordini di guida accettati',
    allDrivingsCanceled: 'Numero totale di ordini di guida rifiutati',
    allVehicles: 'Numero totale di veicoli',
    allUsers: 'Numero totale di utenti',
    areYouSure: 'Sei sicuro?',
    makeRoutesAvailable: 'Rendi disponibili percorsi di integrazione',
    percentage: 'Percentuale',
    deleteImageMessage: 'Sei sicuro di voler eliminare questa immagine?',
    deleteImageTitle: 'Elimina Immagine',
    cancelSubscriptionTitle: 'Annulla abbonamento',
    subscriptionType: 'Tipo di abbonamento',
    billingDate: 'Data di fatturazione',
    articlesOfLaw: 'Articoli di legge',
    country: 'Paese',
    referent: 'Referente',
    language: 'Lingua',
    gnetId: 'ID GNET',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Il periodo di prova termina il',
    languageTooltip:
      'Lingua predefinita per i nuovi utenti e lingua in cui verranno inviate tutte le e-mail aziendali (non le e-mail degli utenti individuali).',
    organisationType: 'Tipo di organizzazione',
    regular: 'Regolare',
    affiliateSender: 'Affiliato mittente',
    affiliateReceiver: 'Affiliato destinatario',
    dispatcher: 'Distributore',
    referral: 'Segnalatore',
    selectLocation: 'Posizione della sede',
  },
  transactionsHeaders: {
    invoiceNumber: 'Numero fattura',
    status: 'Stato',
    total: 'Totale',
    tax: 'Tassa',
    currency: 'Valuta',
    billedAt: 'Fatturato il',
    actions: 'Azioni',
  },
  transactionsPage: {
    newItem: 'Nuova voce di transazione',
    editItem: 'Modifica transazione',
    id: 'Id',
    invoiceNumber: 'Numero fattura',
    status: 'Stato',
    sent: 'Inviato',
    completed: 'Completato',
    total: 'Totale',
    currency: 'Valuta',
    billedAt: 'Fatturato il',
    actions: 'Azioni',
    deleteTransaction: 'Elimina transazione',
    deleteMessage: 'Sei sicuro di voler eliminare questa transazione?',
    currentSubscription: 'Pacchetto corrente',
    nextBillingDate: 'Prossima data di fatturazione',
    numberOfSeatsTaken: 'Numero di membri del team occupati',
    numberOfSeatsAvailable: 'Numero di membri del team disponibili',
  },
  currenciesHeaders: {
    name: 'Nome',
    code: 'Codice',
    symbol: 'Simbolo',
    active: 'Attivo',
    default: 'Predefinito',
    actions: 'Azioni',
  },
  currenciesPage: {
    newItem: 'Nuova voce valuta',
    editItem: 'Modifica valuta',
    id: 'Id',
    currency: 'Valuta',
    code: 'Codice',
    symbol: 'Simbolo',
    deleteMessage: 'Sei sicuro di voler eliminare questa valuta?',
  },
  paymentMethodsHeaders: {
    name: 'Nome',
    active: 'Attivo',
    default: 'Predefinito',
    priceVisible: 'Prezzo visibile',
    actions: 'Azioni',
  },
  paymentMethodsPage: {
    newItem: 'Nuova voce metodo di pagamento',
    editItem: 'Modifica metodo di pagamento',
    id: 'Id',
    paymentMethod: 'Metodo di pagamento',
    deleteMessage: 'Sei sicuro di voler eliminare questo metodo di pagamento?',
  },
  feedbacksHeaders: {
    subject: 'Nome',
    organisation: 'Organizzazione',
    user: 'Utente',
    actions: 'Azioni',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Posizione del punto di passaggio',
    arrivalTime: 'Orario di arrivo',
    actions: 'Azioni',
  },
  tables: {
    noDataText: 'Nessun dato',
    search: 'Ricerca',
    drivings: 'Ordini di guida',
    drivingsByPage: 'Ordini di guida per pagina',
    vehicles: 'Veicoli',
    vehiclesByPage: 'Veicoli per pagina',
    vehiclesClasses: 'Classi veicoli',
    vehiclesClassesByPage: 'Classi veicoli per pagina',
    expenses: 'Costi',
    expensesByPage: 'Costi veicoli per pagina',
    vehiclesMaintenance: 'Malfunzionamenti veicoli',
    vehiclesMaintenanceByPage: 'Malfunzionamenti veicoli per pagina',
    reportsByPage: 'Report per pagina',
    currencies: 'Valute',
    currencieseByPage: 'Valute per pagina',
    payments: 'Metodi di pagamento',
    paymentsByPage: 'Metodi di pagamento per pagina',
    saasAdministrators: 'Amministratori Saas',
    users: 'Utenti',
    usersByPage: 'Utenti per pagina',
    clients: 'Clienti',
    clientsByPage: 'Clienti per pagina',
    organisations: 'Organizzazioni',
    organisationsByPage: 'Organizzazioni per pagina',
    cards: 'Carte',
    cardsByPage: 'Carte per pagina',
    transactions: 'Transazioni',
    transactionsByPage: 'Transazioni per pagina',
    pricingRegions: 'Tariffe delle regioni',
    pricingRegionsByPage: 'Tariffe delle regioni per pagina',
    feedback: 'Feedback',
    feedbacksByPage: 'Feedback per pagina',
    notifications: 'Notifiche',
    notificationsByPage: 'Notifiche per pagina',
    all: 'Tutto',
    passengers: 'Passeggeri',
    passengersByPage: 'Passeggeri per pagina',
    of: 'di',
    checkpoints: 'Punti di passaggio',
    checkpointsByPage: 'Punti di passaggio per pagina',
    invoices: 'Fatture',
    invoicesByPage: 'Fatture per pagina',
    drivingOffers: 'Offerte di guida',
    drivingOffersByPage: 'Offerte di guida per pagina',
    smsMessages: 'Messaggi SMS',
    smsMessagesByPage: 'Messaggi SMS per pagina',
    resourcesUsed: 'Risorse utilizzate',
    resourcesUsedByPage: 'Risorse per pagina',
    inNext3Days: 'Nei prossimi 3 giorni',
    notConfirmedOrPaid: 'Non confermato o pagato',
    inNext5Days: 'Nei prossimi 5 giorni',
    latestReviews: 'Ultime recensioni',
    passengerReviews: 'Recensioni dei passeggeri',
    driversByPage: 'Autisti per pagina',
    reviewsByPage: 'Recensioni per pagina',
    partners: 'Partner',
    partnersByPage: 'Partner per pagina',
    sentRequests: 'Richieste inviate',
    receivedRequests: 'Richieste ricevute',
    requestsByPage: 'Richieste per pagina',
    pricingZones: 'Zone e aree',
    pricingZonesByPage: 'Zone e aree per pagina',
    pricingPerZones: 'Prezzi per zone',
    pricingPerZonesByPage: 'Prezzi per pagina',
    pricingPerHour: 'Prezzi orari',
    pricingPerHourByPage: 'Prezzi per pagina',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Numero di ordini di guida accettati',
    numberOfRejectedDrivings: 'Numero di ordini di guida rifiutati per autista',
    vehicleDrivingsNumber: 'Numero di ordini di guida per veicolo',
    vehicleProfit: 'Guadagno per veicolo',
    monthDrivingsNumber: 'Numero di ordini di guida al mese',
    driverProfit: 'Guadagno per autista',
  },
  reportsHeaders: {
    vehicle: 'Veicolo',
    expenses: 'Spese',
    profit: 'Profitto',
    earnings: 'Guadagni',
    daysWorking: 'Giorni lavorativi',
    usageIndex: 'Indice di utilizzo',
    client: 'Cliente',
    numberOfDrivings: 'Numero di Guidate',
    percentForClient: 'Prezzo per il cliente',
    sumOfPrice: 'Quantità',
  },
  packages: {
    active: 'Attivo',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Abbonamento mensile per organizzazione.',
    starterText: 'Ideale per singoli o piccoli team che necessitano solo delle funzionalità di base.',
    starterListItemOne: 'Applicazioni mobili',
    starterListItemTwo: 'Notifiche push',
    starterListItemThree: "Accesso all'amministrazione",
    starterListItemFour: 'Integrazione nel sito web',
    standardTitle: 'Standard',
    standardText: 'Perfetto per organizzazioni con più di 5 autisti.',
    standardListItemOne: 'Pacchetto STARTER incluso',
    standardListItemTwo: 'Nessun limite al numero di autisti',
    standardListItemThree: 'Uptime 99,9%',
    standardListItemFour: 'Report e gestione veicoli',
    premiumTitle: 'Premium',
    premiumText: 'Perfetto per organizzazioni di servizio limousine che operano su dati precisi.',
    premiumListItemOne: 'Pacchetto STANDARD incluso',
    premiumListItemTwo: 'BI e previsione dei costi',
    premiumListItemThree: 'Esportazione dati',
    premiumListItemFour: 'Report settimanali e mensili',
    warningOne: '* I prezzi mostrati sono mensili per utente',
    warningTwo:
      '* Dopo il passaggio a un nuovo pacchetto, non è possibile passare a un pacchetto più piccolo per i successivi 6 mesi',
    warningThree: '* Gli utenti del piano demo hanno una visione delle funzionalità fornite dal pacchetto premium',
    warningOneAnnualPackage:
      "Hai bisogno di più? Hai un'organizzazione più grande? Abbiamo un pacchetto ENTERPRISE per te.",
    warningTwoAnnualPackage: 'e il nostro team commerciale ti contatterà a breve.',
    packageChange: 'Cambio pacchetto',
    changePackage: 'Sei sicuro di voler passare a',
    package: 'pacchetto',
    annualPricing: 'Tariffe annuali',
    monthlyPricing: 'Tariffe mensili',
    packageType5: 'Squadra 5',
    packageType10: 'Squadra 10',
    packageType25: 'Squadra 25',
    packageType50: 'Squadra 50',
    'Solo - Yearly': 'Solo - Annuale',
    'Team 5 - Yearly': 'Squadra 5 - Annuale',
    'Team 10 - Yearly': 'Squadra 10 - Annuale',
    'Team 25 - Yearly': 'Squadra 25 - Annuale',
    'Team 50 - Yearly': 'Squadra 50 - Annuale',
    'Solo - Monthly': 'Solo - Mensile',
    'Team 5 - Monthly': 'Squadra 5 - Mensile',
    'Team 10 - Monthly': 'Squadra 10 - Mensile',
    'Team 25 - Monthly': 'Squadra 25 - Mensile',
    'Team 50 - Monthly': 'Squadra 50 - Mensile',
    annualPackagesMessage: 'Abbonamento annuale per organizzazione.',
    packageTypeSoloMainMessage: 'Solo 1 account utente.',
    packageType5MainMessage: 'Fino a 5 account utente.',
    packageType10MainMessage: 'Fino a 10 account utente.',
    packageType25MainMessage: 'Fino a 25 account utente.',
    packageType50MainMessage: 'Fino a 50 account utente.',
    allFeaturesIncluded: 'Tutte le funzionalità incluse.',
    unlimitedRides: 'Numero illimitato di corse e veicoli.',
    trialPeriod: 'Periodo di prova incluso.',
    onboardingFeeOptional: 'Tariffa di onboarding opzionale.',
    noHiddenCosts: 'Nessun costo nascosto.',
    freeSmsFlights10: '15 SMS e 50 controlli di volo gratuiti al mese.',
    freeSmsFlights25: '35 SMS e 100 controlli di volo gratuiti al mese.',
    freeSmsFlights50: '50 SMS e 200 controlli di volo gratuiti al mese.',
  },
  successPaymentPage: {
    message: 'Pagamento riuscito. Grazie.',
  },
  demoExpiring: {
    notification: 'Notifica',
    expiredSubscriptionMessage:
      'Vi informiamo che la tua iscrizione a questa piattaforma è scaduta. Per continuare a utilizzare i nostri servizi, ti preghiamo di rinnovare la tua iscrizione.',
  },
  login: {
    username: 'Nome utente',
    password: 'Password',
    rememberMe: 'Ricordami',
    login: 'Accedi',
    noAccount: 'Non hai ancora un account? Puoi registrarti',
    forgetPassword: 'Hai dimenticato la password? Puoi reimpostarla',
    registerHere: 'Qui',
    email: 'Email',
    enterEmail: 'Inserisci la tua email',
    enterPassword: 'Inserisci la tua nuova password',
    signInWithGoogle: 'Accedi con Google',
  },
  registration: {
    register: 'Registrati',
    name: "Nome dell'organizzazione",
    address: 'Indirizzo',
    phoneNumber: 'Numero di telefono',
    email: 'E-mail',
    companyId: 'ID aziendale',
    vat: 'IVA',
    language: 'Lingua',
    package: 'Pacchetto',
    accept: 'Accetto',
    terms: "i termini d'uso",
    successfullyRegistered: "L'utente è stato registrato con successo. Riceverai un'e-mail con un link di verifica.",
    haveAccount: 'Hai già un account? Puoi accedere',
    loginHere: 'Qui',
    trialPeriodInfo:
      'Incluso è un periodo di prova gratuito di 14 giorni. La fatturazione seguirà alla fine del periodo di prova.',
  },
  driving: {
    drivingType: 'Tipo di prenotazione',
    general: 'Informazioni generali',
    newDriving: 'Pianifica un trasferimento',
    newDailyRent: 'Pianifica un noleggio giornaliero',
    areYouSure: 'Sei sicuro?',
    vehicleIsUnavailable: 'Il veicolo è occupato',
    vehicleIsDamaged: 'Il veicolo è danneggiato',
    sureAboutThisVehicle: 'Sei sicuro di voler scegliere questo veicolo?',
    driverUnavaliable: 'Il conducente è occupato',
    sureAboutThisDriver: 'Sei sicuro di voler selezionare questo conducente?',
    checkVehicleSeatAvailability: 'Questo veicolo non può contenere così tanti passeggeri',
    cancelDriving: "Sei sicuro di voler cancellare l'ordine di guida?",
    finishDriving: "Sei sicuro di voler terminare l'ordine di guida?",
    noShowDriving: "Sei sicuro di voler segnare l'ordine di guida come no show?",
    markDrivingAsStarted: "Sei sicuro di voler contrassegnare l'ordine di guida come iniziato?",
    deleteDriving: "Sei sicuro di voler eliminare l'ordine di guida?",
    newTransfer: 'Nuovo trasferimento',
    driving: 'Ordine di guida',
    comments: 'Commenti',
    id: 'ID',
    locationFrom: 'Punto di partenza',
    locationTo: 'Destinazione',
    vehicleClass: 'Classe del veicolo',
    vehicle: 'Veicolo',
    driver: 'Conducente',
    pickupTime: 'Orario di ritiro',
    clientConfirmed: 'Cliente confermato',
    description: 'Descrizione',
    extraInfo: 'Informazioni aggiuntive',
    drivingNotAccepted: "Non hanno accettato l'ordine di guida:",
    currency: 'Valuta',
    paymentMethod: 'Metodi di pagamento',
    price: 'Prezzo',
    paid: 'Pagato',
    distance: 'Distanza (km)',
    distanceMi: 'Distanza (miglia)',
    expectedTime: 'Durata prevista',
    waitingTime: 'Tempo di attesa (h)',
    EnterTheNumberOfHoursWaiting: 'Inserisci il numero di ore di attesa',
    dropOffTime: 'Orario di arrivo',
    comebackTime: 'Orario di ritorno',
    acceptUntil: 'Accetta fino a',
    drivingRequired: "È obbligatorio l'ordine di guida",
    comment: 'Commento',
    attachments: 'Allegati',
    addFiles: '+ Aggiungi file',
    deleteFiles: 'Sei sicuro di voler eliminare questo file?',
    passengers: 'Passeggeri',
    addPassenger: '+ Aggiungi passeggero',
    deletePassenger: 'Sei sicuro di voler eliminare questo passeggero?',
    chooseClient: 'Scegli il cliente',
    costs: 'Costi',
    checkpoints: 'Punti di passaggio',
    addCheckpoint: 'Aggiungi punto di passaggio',
    deleteCheckpoint: 'Sei sicuro di voler eliminare questo punto di passaggio?',
    waitingBoardText: 'Nominativo per il cartello di benvenuto',
    flightNumber: 'Numero di volo',
    flightInfo: 'Informazioni sul volo',
    createdBy: 'Ordine di guida creato alle <b>{time}</b> da <b>{user}</b>',
    client: 'Cliente',
    gnet: 'GNET',
    note: 'Nota',
    noteDriver: 'Nota per il conducente',
    noteDispatcher: 'Nota per il dispatcher',
    passenger: 'Passeggero',
    fullName: 'Nome completo',
    phoneNumber: 'Numero di telefono',
    email: 'Email',
    suitcasesNumber: 'Numero di valigie',
    passengersNumber: 'Numero di passeggeri',
    babySeatsNumber: 'Numero di seggiolini per bambini',
    stopsNumber: 'Numero di fermate',
    steps: {
      mainInfo: 'Informazioni principali',
      additionalInfo: 'Informazioni aggiuntive',
      review: 'Revisione',
    },
    commissionAmount: 'Commissione',
    sendSms: 'Invia SMS',
    message: 'Messaggio',
    acceptDriving: "Sei sicuro di voler accettare l'ordine di guida?",
    rejectDriving: "Sei sicuro di voler rifiutare l'ordine di guida?",
    calculatePriceViaZonePricing: 'Calcola il prezzo tramite la zona tariffaria',
    disableCalculatePriceWithoutHours: 'Impossibile calcolare il prezzo senza il numero di ore di attesa',
    disableCalculatePriceViaZonePricing:
      'Impossibile calcolare il prezzo tramite zona tariffaria senza località, valuta e classe del veicolo',
    calculatePriceViaDistanceScales: 'Calcola il prezzo in base alla distanza',
    disableCalculatePriceViaDistanceScales:
      'Impossibile calcolare il prezzo in base alla distanza senza distanza, valuta e classe di veicolo',
    numberOfPassengers: 'Numero di passeggeri',
    numberOfSuitcases: 'Numero di valigie',
    vat: 'IVA',
    vatPercentage: 'Percentuale IVA',
    priceType: 'Tipo di prezzo',
    accessibleForWheelchair: 'Accessibile per sedie a rotelle',
    priceForWaiting: 'Prezzo per l’attesa',
    numberOfHours: 'Numero di ore',
    getAQuote: 'Richiedi un preventivo',
    selectPassenger: 'Seleziona passeggero',
  },
  drivingStatus: {
    pending: 'In attesa',
    accepted: 'Accettato',
    rejected: 'Rifiutato',
    expired: 'Scaduto',
    canceled: 'Annullato',
    draft: 'Bozza',
    done: 'Completato',
    noShow: 'No show',
    ongoing: 'Iniziato',
  },
  drivingOrderType: {
    dailyRent: 'Noleggio giornaliero',
    transfer: 'Trasferimento',
    hourlyDaily: 'Orario/Giornaliero',
    oneWayTransfer: 'Trasferimento di sola andata',
    fromAirport: "Dall'aeroporto",
    toAirport: "All'aeroporto",
  },
  flightInfo: {
    airlineName: 'Nome della compagnia aerea',
    flightNumber: 'Numero di volo',
    departureAirport: 'Aeroporto di partenza',
    departureCountry: 'Paese di partenza',
    departureTime: 'Orario di partenza',
    arrivalAirport: 'Aeroporto di arrivo',
    arrivalCountry: 'Paese di arrivo',
    arrivalTime: 'Orario di arrivo',
    departureDelayed: 'Partenza ritardata',
    flightStatus: 'Stato del volo',
    percentageOfFlight: 'Percentuale del volo',
  },
  customValidations: {
    fileType: `Il tipo di file .{extension} non è supportato`,
    fileSize: 'I file non possono essere più grandi di {size}',
    fieldRequired: 'Il campo {field} è obbligatorio',
    fieldType: 'Il campo {field} deve essere di tipo {type}',
    fieldLengthMin: 'Il campo {field} deve essere di almeno {length}',
    fieldLengthMax: 'Il campo {field} deve essere di massimo {length}',
    fieldUnique: 'Il campo {field} deve essere unico',
    fieldValid: 'Il campo {field} deve essere valido',
    noActiveSubscription: 'Nessuna sottoscrizione attiva trovata',
    usersLimitExceeded: 'Limite di utenti raggiunto',
    fieldBefore: '{field1} deve essere prima di {field2}',
    flightNotExist: 'Il volo {field} non esiste',
    gnetKeyNotValid: 'La chiave Gnet fornita non è valida.',
    gnetPassengersRequired: "L'ordine di guida non può essere inviato a GNET senza passeggeri.",
    priceAndCurrencyRequired: 'Il link di pagamento non può essere generato senza prezzo e valuta',
    fieldValueNotSupported: 'Il valore del campo {field} non è supportato',
    noCountryCode: 'Non hai selezionato un paese',
    noStripeForYourCountry: 'Stripe non è disponibile nel tuo paese',
    stripeAlreadyEnabled: 'Stripe è già attivato',
    fieldValueMin: 'Il campo {field} deve essere {min} o più',
    fieldValueMax: 'Il campo {field} deve essere {max} o meno',
    markAsDoneDate: "L'orario di prelievo non può essere nel futuro",
    phoneNumberNotValidForSms:
      "Il numero di telefono non è valido per l'invio di SMS. Un numero di telefono valido deve includere il prefisso internazionale.",
    affiliateIdDoesNotExist: "L'organizzazione con l'ID affiliato fornito non esiste.",
    numericDecimal: 'Il campo {field} può contenere solo caratteri numerici (sono consentiti decimali)',
  },
  fieldTypes: {
    string: 'stringa',
    integer: 'numero',
    date: 'data',
  },
  names: {
    username: 'nome utente',
    password: 'password',
    choose_client: 'scegli cliente',
    choose_month: 'scegli mese',
    currency: 'valuta',
    payment_method: 'metodo di pagamento',
    vehicle_class: 'classe del veicolo',
    first_name: 'nome',
    last_name: 'cognome',
    passport: 'passaporto',
    nationality: 'nazionalità',
    plate_number: 'numero di targa',
    brand: "marca dell'auto",
    number_of_passengers: 'numero di passeggeri',
    fuel_consumption: 'consumo di carburante',
    year_of_manufacture: 'anno di fabbricazione',
    price_per_km: 'prezzo al chilometro',
    price_per_mi: 'prezzo per miglio',
    price_per_hour: "prezzo all'ora",
    price_per_waiting_hour: 'prezzo per ora di attesa',
    vehicle: 'veicolo',
    description: 'descrizione',
    time_from: 'ora da',
    time_to: 'ora a',
    name: 'nome',
    cost_type: 'tipo di costo',
    cost_time: 'tempo di costo',
    price: 'prezzo',
    phone_number: 'numero di telefono',
    organisation: 'organizzazione',
    role: 'ruolo',
    image: 'immagine',
    location_from: 'posizione da',
    location_to: 'posizione a',
    pickup_time: 'Orario di ritiro',
    distance: 'distanza',
    waiting_time: 'tempo di attesa',
    comment: 'commento',
    address: 'indirizzo',
    vat: 'IVA',
    company_id: 'ID aziendale',
    company_personal_id: 'id azienda/id personale',
    licence: 'licenza',
    warrant_number: 'numero di autorizzazione',
    seal: 'timbro',
    signature: 'firma',
    email: 'email',
    percentage: 'percentuale',
    organizationName: "nome dell'organizzazione",
    package: 'pacchetto',
    choose_currency: 'scegli la valuta',
    team_10_price: 'prezzo team 10',
    team_10_paddle_price_id: 'ID prezzo Paddle team 10',
    team_25_price: 'prezzo team 25',
    team_25_paddle_price_id: 'ID prezzo Paddle team 25',
    team_50_price: 'prezzo team 50',
    team_50_paddle_price_id: 'ID prezzo Paddle team 50',
    team_10_price_monthly: 'prezzo team 10',
    team_10_paddle_price_id_monthly: 'ID prezzo Paddle team 10',
    team_25_price_monthly: 'prezzo team 25',
    team_25_paddle_price_id_monthly: 'ID prezzo Paddle team 25',
    team_50_price_monthly: 'prezzo team 50',
    team_50_paddle_price_id_monthly: 'ID prezzo Paddle team 50',
    countries: 'paesi',
    invoice_number: 'numero fattura',
    status: 'stato',
    total: 'totale',
    billed_at: 'fatturato il',
    articles_of_law: 'articoli di legge',
    country: 'paese',
    referent: 'referente',
    language: 'lingua',
    location: 'località',
    arrival_time: 'ora di arrivo',
    waiting_board_text: 'nominativo per il cartello di benvenuto',
    flight_number: 'numero di volo',
    gnet_id: 'ID GNET',
    receiver_id: 'ID destinatario',
    preferred_vehicle_type: 'tipo di veicolo preferito',
    reservation_type: 'tipo di prenotazione',
    run_type: 'tipo di corsa',
    trip_duration_minutes: 'durata del viaggio',
    client: 'cliente',
    client_type: 'tipo di cliente',
    card_holder_name: 'nome del titolare',
    card_number: 'numero di carta',
    expiry_date: 'data di scadenza',
    cvv: 'cvv',
    code: 'codice',
    symbol: 'simbolo',
    quantity: 'quantità',
    discount: 'sconto',
    driving_type: 'tipo di guida',
    full_name: 'nome completo',
    suitcases_number: 'numero di valigie',
    passengers_number: 'numero di passeggeri',
    baby_seats_number: 'numero di seggiolini per bambini',
    stops_number: 'numero di fermate',
    commission_amount: 'commissione',
    message: 'messaggio',
    affiliate_id: 'affiliate id',
    coordinates: 'coordinate',
    zone_one: 'zona uno',
    zone_two: 'zona due',
    price_type: 'tipo di prezzo',
    price_for_waiting: 'prezzo per l’attesa',
    number_of_hours: 'numero di ore',
    zones: 'Zone',
  },
  weekDays: {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
  },
  select: {
    noDataAvailable: 'Nessun dato disponibile',
    selected: 'selezionato',
  },
  excelExport: {
    to_date_range: 'Il rapporto non può essere generato per date future. Inserisci date valide.',
    overlap_range: "L'orario di fine non può essere maggiore dell'orario di inizio.",
  },
  setPassword: {
    success: 'Email inviata',
    checkEmail: 'Controlla la tua email per ulteriori istruzioni.',
    already_changed: 'Già cambiato',
    send_new_reset_request:
      'Hai già cambiato la password con questo link. Invia una nuova richiesta di reset password.',
  },
  statisticsPage: {
    reportType: 'Tipo di rapporto',
    reportTime: 'Tempo del rapporto',
    vehicleUtilization: 'Utilizzo del veicolo',
    costEffectiveness: 'Economicità',
    generalReport: 'Rapporto generale',
    clientsReport: 'Rapporto per Clienti',
  },
  profile: {
    yourProfile: 'Il tuo profilo',
    name: 'Nome',
    role: 'Ruolo',
    username: 'Nome utente',
    password: 'Password',
    phoneNumber: 'Numero di telefono',
    email: 'Email',
    percentage: 'Percentuale',
  },
  gpsMaps: {
    noTransfers: 'Non ci sono trasferimenti in corso o imminenti.',
    map: 'Mappa',
    transfers: 'Trasferimenti',
    drivers: 'Conducenti',
    ongoing: 'In corso',
    upcoming: 'In arrivo',
    driving: 'Guida',
    driver: 'Autista',
    vehicle: 'Veicolo',
    requestDriverLocation: 'Richiedi posizione del conducente',
    lastRecordedLocation: 'Ultima posizione registrata',
  },
  driverStatus: {
    available: 'Disponibile',
    offline: 'Non in linea',
    inTransfer: 'In trasferimento',
  },
  onboardingTutorialMain: {
    languageStep: 'Qui puoi impostare la lingua preferita per la piattaforma.',
    notificationsStep: 'Qui puoi trovare le tue notifiche.',
    addTransferStep:
      'Clicca su questo pulsante per creare una nuova prenotazione, trasferimenti di sola andata e noleggi giornalieri.',
    sideMenuButtonStep: 'Clicca su questa icona per mostrare o nascondere la barra del menu.',
    sideMenuExpStep:
      'Da qui puoi navigare nelle principali sezioni della piattaforma: Prenotazioni, Flotta, Costi, Amministrazione, Fatture, Statistiche, ecc.',
    sideMenuButtonCloseStep: 'Clicca sulla stessa icona per nascondere la barra del menu.',
    supportStep: 'In caso di problemi o domande, non esitare a contattare il supporto clienti cliccando qui.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'I veicoli verranno elencati in questa tabella dopo che li hai aggiunti.',
    searchStep: 'Puoi trovare facilmente i veicoli cercando direttamente per marca, targa, anno, ecc.',
    addStep:
      'Aggiungi nuovi veicoli cliccando sul pulsante “Aggiungi”. Si noti che tutte le “Classi di veicoli” devono essere aggiunte prima di inserire i veicoli.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'In questa sezione puoi trovare tutti i dati statistici mostrati nei grafici. Seleziona qui il tipo di statistica.',
    intervalStep: 'Scegli un intervallo per visualizzare le statistiche.',
    downloadStep: 'Puoi anche scaricare e stampare queste tabelle.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'Nel modulo GPS, puoi monitorare i tuoi conducenti e trasferimenti in un unico posto. Scegli questa scheda per visualizzare i trasferimenti in corso e imminenti (nelle prossime 24 ore).',
    tabTwoStep:
      'Seleziona i veicoli per visualizzarli sulla mappa. Puoi assegnare i veicoli disponibili direttamente da qui.',
    mapStep: 'Seleziona o deseleziona i veicoli facendo clic sul loro pin sulla mappa.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Tutte le fatture saranno elencate in questa tabella, dalla più recente alla più vecchia.',
    searchStep:
      'Trova facilmente le fatture cercando direttamente per numero, importo, nome del cliente, data di creazione, ecc.',
    addStep: 'Crea nuove fatture cliccando sul pulsante “Aggiungi”.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Le offerte saranno elencate in questa tabella dopo l’aggiunta, dalla più recente alla più vecchia.',
    searchStep: 'Trova facilmente le offerte cercando direttamente per numero, importo o descrizione.',
    addStep: 'Crea nuove offerte cliccando sul pulsante “Aggiungi”.',
  },
  onboardingTutorialResources: {
    infoStep: 'Visualizza le risorse rimanenti e acquista più messaggi SMS su richiesta.',
    tableStep:
      'In questa tabella puoi trovare un audit e la cronologia di tutte le risorse utilizzate (SMS inviati e voli verificati).',
    searchStep: 'Trova facilmente le risorse cercando direttamente per contenuto, utente o data di creazione.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Le recensioni dei passeggeri saranno elencate in questa tabella, dalla più recente alla più vecchia.',
    searchStep:
      'Trova facilmente le recensioni dei passeggeri cercando direttamente per numero o commento del passeggero.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Le zone di prezzo saranno elencate in questa tabella dopo che le hai aggiunte.',
    searchStep: 'Trova facilmente le zone di prezzo cercando direttamente.',
    addStep: 'Crea nuove zone di prezzo cliccando sul pulsante “Aggiungi”.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Le relazioni tra zone di prezzo (prezzi) saranno elencate in questa tabella dopo che le hai aggiunte.',
    searchStep: 'Trova facilmente le relazioni tra zone di prezzo cercando direttamente.',
    addStep:
      'Crea nuove relazioni tra zone di prezzo (prezzi) cliccando sul pulsante “Aggiungi”. Si noti che prima bisogna aggiungere le “Zone di prezzo”.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Tutte le zone tariffarie sono elencate in questa tabella. Aprendo una zona specifica, puoi impostare i prezzi per quella zona.',
    searchStep: 'Trova facilmente le zone tariffarie cercando direttamente.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Visualizza un riepilogo delle statistiche della tua organizzazione per gli ultimi 30 giorni.',
    next3DaysStep: 'Le prenotazioni per i prossimi 3 giorni saranno elencate in questa tabella.',
    notPaidOrConfirmedStep:
      'Le prenotazioni per i prossimi 7 giorni non confermate o non pagate saranno elencate in questa tabella.',
    next5DaysStep: 'I conducenti occupati per i prossimi 5 giorni saranno elencati in questa tabella.',
    latestReviewsStep: 'Le ultime recensioni sui viaggi saranno elencate in questa tabella.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Cambiando scheda, puoi visualizzare i tuoi partner, le richieste inviate o le richieste ricevute.',
    idStep: 'Qui puoi trovare il tuo ID affiliato, che puoi condividere con i tuoi partner.',
    addStep: 'Crea una nuova richiesta di partner affiliato cliccando sul pulsante “Aggiungi”.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Cliccando qui, accedi alle Impostazioni avanzate della tua organizzazione e puoi configurare tutte le proprietà e le integrazioni.',
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Qui puoi impostare e gestire le scale di prezzo per distanza per ciascuna classe di veicolo e valuta. Definisci facilmente i prezzi per intervalli di distanza specifici (es. 0-5 km, 5-15 km) per personalizzare i calcoli delle tariffe.',
  },
  advancedSettings: {
    advancedSettings: 'Impostazioni avanzate',
    distanceUnit: 'Unità di misura della distanza',
    distanceUnitDescription: "Seleziona l'unità di misura per le distanze.",
    kilometers: 'Chilometri',
    miles: 'Miglia',
    dateFormat: 'Formato della data',
    dateFormatDescription: 'Seleziona il formato della data che preferisci utilizzare.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Ordini di guida obbligatori',
    mandatoryDrivingsDescription:
      'Scegli se rendere obbligatori tutti gli ordini di guida per impostazione predefinita.',
    mandatoryDrivingsDefault: 'Ordini di Guida Obbligatori (Predefinito)',
    paidDrivings: 'Ordini di guida pagati',
    paidDrivingsDescription: 'Seleziona per rendere tutti gli ordini di guida pagati per impostazione predefinita.',
    paidDrivingsDefault: 'Ordini di guida pagati (Predefinito)',
    notificationsEmail: 'Email per notifiche',
    notificationsEmailDescription:
      "Modifica l'email predefinita per ricevere le notifiche (se non vuoi utilizzare l'indirizzo email della tua organizzazione).",
    email: 'Email',
    articlesOfLaw: 'Articoli di legge',
    integrations: 'Integrazioni',
    gnetId: 'GNET ID',
    gnetDescription:
      "GNET è una piattaforma completa progettata per ottimizzare il monitoraggio in tempo reale, la gestione della flotta e le operazioni di dispacciamento per l'industria dei trasporti.",
    makeRoutesAvailable: 'Rendi disponibili percorsi di integrazione',
    makeRoutesAvailableDescription:
      'Attiva la tua chiave API per integrare LimoExpress con il tuo sito web, sistema ERP e altro.',
    makePublicFormAvailable: 'Rendere disponibile il modulo pubblico',
    makePublicFormAvailableDescription:
      'Attiva questa opzione per accedere alla pagina pubblica di prenotazione in modo che i tuoi clienti possano accedere al modulo di prenotazione avanzato e creare facilmente account. Puoi utilizzare il link nelle newsletter, sul sito web, sui volantini; è un link unico solo per la tua azienda. In alternativa, puoi anche ottenere un frammento di codice per incorporare il modulo di prenotazione direttamente sul tuo sito web.',
    emailsEnabled: 'Email abilitati',
    emailsEnabledDefault: 'Email abilitati (Per impostazione predefinita)',
    emailsEnabledDescription: 'Seleziona per ricevere notifiche via email.',
    passengerReviewsEnabled: 'Recensioni passeggeri abilitate',
    passengerReviewsEnabledDefault: 'Recensioni passeggeri abilitate',
    passengerReviewsEnabledDescription:
      "Attivando questa funzionalità, i vostri passeggeri (se inserito l'email) riceveranno un'email di conferma dopo il completamento del viaggio (segnato come Completato) e avranno l'opportunità di fornire valutazioni (feedback) sui vostri servizi.",
    vat: 'IVA',
    vatPercentage: 'Percentuale IVA',
    vatPercentageDescription: 'Inserisci la percentuale di IVA.',
    invoicePaymentInstructions: 'Istruzioni per il pagamento della fattura',
    stripe: 'Stripe',
    stripeDescription:
      'Attiva Stripe per poter addebitare ai tuoi clienti i tuoi servizi. LimoExpress non prende commissioni.',
    dataExport: 'Esportazione dati',
    dataExportDescription: 'Puoi esportare tutti i tuoi dati e ti verranno inviati via email.',
    sureAboutDataExport:
      'Sei sicuro di voler esportare tutti i tuoi dati? Se sì, tutti i dati ti saranno inviati via e-mail entro le prossime 2 ore.',
    defaultPriceType: 'Tipo di prezzo predefinito',
    defaultPriceTypeDescription:
      'Imposta il tipo di prezzo predefinito per determinare come verranno visualizzati i prezzi.',
    NET: 'NETTO',
    GROSS: 'LORDO',
    link: 'Link',
    code: 'Codice',
    language: 'Lingua',
    chooseSize: 'Scegli la dimensione',
    small: 'Piccola',
    medium: 'Media',
    large: 'Grande',
  },
  drivingExcelCheckboxes: {
    drivingNumber: "Numero d'ordine",
    drivingType: 'Tipo di prenotazione',
    drivingStatus: 'Stato',
    locationFrom: 'Punto di partenza',
    locationTo: 'Destinazione',
    driver: 'Autista',
    client: 'Cliente',
    plateNumber: 'Numero di targa',
    note: 'Descrizione',
    fuelConsumption: 'Consumo di carburante',
    pickupTime: 'Ora di ritiro',
    numberOfPassengers: 'Numero di passeggeri',
    passengers: 'Passeggeri',
    price: 'Prezzo',
    paid: 'Pagato',
    paymentMethod: 'Metodo di pagamento',
    waitingTime: 'Tempo di attesa (h)',
    distance: 'Distanza (km)',
    distanceMi: 'Distanza (miglia)',
    selectAll: 'Seleziona tutto',
  },
  gnet: {
    gnetInfo: 'Informazioni GNET',
    requesterId: 'ID richiedente',
    receiverId: 'ID destinatario',
    preferredVehicleType: 'Tipo di veicolo preferito',
    reservationType: 'Tipo di prenotazione',
    runType: 'Tipo di corsa',
    price: 'Prezzo',
    currency: 'Valuta',
    noteDriver: 'Nota per il conducente',
    noteDispatcher: 'Nota per il dispatcher',
    tripDurationMinutes: 'Durata del viaggio (minuti)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Numero fattura',
    client: 'Cliente',
    totalPrice: 'Prezzo totale',
    drivings: 'Guida',
    paid: 'Pagato',
    currency: 'Valuta',
    createdAt: 'Creato il',
    actions: 'Azioni',
  },
  invoicesPage: {
    newItem: 'Nuova voce di fattura',
    chooseClient: 'Scegli il cliente',
    chooseDrivings: 'Scegli le corse',
    allDrivings: 'Tutti i viaggi',
    deleteMessage: 'Sei sicuro di voler eliminare questa fattura?',
    markInvoiceAsPaid: 'Sei sicuro di voler contrassegnare questa fattura come pagata?',
  },
  drivingOffersHeaders: {
    number: 'Numero offerta di guida',
    totalPrice: 'Prezzo totale',
    description: 'Descrizione',
    paid: 'Pagato',
    currency: 'Valuta',
    createdAt: 'Creato il',
    actions: 'Azioni',
  },
  drivingOffersPage: {
    newItem: 'Nuova voce offerta di guida',
    item: 'Elemento',
    description: 'Descrizione',
    title: 'Titolo',
    vehicleClass: 'Classe del veicolo',
    quantity: 'Quantità',
    price: 'Prezzo',
    discount: 'Sconto',
    vat: 'IVA',
    deleteMessage: 'Sei sicuro di voler eliminare questa offerta di guida?',
    markDrivingOfferAsPaid: 'Sei sicuro di voler contrassegnare questa offerta di guida come pagata?',
  },
  modal: {
    reservationSuccessTitle: 'Successo',
    reservationSuccessMessage: 'Richiesta di prenotazione inviata con successo. Qualcuno ti contatterà a breve.',
    reservationErrorTitle: 'Modulo disabilitato',
    reservationErrorMessage:
      'Il modulo è attualmente disabilitato dall’organizzazione. Si prega di contattare il supporto o riprovare più tardi.',
  },
  dashboardPage: {
    drivingCountToday: 'Numero di ordini di guida (oggi)',
    drivingCountThisMonth: 'Numero di ordini di guida (ultimi 30 giorni)',
    dailyRentsAndTransfers: 'Numero di ordini di guida (noleggi giornalieri/trasferimenti)',
    drivingTotalAmount: 'Importo totale',
    driversCount: 'Autisti attivi (±7 giorni)',
    passengerCount: 'Numero di passeggeri (ultimi 30 giorni)',
  },
  reviewsHeaders: {
    rating: 'Valutazione',
    comment: 'Commento',
    createdAt: 'Creato il',
  },
  resourceOverviewHeaders: {
    actionType: 'Tipo di azione',
    user: 'Utente',
    action: 'Azione',
    createdAt: 'Creato il',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Numero di messaggi SMS rimanenti',
    flightsCheckLeft: 'Numero di controlli voli rimanenti',
    sms: 'SMS',
    flightCheck: 'Controllo volo',
  },
  affiliateHeaders: {
    organisation: 'Organizzazione',
    createdAt: 'Creato il',
    actions: 'Azioni',
  },
  affiliatePage: {
    partners: 'Partner',
    sentRequests: 'Richieste inviate',
    receivedRequests: 'Richieste ricevute',
    acceptRequest: 'Sei sicuro di voler accettare questa richiesta?',
    rejectRequest: 'Sei sicuro di voler rifiutare questa richiesta?',
    deletePartner: 'Sei sicuro di voler eliminare questo partner?',
    deleteRequest: 'Sei sicuro di voler eliminare la richiesta?',
    newItem: 'Nuova richiesta',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Registrare un nuovo partner',
    registrationSuccessfull: 'Registrazione completata',
    registrationSuccessfullDescription:
      'Partner affiliato registrato con successo! Si prega di informare il partner che riceverà un’email per verificare il proprio account e impostare una password. Una volta completato, potrà iniziare a utilizzare la piattaforma.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate informazioni',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Tipo di veicolo preferito',
    driverInfo: 'Informazioni sul conducente',
    vehicleInfo: 'Informazioni sul veicolo',
    price: 'Prezzo',
    currency: 'Valuta',
  },
  pricingZonesHeaders: {
    name: 'Nome della zona',
    code: 'Codice',
    postalCode: 'CAP/Codice Postale',
    actions: 'Azioni',
  },
  pricingZonesPage: {
    newItem: 'Nuova voce della zona tariffaria',
    editItem: 'Modifica della zona tariffaria',
    id: 'ID',
    name: 'Nome della zona',
    code: 'Codice',
    deleteMessage: 'Sei sicuro di voler eliminare questa zona tariffaria?',
    postalCode: 'CAP/Codice Postale',
    postalCodeDescription: 'Definisci una zona sulla mappa utilizzando un CAP/Codice Postale',
    postalCodeDisabled: 'Inserisci prima un CAP/Codice Postale',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zona uno',
    zoneTwo: 'Zona due',
    client: 'Cliente',
    actions: 'Azioni',
  },
  pricingZoneRelationsPage: {
    newItem: 'Nuova voce di relazione della zona tariffaria',
    editItem: 'Modifica della relazione della zona tariffaria',
    zone: 'Zona',
    zoneOne: 'Zona uno',
    zoneTwo: 'Zona due',
    chooseClient: 'Scegli cliente',
    chooseClientFrom: 'Clona dal cliente',
    chooseClientTo: 'Clona al cliente',
    cloneItem: 'Clona relazione della zona tariffaria',
    zoneRelations: 'Relazioni delle zone tariffarie',
    cloneItemHourly: 'Clona prezzi orari',
    zones: 'Zone',
    fromTo: 'Da - A',
    discountPercentage: 'Percentuale di sconto (%)',
    deleteMessage: 'Sei sicuro di voler eliminare questa relazione della zona tariffaria?',
  },
  referralPrize: {
    referToAFriend: 'Consiglia a un amico',
    referToAFriendDescription:
      'Invita i tuoi amici a unirsi alla piattaforma inserendo il loro indirizzo email qui sotto. Riceveranno un invito personalizzato per registrarsi e iniziare a utilizzare la piattaforma. In alternativa, puoi condividere direttamente il tuo link di registrazione unico con gli altri! Quando qualcuno si registra utilizzando il tuo link o invito, riceverai un premio di riferimento come nostro ringraziamento. Inizia a invitare e condividere oggi stesso!',
  },
  distanceScalesPage: {
    distanceScales: 'Scale di distanza',
  },
  passengersPage: {
    newItem: 'Nuovo inserimento passeggero',
    deletePassenger: 'Elimina passeggero',
    deleteMessage: 'Sei sicuro di voler eliminare il passeggero ',
  },
  formsAndTables: {
    actions: 'Azioni',
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'Email',
    phoneNumber: 'Numero di telefono',
    passport: 'Passaporto',
    country: 'Paese',
    nationality: 'Nazionalità',
  },
};
