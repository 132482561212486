<template>
  <v-col class="mb-5" cols="auto" lg="12" md="12" sm="12">
    <v-card>
      <v-data-table
        :footer-props="{
          pageText: `{0}-{1} ${$t('tables.of')} {2}`,
          itemsPerPageText: $t('tables.expensesByPage'),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        }"
        :headers="expensesHeaders"
        :no-data-text="$t('tables.noDataText')"
        :no-results-text="$t('tables.noDataText')"
        :items="expensesToShow"
        :search="searchVehicleExpenseString"
        class="elevation-1 cursor-pointer"
        @click:row="editVehicleExpenseItem"
        multi-sort>
        <template v-slot:item.description="{ item }">
          {{ item.description?.length > 60 ? item.description.slice(0, 50) + '...' : item.description }}
        </template>

        <template v-slot:item.expense_type.name="{ item }">
          {{ getTranslatedType(item.expense_type.id) }}
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <template v-if="!vehicleId && !drivingId">
              <v-toolbar-title>
                {{ $t('tables.expenses') }}
              </v-toolbar-title>

              <v-divider class="mx-4" inset vertical></v-divider>
            </template>

            <v-text-field
              v-model="searchVehicleExpenseString"
              append-icon="mdi-magnify"
              hide-details
              :label="$t('tables.search')"
              single-line></v-text-field>

            <v-spacer />

            <div>
              <template>
                <v-dialog v-model="exportExcelDialog" max-width="700px" class="no-transition" :key="exportExcelDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-5 mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.export') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t('buttons.export') }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeExportDialog"></v-icon>
                    </v-card-title>

                    <validation-observer ref="exportExcelForm" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(exportToExcel)">
                        <v-container>
                          <v-row>
                            <v-col cols="12" lg="6" md="6" sm="6">
                              <v-menu
                                ref="fromDateFilterNew"
                                v-model="fromDateFilterNew"
                                :close-on-content-click="false"
                                :return-value.sync="fromDateNew"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="required" v-slot="{ errors }" name="time_from">
                                    <v-text-field
                                      v-model="fromDateNew"
                                      clearable
                                      :label="$t('filters.fromDate')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  v-model="fromDateNew"
                                  :model-config="filterModelConfig"
                                  mode="date"
                                  :max-date="new Date()"
                                  :first-day-of-week="firstDay"
                                  @input="updateFromDateFilter(fromDateNew)"></DatePicker>
                              </v-menu>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="6">
                              <v-menu
                                ref="toDateFilterNew"
                                v-model="toDateFilterNew"
                                :close-on-content-click="false"
                                :return-value.sync="toDateNew"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="required" v-slot="{ errors }" name="time_to">
                                    <v-text-field
                                      v-model="toDateNew"
                                      clearable
                                      :label="$t('filters.toDate')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  v-model="toDateNew"
                                  :model-config="filterModelConfig"
                                  mode="date"
                                  :max-date="new Date()"
                                  :first-day-of-week="firstDay"
                                  @input="updateToDateFilter(toDateNew)"></DatePicker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-card-actions>
                          <v-spacer />

                          <button-submit
                            :failed="failed"
                            :loading="loadingExport"
                            buttonText="buttons.export"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </template>

              <v-dialog v-model="vehicleExpenseDialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                    {{ $t('buttons.add') }}
                  </v-btn>
                </template>
                <v-card>
                  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                    <v-form lazy-validation @submit.prevent="handleSubmit(saveVehicleExpense)">
                      <v-card-title class="info title white--text font-weight-bold">
                        {{ $t(vehicleExpenseFormTitle) }}
                        <v-spacer />
                        <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleExpenseEdit"></v-icon>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" hidden md="6" sm="12">
                              <v-text-field
                                v-model="editedVehicleExpenseItem.id"
                                :label="$t('expensesPage.id')"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider rules="required|max:200" v-slot="{ errors }" name="name"
                                ><v-text-field
                                  v-model="editedVehicleExpenseItem.name"
                                  autofocus
                                  clearable
                                  counter="200"
                                  :label="$t('expensesPage.name')"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="cost_type">
                                <v-autocomplete
                                  v-model="editedVehicleExpenseItem.expense_type_id"
                                  :items="expenseTypes"
                                  clearable
                                  item-text="translatedTitle"
                                  item-value="id"
                                  :label="$t('expensesPage.costType')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider v-slot="{ errors }" name="vehicle">
                                <v-autocomplete
                                  v-model="editedVehicleExpenseItem.vehicle_id"
                                  :items="filteredVehicles"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('expensesPage.vehicle')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete> </validation-provider
                            ></v-col>
                            <v-col lg="6" md="6" sm="12">
                              <validation-provider v-slot="{ errors }" name="driving">
                                <v-autocomplete
                                  v-model="editedVehicleExpenseItem.driving_id"
                                  :items="drivings"
                                  clearable
                                  item-text="number"
                                  item-value="id"
                                  :label="$t('expensesHeaders.driving')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                              <validation-provider
                                rules="required|numeric_decimal|min_value:0"
                                v-slot="{ errors }"
                                name="price"
                                ><v-text-field
                                  v-model="editedVehicleExpenseItem.price"
                                  clearable
                                  :label="$t('expensesPage.price')"
                                  type="text"
                                  :error-messages="errors"></v-text-field> </validation-provider
                            ></v-col>
                            <v-col lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="currency">
                                <v-autocomplete
                                  v-model="editedVehicleExpenseItem.currency_id"
                                  :items="filteredCurrencies"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('expensesPage.currency')"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  :error-messages="errors"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                              <v-menu
                                ref="vehicleExpenseDate"
                                v-model="vehicleExpenseDate"
                                :close-on-content-click="false"
                                :return-value.sync="editedVehicleExpenseItem.expense_date"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="required" v-slot="{ errors }" name="cost_time"
                                    ><v-text-field
                                      v-model="editedVehicleExpenseItem.expense_date"
                                      clearable
                                      :label="$t('expensesPage.costTime')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  v-model="editedVehicleExpenseItem.expense_date"
                                  :model-config="modelConfig"
                                  mode="date"
                                  :first-day-of-week="firstDay"
                                  @input="$refs.vehicleExpenseDate.save(editedVehicleExpenseItem.expense_date)">
                                </DatePicker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <validation-provider rules="max:200" v-slot="{ errors }" name="description">
                                <v-textarea
                                  v-model="editedVehicleExpenseItem.description"
                                  clearable
                                  counter="255"
                                  :label="$t('expensesPage.description')"
                                  :error-messages="errors"
                                  rows="2"
                                  auto-grow>
                                </v-textarea>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                              <add-files
                                :editedItem="editedVehicleExpenseItem"
                                :attachments="editedVehicleExpenseItem.files"
                                :filesToSend="filesToSend"
                                deleteRoute="expenses/deleteExpenseAttachment"
                                @addNewFile="addNewFile"
                                @deleteNewFile="deleteNewFile"
                                @deleteOldFile="deleteOldFile">
                              </add-files>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                      </v-card-actions>
                    </v-form>
                  </validation-observer>
                </v-card>
              </v-dialog>
            </div>

            <v-dialog v-model="vehicleExpenseDialogDelete" max-width="650">
              <v-card>
                <v-card-title class="info title white--text font-weight-bold">
                  {{ editedVehicleExpenseItem.name }}
                  <v-spacer />
                  <v-icon class="mdi mdi-close" style="color: white" @click="closeVehicleExpenseDelete"></v-icon>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <p>
                      {{ $t('expensesPage.deleteMessage') }}
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="!enableVehicleExpenseSave"
                    class="primary"
                    text
                    @click="deleteVehicleExpenseConfirm">
                    {{ $t('buttons.yes') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-tooltip left>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn text v-on="{ ...on, ...tooltipOn }">
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('buttons.actions') }}
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="editVehicleExpenseItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.edit') }}</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="deleteItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.delete') }}</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import i18n from '@/i18n/i18n';
import { expensesHeaders } from '@/mixins/data-table-headers';
import { defaultVehicleExpense } from '@/mixins/default-items';
import { DatePicker } from 'v-calendar/src/components';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import AddFiles from '@/components/AddFiles.vue';
import { formatDate, formatDateToDDMM } from '@/utils/formatDate';
import store from '@/store';

export default {
  name: 'expensesList',
  components: { AddFiles, DatePicker, ButtonSubmit },
  props: [
    'drivingId',
    'expensesFromDrivingOrder',
    'fromDate',
    'vehicleFilter',
    'userFilter',
    'drivingFilter',
    'toDate',
    'vehicles',
    'vehicleId',
  ],
  data: () => ({
    searchVehicleExpenseString: '',
    exportExcelDialog: false,
    fromDateFilterNew: null,
    toDateFilterNew: null,
    filterModelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
    vehicleExpenseDialog: false,
    vehicleExpenseDialogDelete: false,
    expenses: [],
    currencies: [],
    filteredCurrencies: [],
    vehicleExpenseFormTitle: 'expensesPage.newItem',
    vehicleExpenseFormValid: true,
    editedVehicleExpenseItem: {},
    vehicleExpenseDate: null,
    expenseTypes: [],
    drivings: [],
    driving: '',
    enableVehicleExpenseSave: true,
    modelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
    loading: false,
    loadingExport: false,
    filteredVehicles: [],
    filesToSend: [],
    fromDateNew: '',
    toDateNew: '',
  }),
  created() {
    this.editedVehicleExpenseItem = Object.assign(
      {},
      {
        ...defaultVehicleExpense,
        expense_date: formatDateToDDMM(this.getCurrentDate()),
        vehicle_id: this.vehicleId,
        driving_id: this.drivingId,
      }
    );
    this.loadAllExpenseTypes();
    this.loadAllVehicleExpenses();
    this.loadAllDrivings();
    this.getAllCurrencies();
  },
  computed: {
    expensesHeaders() {
      return expensesHeaders(i18n);
    },
    expensesToShow() {
      if (this.drivingId) {
        return this.expensesFromDrivingOrder.map((item) => {
          return {
            ...item,
            expense_date: formatDate(item.expense_date),
            expense_type_name: this.getTranslatedType(item.expense_type.id),
          };
        });
      } else {
        return this.expenses;
      }
    },
  },
  methods: {
    async loadAllVehicleExpenses() {
      if (this.vehicleId) {
        let data = {
          id: this.vehicleId,
        };
        await this.$store.dispatch('vehicleMaintenances/getAllVehicleData', data).then((res) => {
          this.expenses = res.data.expenses.map((item) => {
            return {
              ...item,
              expense_date: formatDate(item.expense_date),
              expense_type_name: this.getTranslatedType(item.expense_type.id),
            };
          });
        });
      } else {
        let data = {
          vehicle_id: this.vehicleFilter,
          user_id: this.userFilter,
          driving_id: this.drivingFilter,
          from_date: this.fromDate ? formatDateToDDMM(this.fromDate) : null,
          to_date: this.toDate ? formatDateToDDMM(this.toDate) : null,
        };
        await this.$store.dispatch('expenses/getAllVehicleExpenses', data).then((res) => {
          this.expenses = res.data.map((item) => {
            return {
              ...item,
              expense_date: formatDate(item.expense_date),
              expense_type_name: this.getTranslatedType(item.expense_type.id),
            };
          });
        });
      }
    },
    async loadAllExpenseTypes() {
      await this.$store.dispatch('expenses/getAllExpensesTypes').then((res) => {
        this.expenseTypes = res.data.map((expenseType) => ({
          ...expenseType,
          translatedTitle: JSON.parse(expenseType.title)[i18n.locale] || JSON.parse(expenseType.title)['en'], // Fallback to 'en'
        }));
      });
    },
    async loadAllDrivings() {
      await this.$store.dispatch('drivings/getAllDrivingsData').then((res) => {
        this.drivings = res.data;
      });
    },

    getTranslatedType(typeId) {
      // Find the expense type by ID
      const expenseType = this.expenseTypes.find((type) => type.id === typeId);

      if (!expenseType || !expenseType.title) {
        return ''; // Return empty if not found or title is invalid
      }

      let titles = JSON.parse(expenseType.title);
      // Return the title for the specified language or fallback to English
      return titles[i18n.locale] || titles['en'] || '';
    },

    async getAllCurrencies() {
      await this.$store.dispatch('currencies/getOrganisationCurrencies').then((res) => {
        this.currencies = res.data.map((item) => {
          return item.currency;
        });
      });
      this.editedVehicleExpenseItem.currency_id = parseInt(this.currencies[0]?.id);
    },

    filterAllCurrencies() {
      this.filteredCurrencies = this.currencies?.map((item) => {
        if (item.active == '1' || item?.id === this.editedVehicleExpenseItem?.currency_id) {
          return item;
        }
      });
      if (!this.editedVehicleExpenseItem.id || !this.editedVehicleExpenseItem.currency_id) {
        this.editedVehicleExpenseItem.currency_id = parseInt(this.currencies[0]?.id);
      }
    },

    filterAllVehicles() {
      this.filteredVehicles = this.vehicles?.map((item) => {
        if (item.active == '1' || item?.id === this.editedVehicleExpenseItem?.vehicle_id) {
          return item;
        }
      });
    },

    editVehicleExpenseItem(item) {
      this.editedVehicleExpenseItem = this.expenses.indexOf(item);
      this.editedVehicleExpenseItem = Object.assign({}, item);
      this.editedVehicleExpenseItem.expense_type_id = parseInt(item.expense_type_id);
      this.editedVehicleExpenseItem.vehicle_id = parseInt(item.vehicle_id);
      this.editedVehicleExpenseItem.driving_id = parseInt(item?.driving?.id);
      this.editedVehicleExpenseItem.currency_id = item?.currency?.id
        ? parseInt(item.currency.id)
        : parseInt(this.currencies[0]?.id);
      this.editedVehicleExpenseItem.price = item.price ? Number(item.price) : null;
      this.vehicleExpenseFormTitle = item.name;
      this.vehicleExpenseDialog = true;
    },

    deleteItem(item) {
      this.editedVehicleExpenseItem = this.expenses.indexOf(item);
      this.editedVehicleExpenseItem = Object.assign({}, item);
      this.vehicleExpenseDialogDelete = true;
    },

    async saveVehicleExpense() {
      this.enableVehicleExpenseSave = false;
      this.loading = true;
      let path = 'saveVehicleExpense';
      let formData = this.createFormData();

      if (this.editedVehicleExpenseItem.id) {
        path = 'updateVehicleExpense';
      }

      await this.$store
        .dispatch('expenses/' + path, formData)
        .then(() => {
          if (this.drivingId) {
            this.$emit('loadDrivingDetails');
          } else {
            this.loadAllVehicleExpenses();
          }
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeVehicleExpenseEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableVehicleExpenseSave = true;
          this.loading = false;
          this.vehicleExpenseFormTitle = 'expensesPage.newItem';
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedVehicleExpenseItem.id ? this.editedVehicleExpenseItem.id : '');
      formData.append('name', this.editedVehicleExpenseItem.name ? this.editedVehicleExpenseItem.name : '');
      formData.append(
        'description',
        this.editedVehicleExpenseItem.description ? this.editedVehicleExpenseItem.description : ''
      );
      formData.append('expense_type_id', this.editedVehicleExpenseItem.expense_type_id);
      formData.append(
        'vehicle_id',
        this.editedVehicleExpenseItem.vehicle_id ? this.editedVehicleExpenseItem.vehicle_id : ''
      );
      formData.append(
        'expense_date',
        this.editedVehicleExpenseItem.expense_date ? formatDateToDDMM(this.editedVehicleExpenseItem.expense_date) : ''
      );
      formData.append(
        'driving_id',
        this.editedVehicleExpenseItem.driving_id ? this.editedVehicleExpenseItem.driving_id : ''
      );
      formData.append(
        'currency_id',
        this.editedVehicleExpenseItem.currency_id ? this.editedVehicleExpenseItem.currency_id : ''
      );
      formData.append('price', this.editedVehicleExpenseItem.price ? this.editedVehicleExpenseItem.price : '');
      this.filesToSend.map((item) => {
        formData.append('files[]', item);
      });

      return formData;
    },

    async deleteVehicleExpenseConfirm() {
      await this.$store
        .dispatch('expenses/deleteVehicleExpense', this.editedVehicleExpenseItem)
        .then((res) => {
          if (res.success) {
            if (this.drivingId) {
              this.$emit('loadDrivingDetails');
            } else {
              this.loadAllVehicleExpenses();
            }
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'red' });
            this.closeVehicleExpenseDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeVehicleExpenseDelete();
        });
    },

    closeVehicleExpenseEdit() {
      this.vehicleExpenseDialog = false;
      this.$nextTick(() => {
        this.editedVehicleExpenseItem = Object.assign(
          {},
          {
            ...defaultVehicleExpense,
            expense_date: formatDateToDDMM(this.getCurrentDate()),
            vehicle_id: this.vehicleId,
            driving_id: this.drivingId,
          }
        );
      });
      this.$refs.form.reset();
      this.filesToSend = [];
      this.vehicleExpenseFormTitle = 'expensesPage.newItem';
    },

    closeVehicleExpenseDelete() {
      this.vehicleExpenseDialogDelete = false;
      this.$nextTick(() => {
        this.editedVehicleExpenseItem = Object.assign(
          {},
          {
            ...defaultVehicleExpense,
            expense_date: formatDateToDDMM(this.getCurrentDate()),
            vehicle_id: this.vehicleId,
            driving_id: this.drivingId,
          }
        );
      });
    },

    getCurrentDate() {
      const date = new Date();

      return this.getFormattedDate(date);
    },

    getFormattedDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

    addFiles() {
      this.$refs.addFilesRef.$refs.input.click();
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedVehicleExpenseItem.files.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },

    exportToExcel() {
      this.loadingExport = true;

      this.$store
        .dispatch('expenses/exportExcel', {
          from: formatDateToDDMM(this.fromDateNew),
          to: formatDateToDDMM(this.toDateNew),
          vehicle_id: this.vehicleFilter,
          driving_id: this.drivingFilter ? this.drivingFilter : this.drivingId ? this.drivingId : null,
          user_id: this.userFilter,
        })
        .then((response) => {
          if (response.path) {
            window.open(response.path, '_blank');
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.excelDownloadedSuccessfully'),
              color: 'green',
            });
            this.closeExportDialog();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
    closeExportDialog() {
      this.exportExcelDialog = false;
      this.$nextTick(() => {
        this.fromDateNew = null;
        this.toDateNew = null;
      });
      this.$refs.exportExcelForm.reset();
    },
    updateFromDateFilter(fromDateNew) {
      this.$refs.fromDateFilterNew.save(fromDateNew);
    },
    updateToDateFilter(toDateNew) {
      this.$refs.toDateFilterNew.save(toDateNew);
    },
  },

  watch: {
    vehicleExpenseDialog(val) {
      if (!val) {
        this.closeVehicleExpenseEdit();
      } else {
        this.filterAllCurrencies();
        this.filterAllVehicles();
      }
    },
    fromDate: {
      handler() {
        this.loadAllVehicleExpenses();
      },
    },
    toDate: {
      handler() {
        this.loadAllVehicleExpenses();
      },
    },
    vehicleFilter: {
      handler() {
        this.loadAllVehicleExpenses();
      },
    },
    userFilter: {
      handler() {
        this.loadAllVehicleExpenses();
      },
    },
    drivingFilter: {
      handler() {
        this.loadAllVehicleExpenses();
      },
    },
    vehicleId: {
      handler() {
        this.editedVehicleExpenseItem.vehicle_id = this.vehicleId;
        this.loadAllVehicleExpenses();
      },
    },
    exportExcelDialog(val) {
      if (val) {
        this.fromDateNew = this.fromDate;
        this.toDateNew = this.toDate;
      }
    },
    '$i18n.locale': function (newLocale) {
      this.expenseTypes = this.expenseTypes.map((expenseType) => ({
        ...expenseType,
        translatedTitle: JSON.parse(expenseType.title)[newLocale] || JSON.parse(expenseType.title)['en'], // Fallback to 'en'
      }));

      this.expenses = this.expenses.map((item) => {
        return {
          ...item,
          expense_type_name: this.getTranslatedType(item.expense_type.id),
        };
      });
    },
  },
};
</script>
