<template>
  <v-container class="d-flex justify-center align-center fill-height my-5">
    <validation-observer v-if="!isRegistered" v-slot="{ handleSubmit, failed }">
      <v-form lazy-validation @submit.prevent="handleSubmit(preSubmitForm)" class="registration-form">
        <v-row>
          <v-col cols="12 text-center" class="mb-2 register-title">
            {{ $t('registration.register') }}
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider rules="required" v-slot="{ errors }" name="name" ref="organizationNameRef">
              <div class="input-container">
                <v-text-field
                  name="name"
                  v-model="name"
                  clearable
                  :error-messages="errors"
                  class="custom-input"
                  :label="$t('registration.name')"
                  prepend-icon="mdi-office-building-outline">
                </v-text-field>
              </div>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider rules="required" v-slot="{ errors }" name="address">
              <v-text-field
                name="address"
                v-model="address"
                clearable
                :label="$t('registration.address')"
                :error-messages="errors"
                prepend-icon="mdi-map-marker"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
              :rules="{
                required: true,
                regex: /^\+?[0-9\s\-()]+$/,
              }"
              v-slot="{ errors }"
              name="phone_number">
              <v-text-field
                name="phone"
                v-model="phoneNumber"
                clearable
                :label="$t('registration.phoneNumber')"
                :error-messages="errors"
                prepend-icon="mdi-phone"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
              <v-text-field
                name="email"
                v-model="email"
                clearable
                :label="$t('login.email')"
                :error-messages="errors"
                prepend-icon="mdi-email"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider>
              <v-text-field
                name="company_id"
                v-model="companyId"
                clearable
                :label="$t('registration.companyId')"
                prepend-icon="mdi-information-slab-box-outline">
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider>
              <v-text-field
                name="vat"
                v-model="vat"
                clearable
                :label="$t('registration.vat')"
                prepend-icon="mdi-information-slab-box-outline"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider>
              <v-autocomplete
                name="language"
                clearable
                item-text="lang"
                item-value="abbr"
                v-model="selectedLanguage"
                :items="languageOptions"
                :label="$t('registration.language')"
                prepend-icon="mdi-web"></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider rules="required" v-slot="{ errors }" name="package">
              <v-autocomplete
                name="package"
                v-model="selectedPackage"
                :items="packageOptions"
                clearable
                :item-text="formatPackageText"
                item-value="id"
                prepend-icon="mdi-check"
                :label="$t('registration.package')"
                :no-data-text="$t('select.noDataAvailable')"
                :error-messages="errors">
              </v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="justify-center align-center">
          <span class="font-weight-bold">{{ $t('registration.trialPeriodInfo') }}</span>
        </v-row>
        <v-row class="justify-center align-center">
          <v-checkbox v-model="acceptedTerms"></v-checkbox>
          <span>{{ $t('registration.accept') }}</span>
          <a class="text-decoration-none" :href="websiteUrl + 'terms-and-conditions'" target="_blank" rel="noreferrer">
            <span class="blue-text ml-1">{{ $t('registration.terms') }}</span>
          </a>
        </v-row>
        <v-row justify="center">
          <button-submit
            :failed="failed || !acceptedTerms"
            :loading="loading"
            buttonText="buttons.send"></button-submit>
        </v-row>
      </v-form>

      <p class="mt-16 text-center">
        {{ $t('registration.haveAccount') }}
        <router-link to="/login" class="text-decoration-none" style="font-weight: bold; color: blue">
          {{ $t('registration.loginHere') }}
        </router-link>
      </p>
    </validation-observer>

    <v-row v-else justify="center" align="center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          width="450px"
          style="margin-left: auto; margin-right: auto" />
      </v-col>
      <p class="successfully-registered-text">{{ $t('registration.successfullyRegistered') }}</p>
    </v-row>
  </v-container>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';
import { languageOptions } from '@/mixins/language-options';

export default {
  components: { ButtonSubmit },
  data() {
    return {
      name: '',
      selectedLanguage: '',
      selectedPackage: '',
      email: '',
      address: '',
      phoneNumber: '',
      companyId: '',
      vat: '',
      loading: false,
      languageOptions: languageOptions,
      packageOptions: [],
      acceptedTerms: true,
      isRegistered: false,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    };
  },
  created() {
    this.getListOfPackages();
  },
  methods: {
    async preSubmitForm() {
      try {
        // Call reCAPTCHA verification
        const token = await this.$recaptcha('public_reservation_form');
        // You can define any action name here

        // Submit your form or make an API call here with the token
        await this.submitForm(token);
      } catch {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    submitForm(token) {
      this.loading = true;

      const body = {
        organisation_name: this.name,
        organisation_address: this.address,
        organisation_phone: this.phoneNumber,
        email: this.email,
        subscription_type_id: this.selectedPackage,
        vat: this.vat,
        company_id: this.companyId,
        language: this.selectedLanguage,
        terms_and_conditions: true,
        token: token,
        source: 'platform',
      };

      this.$store
        .dispatch('auth/registerUser', body)
        .then(() => {
          this.isRegistered = true;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response.data.errors?.email || error.response.data.errors?.organisation_name) {
              if (error.response.data.errors?.email && error.response.data.errors?.email[0] === 'ORG-REG-008') {
                this.$refs.emailRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.email'),
                    }),
                  ],
                });
              }
              if (
                error.response.data.errors?.organisation_name &&
                error.response.data.errors?.organisation_name[0] === 'ORG-REG-0014'
              ) {
                this.$refs.organizationNameRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.organizationName'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
          this.loading = false;
        });
    },
    async getListOfPackages() {
      await this.$store.dispatch('subscriptionPackages/getPackagesTypes').then((res) => {
        this.packageOptions = res.data;
      });

      if (this.$route.query.email) {
        this.email = this.$route.query.email;
      }
    },
    formatPackageText(item) {
      const packageName = this.getTranslatedName(item);
      const pricingText =
        item.billing_period === 'yearly' ? i18n.t('packages.annualPricing') : i18n.t('packages.monthlyPricing');
      return `${packageName} - ${this.getCurrencyPrice(item)} - ${pricingText}`;
    },

    getTranslatedName(item) {
      const names = {
        5: 'packageType5',
        9: 'packageType10',
        10: 'packageType25',
        11: 'packageType50',
        12: 'packageType10',
        13: 'packageType25',
        14: 'packageType50',
        17: 'packageType5',
      };

      return names[item.id] ? i18n.t(`packages.${names[item.id]}`) : '';
    },

    getCurrencyPrice(item) {
      switch (item.currency) {
        case 'EUR':
          return '€' + item.price;
        case 'USD':
          return '$' + item.price;
        case 'GBP':
          return '£' + item.price;
        default:
          return '€' + item.price;
      }
    },
  },
};
</script>

<style scoped>
.register-title {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
}

.blue-text {
  color: var(--primary);
  font-weight: 600;
}

.registration-form {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

.successfully-registered-text {
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
  text-align: center;
}
</style>
