var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"4","lg":"4","md":"4","sm":"4"}},[_c('validation-provider',{attrs:{"rules":"required|numeric_decimal|max:10","name":_vm.currency.id + _vm.label,"custom-messages":{
      required: _vm.$t('customValidations.fieldRequired', {
        field: _vm.$t('names.price'),
      }),
      max: _vm.$t('customValidations.fieldLengthMax', {
        field: _vm.$t('names.price'),
        length: 10,
      }),
      numeric_decimal: _vm.$t('customValidations.numericDecimal', {
        field: _vm.$t('names.price'),
      }),
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"clearable":"","counter":"10","name":"price_per_km","label":_vm.label,"error-messages":errors},on:{"input":_vm.updateValue},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }