import { getDistanceScaleData, saveDistanceScale } from '@/api/distanceScales';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDistanceScaleData(state, item) {
      return getDistanceScaleData(item);
    },
    async saveDistanceScale(state, item) {
      return saveDistanceScale(item);
    },
  },
  getters: {},
};
