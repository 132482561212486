import { getAllNotifications, setAllAsSeen, setNotificationAsSeen } from '@/api/notifications';
import { formatDateTime } from '@/utils/formatDate';

const state = {
  notifications: [],
};

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  async getAllNotifications({ commit }, item) {
    try {
      const res = await getAllNotifications(item);
      const notifications = res.notifications.map((e) => ({
        ...e,
        time: formatDateTime(e.created_at),
        notification: e.data.status,
        driving_id: e.data.driving_id,
      }));

      commit('setNotifications', notifications);
      return notifications;
    } catch (error) {
      throw error;
    }
  },

  async setAllAsSeen(state, item) {
    return setAllAsSeen(item);
  },

  async setNotificationAsSeen(state, item) {
    return setNotificationAsSeen(item);
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
  getters: {},
};
