import { getHttpClient } from './client';

export const getAllNotifications = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/notification';

  let queryString = '';
  if (data) {
    if (data.unseen_only) {
      queryString += `?unseen_only=${data.unseen_only}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const setAllAsSeen = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/notifications/set-all-as-seen', 'POST', data).then(
    (response) => response.data
  );
};

export const setNotificationAsSeen = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + `/notifications/set-as-seen/${data.id}`, 'POST').then(
    (response) => response.data
  );
};
