import { getHttpClient } from './client';

export const getDistanceScaleData = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/distance-scale-data';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveDistanceScale = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/save-distance-scale-prices', 'POST', data).then(
    (response) => response.data
  );
};
