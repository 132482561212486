export default {
  sidebar: {
    fleet: 'Στόλος',
    vehicles: 'Οχήματα',
    vehiclesClasses: 'Κατηγορίες οχημάτων',
    administration: 'Διαχείριση',
    currencies: 'Νομίσματα',
    currenciesAdministration: 'Διαχείριση νομισμάτων',
    paymentTypes: 'Τρόποι πληρωμής',
    paymentTypesAdministration: 'Διαχείριση τρόπων πληρωμής',
    users: 'Χρήστες',
    usersAdministration: 'Διαχείριση χρηστών',
    clients: 'Πελάτες',
    clientsAdministration: 'Διαχείριση πελατών',
    organizations: 'Οργανισμοί',
    organizationsAdministration: 'Διαχείριση οργανισμών',
    saasAdmin: 'Διαχειριστής Saas',
    administrators: 'Διαχειριστές',
    saasAdminAdministration: 'Διαχείριση Saas διαχειριστών',
    statistics: 'Στατιστικά',
    generalOverview: 'Γενική αναφορά',
    payment: 'Πληρωμή',
    pricingRegions: 'Περιοχές τιμολόγησης',
    pricingRegionsAdministration: 'Διαχείριση περιοχών τιμολόγησης',
    help: 'Βοήθεια',
    drivings: 'Παραγγελίες διαδρομών',
    calendar: 'Ημερολόγιο',
    tabularView: 'Πίνακας προβολής',
    expenses: 'Έξοδα',
    vehicleExpensesAdministration: 'Διαχείριση εξόδων οχημάτων',
    vehicleMaintenance: 'Βλάβες οχημάτων',
    vehicleMaintenanceAdministration: 'Διαχείριση βλαβών οχημάτων',
    organization: 'Οργάνωση',
    organizationAdminstration: 'Διαχείριση οργανισμού',
    packages: 'Πακέτα',
    packagesChange: 'Αλλαγή πακέτων',
    logout: 'Αποσύνδεση',
    logoutText: 'Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;',
    unaccessibleRoutes:
      'Δεν μπορείτε να αποκτήσετε πρόσβαση σε αυτήν τη σελίδα. Αναβαθμίστε το πακέτο για πλήρη πρόσβαση.',
    transactions: 'Συναλλαγές',
    gpsMap: 'Χάρτης GPS',
    team: 'Ομάδα',
    teamAdministration: 'Διαχείριση ομάδας',
    invoices: 'Τιμολόγια',
    invoicesAdministration: 'Διαχείριση τιμολογίων',
    drivingOffers: 'Προσφορές',
    drivingOffersAdministration: 'Διαχείριση προσφορών',
    dashboard: 'Πίνακας ελέγχου',
    dashboardAdministration: 'Διαχείριση πίνακα ελέγχου',
    resourceOverview: 'Επισκόπηση πόρων',
    resourceOverviewAdministration: 'Διαχείριση επισκόπησης πόρων',
    rides: 'Διαδρομές',
    passengerReviews: 'Κριτικές επιβατών',
    passengerReviewsAdministration: 'Διαχείριση κριτικών επιβατών',
    affiliate: 'Συνεργάτης',
    affiliateAdministration: 'Διαχείριση συνεργατών',
    pricingAndRates: 'Τιμολόγηση και χρεώσεις',
    pricingZones: 'Ζώνες και περιοχές',
    pricingZonesAdministration: 'Διαχείριση ζωνών και περιοχών',
    pricingPerZones: 'Τιμολόγηση ανά ζώνη',
    pricingPerZonesAdministration: 'Διαχείριση τιμολόγησης ανά ζώνη',
    pricingPerHour: 'Τιμολόγηση ανά ώρα',
    pricingPerHourAdministration: 'Διαχείριση τιμολόγησης ανά ώρα',
    availability: 'Διαθεσιμότητα',
    availabilityAdministration: 'Διαθεσιμότητα οδηγών και οχημάτων',
    distanceScales: 'Κλίμακες απόστασης',
    distanceScalesAdministration: 'Διαχείριση κλιμάκων απόστασης',
    passengers: 'Επιβάτες',
    passengersAdministration: 'Διαχείριση επιβατών',
  },
  header: {
    organization: 'Οργάνωση',
    newDriving: 'Κράτηση',
    dailyRent: 'Ημερήσια ενοικίαση',
    transfer: 'Μεταφορά',
    notifications: 'Ειδοποιήσεις',
    changeLanguage: 'Αλλαγή γλώσσας',
    help: 'Βοήθεια',
  },
  footer: {
    poweredBy: 'Υποστηρίζεται από',
    subscriptionTo: 'Η συνδρομή σας σε',
    trialEndsAt: 'Η δοκιμαστική περίοδος για',
    packageExpiresIn: 'το πακέτο λήγει σε',
    clickToChangePackage: 'Κάντε κλικ για αλλαγή πακέτου',
    clickToActivatePackage: 'Κάντε κλικ για ενεργοποίηση πακέτου',
    clickToPay: 'Κάντε κλικ για πληρωμή',
    packageHasExpired: 'το πακέτο έχει λήξει',
    affiliateUser: 'Αν θέλετε να ξεκλειδώσετε όλες τις λειτουργίες της πλατφόρμας, χρειάζεται να εγγραφείτε',
    here: 'ΕΔΩ',
  },
  buttons: {
    ok: 'ΟΚ',
    yes: 'Ναι',
    no: 'Όχι',
    save: 'Αποθήκευση',
    send: 'Αποστολή',
    sendMessage: 'Αποστολή μηνύματος',
    edit: 'Επεξεργασία',
    add: 'Προσθήκη',
    confirm: 'Επιβεβαίωση',
    cancel: 'Ακύρωση',
    cancelDriving: 'Ακύρωση διαδρομής',
    markAsDone: 'Σήμανση ως ολοκληρωμένο',
    markAsNoShow: 'Σήμανση ως μη εμφάνιση',
    deleteDriving: 'Διαγραφή διαδρομής',
    newOrganisation: 'Νέος οργανισμός',
    changePackage: 'Αλλαγή πακέτων',
    downloadStats: 'Λήψη στατιστικών',
    downloadPDF: 'Λήψη PDF',
    today: 'Σήμερα',
    choose: 'Επιλογή',
    login: 'Σύνδεση',
    generateFile: 'Δημιουργία συμβολαίου',
    generateDrivingOrder: 'Εκτύπωση εντολής διαδρομής',
    downloadFile: 'Λήψη αρχείου',
    activate: 'Ενεργοποίηση',
    deactivate: 'Απενεργοποίηση',
    showImage: 'Εμφάνιση εικόνας',
    showDetails: 'Εμφάνιση λεπτομερειών',
    actions: 'Ενέργειες',
    delete: 'Διαγραφή',
    open: 'Άνοιγμα',
    show: 'Εμφάνιση',
    options: 'Επιλογές',
    prev: 'Προηγούμενο',
    next: 'Επόμενο',
    skip: 'Παράβλεψη',
    finish: 'Ολοκλήρωση',
    settings: 'Ρυθμίσεις',
    cancelSubscription: 'Ακύρωση συνδρομής',
    backToEditOrganization: 'Επιστροφή για επεξεργασία οργανισμού',
    payNow: 'Πληρώστε τώρα',
    back: 'Επιστροφή',
    integrationRoutes: 'Διαδρομές ενοποίησης',
    transactions: 'Συναλλαγές',
    articlesOfLaw: 'Άρθρα νόμου',
    export: 'Εξαγωγή',
    duplicate: 'Δημιουργία αντιγράφου',
    makeReturnTrip: 'Δημιουργία επιστροφής',
    selectOrganisation: 'Επιλογή οργανισμού',
    advancedSettings: 'Προηγμένες ρυθμίσεις',
    accept: 'Αποδοχή',
    reject: 'Απόρριψη',
    sendViaGnet: 'Αποστολή μέσω GNET',
    printInvoice: 'Εκτύπωση τιμολογίου',
    enable: 'Ενεργοποίηση',
    disable: 'Απενεργοποίηση',
    generatePaymentLink: 'Δημιουργία συνδέσμου πληρωμής',
    addItem: '+ Προσθήκη αντικειμένου',
    visitWebsite: 'Επισκεφθείτε την ιστοσελίδα μας',
    buyMoreSms: 'Αγορά περισσότερων SMS',
    forward: 'Προώθηση',
    sendViaAffiliate: 'Αποστολή μέσω συνεργάτη',
    markInvoiceAsPaid: 'Σήμανση τιμολογίου ως πληρωμένο',
    markDrivingOfferAsPaid: 'Σήμανση προσφοράς διαδρομής ως πληρωμένη',
    selectAll: 'Επιλογή όλων',
    viaAffiliateId: 'Προσθήκη μέσω affiliate ID',
    registerPartner: 'Εγγραφή συνεργάτη',
    createNew: 'Δημιουργία νέου',
    clone: 'Κλωνοποίηση',
    cloneExisting: 'Κλωνοποίηση υπάρχοντος',
    copyLink: 'Αντιγραφή συνδέσμου',
    markAsStarted: 'Σήμανση ως ξεκινήθηκε',
  },
  tooltips: {
    save: 'Αποθήκευση',
    edit: 'Επεξεργασία',
    delete: 'Διαγραφή',
    showImage: 'Εμφάνιση εικόνας',
    open: 'Άνοιγμα',
    show: 'Εμφάνιση',
    showDetails: 'Εμφάνιση λεπτομερειών',
    downloadFile: 'Λήψη αρχείου',
    generateFile: 'Δημιουργία συμβολαίου',
    generateDrivingOrder: 'Εκτύπωση εντολής διαδρομής',
    activate: 'Ενεργοποίηση',
    deactivate: 'Απενεργοποίηση',
    cancel: 'Ακύρωση',
    cancelDriving: 'Ακύρωση διαδρομής',
    cannotMarkAsCanceled: 'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη γίνει αποδεκτή',
    markAsDone: 'Σήμανση ως ολοκληρωμένο',
    cannotMarkAsDone: 'Η παραγγελία δεν μπορεί να σημειωθεί ως ολοκληρωμένη γιατί δεν έχει ακόμη πραγματοποιηθεί',
    markAsNoShow: 'Σήμανση ως μη εμφάνιση',
    cannotMarkAsNoShow: 'Η παραγγελία δεν μπορεί να σημειωθεί ως μη εμφάνιση γιατί δεν έχει ακόμη πραγματοποιηθεί',
    deleteDriving: 'Διαγραφή διαδρομής',
    cannotDelete: 'Λυπούμαστε, αλλά για λόγους ασφαλείας, οι χρήστες δεν μπορούν να διαγραφούν ενώ είναι συνδεδεμένοι.',
    duplicate: 'Δημιουργία αντιγράφου',
    makeReturnTrip: 'Δημιουργία επιστροφής',
    refresh: 'Ανανέωση',
    selectOrganisation: 'Επιλογή οργανισμού',
    sendViaGnet: 'Αποστολή μέσω GNET',
    receivedViaGnet: 'Ελήφθη μέσω GNET',
    sentViaGnet: 'Εστάλη μέσω GNET',
    printInvoice: 'Εκτύπωση τιμολογίου',
    optionsDisabled:
      'Πρέπει να απορρίψετε ή να αποδεχτείτε την παραγγελία για να αποκτήσετε πρόσβαση στο μενού επιλογών',
    cannotMakeReturnTrip: 'Δεν μπορείτε να δημιουργήσετε επιστροφή για ημερήσια ενοικίαση',
    cannotSendGnetOrderViaGnet: 'Δεν μπορείτε να στείλετε την παραγγελία GNET μέσω GNET',
    cannotSendViaGnetWithoutGnetId: 'Δεν μπορείτε να στείλετε την παραγγελία μέσω GNET χωρίς ID GNET',
    cannotPrintInvoiceWithoutClient: 'Δεν μπορείτε να εκτυπώσετε τιμολόγιο χωρίς να επιλέξετε πελάτη',
    cannotMarkAsDoneStatusRejected:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ολοκληρωμένη γιατί έχει ήδη σημειωθεί ως απορριφθείσα',
    cannotMarkAsDoneStatusCanceled:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ολοκληρωμένη γιατί έχει ήδη σημειωθεί ως ακυρωμένη',
    cannotMarkAsDoneStatusDone:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ολοκληρωμένη γιατί έχει ήδη σημειωθεί ως ολοκληρωμένη',
    cannotMarkAsDoneStatusNoShow:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ολοκληρωμένη γιατί έχει ήδη σημειωθεί ως μη εμφάνιση',
    cannotMarkAsNoShowStatusRejected:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως μη εμφάνιση γιατί έχει ήδη σημειωθεί ως απορριφθείσα',
    cannotMarkAsNoShowStatusCanceled:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως μη εμφάνιση γιατί έχει ήδη σημειωθεί ως ακυρωμένη',
    cannotMarkAsNoShowStatusDone:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως μη εμφάνιση γιατί έχει ήδη σημειωθεί ως ολοκληρωμένη',
    cannotMarkAsNoShowStatusNoShow:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως μη εμφάνιση γιατί έχει ήδη σημειωθεί ως μη εμφάνιση',
    cannotMarkAsCanceledGnet: 'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ληφθεί μέσω GNET',
    cannotMarkAsCanceledStatusRejected:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως απορριφθείσα',
    cannotMarkAsCanceledStatusExpired:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως λήξη',
    cannotMarkAsCanceledStatusCanceled:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως ακυρωμένη',
    cannotMarkAsCanceledStatusDraft:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως προσχέδιο',
    cannotMarkAsCanceledStatusCompleted:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως ολοκληρωμένη',
    cannotMarkAsCanceledStatusNoShow:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ήδη σημειωθεί ως μη εμφάνιση',
    cannotDeleteDrivingSentViaGnet: 'Η παραγγελία δεν μπορεί να διαγραφεί γιατί έχει αποσταλεί μέσω GNET',
    cannotDeleteDrivingReceivedViaGnet:
      'Η παραγγελία δεν μπορεί να διαγραφεί γιατί έχει ληφθεί μέσω GNET και έχει γίνει αποδεκτή',
    generatePaymentLink: 'Δημιουργία συνδέσμου πληρωμής',
    cannotGeneratePaymentLInk:
      'Ο σύνδεσμος πληρωμής δεν μπορεί να δημιουργηθεί γιατί δεν έχετε ενεργοποιήσει το Stripe',
    disabledExport: 'Μπορείτε να εξάγετε δεδομένα μία φορά κάθε 24 ώρες',
    sendSms: 'Αποστολή SMS',
    accept: 'Αποδοχή',
    reject: 'Απόρριψη',
    sendViaAffiliate: 'Αποστολή μέσω συνεργάτη',
    receivedViaAffiliate: 'Λήφθηκε μέσω συνεργάτη',
    sentViaAffiliate: 'Εστάλη μέσω συνεργάτη',
    cannotSendViaAffiliate: 'Δεν μπορείτε να στείλετε την παραγγελία συνεργάτη μέσω συνεργάτη.',
    cannotForwardDrivingOrder: 'Δεν μπορείτε να προωθήσετε παραγγελία διαδρομής που βρίσκεται στο παρελθόν.',
    cannotMarkAsCanceledAffiliate: 'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχει ληφθεί μέσω συνεργάτη',
    cannotMarkAsCanceled12Hours:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ακυρωμένη γιατί έχουν περάσει 12 ώρες από την ώρα παραλαβής.',
    cannotDeleteActiveDriving: 'Η ενεργή παραγγελία διαδρομής δεν μπορεί να διαγραφεί',
    noImageToShow: 'Δεν υπάρχει εικόνα για εμφάνιση',
    disabledForAffiliateUser:
      "Ο λογαριασμός σας είναι τύπου 'Συνεργάτης'. Αυτή η ενέργεια είναι διαθέσιμη μόνο σε συνδρομημένους λογαριασμούς [Κανονικός]. Μεταβείτε στο σύνδεσμο στο υποσέλιδο για εγγραφή.",
    markInvoiceAsPaid: 'Σήμανση τιμολογίου ως πληρωμένο',
    markDrivingOfferAsPaid: 'Σήμανση προσφοράς διαδρομής ως πληρωμένη',
    invoiceIsPaid: 'Το τιμολόγιο έχει ήδη σημειωθεί ως πληρωμένο',
    drivingOfferIsPaid: 'Η προσφορά διαδρομής έχει ήδη σημειωθεί ως πληρωμένη',
    selectAreaToDelete: 'Επιλέξτε περιοχή που θέλετε να διαγράψετε',
    deleteSelectedArea: 'Διαγραφή επιλεγμένης περιοχής',
    markAsStarted: 'Σήμανση ως ξεκίνησε',
    cannotMarkAsStarted: 'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί δεν έχει ακόμη πραγματοποιηθεί',
    cannotMarkAsStartedStatusStarted:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί έχει ήδη σημειωθεί ως ξεκίνησε',
    cannotMarkAsStartedStatusRejected:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί έχει ήδη σημειωθεί ως απορριφθείσα',
    cannotMarkAsStartedStatusCanceled:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί έχει ήδη σημειωθεί ως ακυρωμένη',
    cannotMarkAsStartedStatusDone:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί έχει ήδη σημειωθεί ως ολοκληρωμένη',
    cannotMarkAsStartedStatusNoShow:
      'Η παραγγελία δεν μπορεί να σημειωθεί ως ξεκίνησε γιατί έχει ήδη σημειωθεί ως μη εμφάνιση',
  },
  snackbar: {
    close: 'Κλείσιμο',
    savedSuccessfully: 'Αποθηκεύτηκε με επιτυχία',
    excelDownloadedSuccessfully: 'Λήφθηκε με επιτυχία',
    deletedSuccessfully: 'Διαγράφηκε με επιτυχία',
    sentSuccessfully: 'Εστάλη με επιτυχία',
    statusChanged: 'Η κατάσταση άλλαξε με επιτυχία',
    activeStatusChanged: 'Η ενεργή κατάσταση άλλαξε με επιτυχία',
    defaultStatusChanged: 'Η προεπιλεγμένη κατάσταση άλλαξε με επιτυχία',
    primaryStatusChanged: 'Η κύρια κατάσταση άλλαξε με επιτυχία',
    anErrorOccured: 'Προέκυψε σφάλμα, αναφέρετε στο τμήμα υποστήριξης',
    loadingCommentsFailed: 'Αποτυχία φόρτωσης σχολίων',
    drivingCanceled: 'Η παραγγελία διαδρομής ακυρώθηκε με επιτυχία',
    drivingFinished: 'Η παραγγελία διαδρομής ολοκληρώθηκε με επιτυχία',
    drivingStarted: 'Η παραγγελία διαδρομής σημειώθηκε ως ξεκίνησε με επιτυχία',
    drivingNoShow: 'Η παραγγελία διαδρομής σημειώθηκε ως μη εμφάνιση με επιτυχία',
    drivingDeleted: 'Η παραγγελία διαδρομής διαγράφηκε με επιτυχία',
    drivingAccepted: 'Η παραγγελία διαδρομής έγινε αποδεκτή με επιτυχία',
    drivingRejected: 'Η παραγγελία διαδρομής απορρίφθηκε με επιτυχία',
    planChanged: 'Το πλάνο άλλαξε με επιτυχία',
    planChangeFailed: 'Δεν είναι δυνατή η αλλαγή πλάνου',
    saveChangesFirst: 'Αποθηκεύστε πρώτα τις αλλαγές που κάνατε προηγουμένως',
    makeRoutesPublic: 'Οι διαδρομές είναι δημόσια προσβάσιμες',
    makeRoutesNotPublic: 'Οι διαδρομές δεν είναι πλέον δημόσια προσβάσιμες',
    makePublicLinkActive: 'Ο δημόσιος σύνδεσμος είναι τώρα διαθέσιμος',
    makePublicLinkInactive: 'Ο δημόσιος σύνδεσμος δεν είναι πλέον διαθέσιμος',
    integrationKeyCopied: 'Το κλειδί ενοποίησης αντιγράφηκε',
    copyToken: 'Αντιγράψτε το διακριτικό χειροκίνητα',
    publicLinkCopied: 'Ο δημόσιος σύνδεσμος αντιγράφηκε',
    copyPublicLink: 'Αντιγράψτε το δημόσιο σύνδεσμο χειροκίνητα',
    organisationChanged: 'Η εταιρεία άλλαξε με επιτυχία',
    changeFailed: 'Η αλλαγή απέτυχε, παρακαλώ δοκιμάστε ξανά',
    loginFailed: 'Λανθασμένο όνομα χρήστη ή κωδικός πρόσβασης',
    organisationSuspended: 'Η οργάνωσή σας έχει ανασταλεί',
    userEmailDoesNotExists: 'Δεν υπάρχει χρήστης με αυτό το email',
    canceledSubscrSuccessfully: 'Η συνδρομή ακυρώθηκε με επιτυχία',
    toManyRequests: 'Πολλές αιτήσεις. Δοκιμάστε ξανά σε ένα λεπτό.',
    visibleStatusChanged: 'Η ορατή κατάσταση άλλαξε με επιτυχία',
    noInternetConnection: 'Δεν υπάρχει σύνδεση στο διαδίκτυο. Ελέγξτε τη σύνδεσή σας.',
    backOnline: 'Είστε ξανά online.',
    driverNotifiedToTurnGpsOn: 'Ο οδηγός ειδοποιήθηκε να ενεργοποιήσει το GPS',
    savedSuccessfullyWithGnetError: 'Η συγχρονισμός GNET απέτυχε, η παραγγελία διαδρομής αποθηκεύτηκε με επιτυχία.',
    logoutFromAnotherDevice: 'Έχετε αποσυνδεθεί από άλλη συσκευή.',
    stripeEnabled: 'Το Stripe ενεργοποιήθηκε με επιτυχία',
    stripeDisabled: 'Το Stripe απενεργοποιήθηκε με επιτυχία',
    finishStripeConnect: 'Ολοκληρώστε τη ρύθμιση του Stripe σας',
    requestSentSuccessfully: 'Το αίτημα εστάλη με επιτυχία',
    pricingZoneNoMatch: 'Δεν μπορούμε να βρούμε τις αντίστοιχες ζώνες τιμολόγησης για υπολογισμό της τιμής.',
    areaAlreadyExists: 'Αυτή η περιοχή υπάρχει ήδη',
    noDataForThisPostalCode: 'Δεν υπάρχουν δεδομένα για αυτόν τον ταχυδρομικό κώδικα',
    affiliateRequestSentSuccessfully: 'Ο οργανισμός υπάρχει ήδη, το αίτημα συνεργάτη εστάλη',
    organizationRegisteredSuccessfully: 'Ο οργανισμός καταχωρήθηκε με επιτυχία',
    organizationIsAlreadyAPartner: 'Ο οργανισμός είναι ήδη συνεργάτης',
    linkCopied: 'Ο σύνδεσμος αντιγράφηκε',
    recordAlreadyExists: 'Αυτή η εγγραφή υπάρχει ήδη.',
    tooManyUsers: 'Έχετε πάρα πολλούς χρήστες για αυτό το πακέτο',
    priceCalculationFailed: 'Αποτυχία υπολογισμού της τιμής',
  },
  filters: {
    filter: 'Φίλτρο',
    currentYear: 'Τρέχον έτος',
    lastYear: 'Προηγούμενο έτος',
    lastQuarter: 'Τελευταίο τρίμηνο',
    lastThreeYears: 'Τα τελευταία τρία χρόνια',
    filterByDriver: 'Φιλτράρισμα κατά οδηγό',
    filterByVehicle: 'Φιλτράρισμα κατά όχημα',
    filterByClient: 'Φιλτράρισμα κατά πελάτες',
    day: 'Ημέρα',
    week: 'Εβδομάδα',
    month: 'Μήνας',
    vehicle: 'Όχημα',
    driving: 'Διαδρομή',
    user: 'Χρήστης',
    fromDate: 'Από ημερομηνία',
    toDate: 'Έως ημερομηνία',
    threeMonths: 'Τρεις μήνες',
    halfYear: 'Εξάμηνο',
    year: 'Έτος',
  },
  months: {
    jan: 'Ιανουάριος',
    feb: 'Φεβρουάριος',
    mar: 'Μάρτιος',
    apr: 'Απρίλιος',
    may: 'Μάιος',
    jun: 'Ιούνιος',
    jul: 'Ιούλιος',
    aug: 'Αύγουστος',
    sep: 'Σεπτέμβριος',
    oct: 'Οκτώβριος',
    nov: 'Νοέμβριος',
    dec: 'Δεκέμβριος',
    January: 'Ιανουάριος',
    February: 'Φεβρουάριος',
    March: 'Μάρτιος',
    April: 'Απρίλιος',
    May: 'Μάιος',
    June: 'Ιούνιος',
    July: 'Ιούλιος',
    August: 'Αύγουστος',
    September: 'Σεπτέμβριος',
    October: 'Οκτώβριος',
    November: 'Νοέμβριος',
    December: 'Δεκέμβριος',
  },
  usersPageHeaders: {
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    username: 'Όνομα χρήστη',
    email: 'Email',
    phoneNumber: 'Αριθμός τηλεφώνου',
    role: 'Ρόλος',
    active: 'Ενεργός',
    actions: 'Ενέργειες',
  },
  usersRoles: {
    masterAdmin: 'Κύριος διαχειριστής',
    manager: 'Διαχειριστής',
    driver: 'Οδηγός',
    operator: 'Χειριστής',
    externalAssociate: 'Εξωτερικός συνεργάτης',
    sales: 'Πωλήσεις',
    salesLead: 'Υπεύθυνος πωλήσεων',
    gnet: 'GNET',
    customer: 'Χρήστης πύλης',
    publicUser: 'Δημόσιος χρήστης',
  },
  masterUsersPageHeaders: {
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    username: 'Όνομα χρήστη',
    email: 'Email',
    phoneNumber: 'Αριθμός τηλεφώνου',
    role: 'Ρόλος',
    organisation: 'Οργάνωση',
    active: 'Ενεργός',
    actions: 'Ενέργειες',
  },
  usersPage: {
    newItem: 'Νέα εγγραφή χρήστη',
    editItem: 'Επεξεργασία χρήστη',
    id: 'Κωδικός',
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    username: 'Όνομα χρήστη',
    password: 'Κωδικός πρόσβασης',
    phoneNumber: 'Αριθμός τηλεφώνου',
    role: 'Ρόλος',
    client: 'Πελάτης',
    email: 'Email',
    organisation: 'Οργάνωση',
    percentage: 'Ποσοστό',
    externalAssociate: 'Εξωτερικός συνεργάτης',
    image: 'Εικόνα',
    deleteUser: 'Διαγραφή χρήστη',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;',
    emailChanged: 'Αλλαγή email',
    verificationEmailSent:
      'Το αίτημα αλλαγής email έχει σταλεί. Ο νέος email πρέπει να επαληθευτεί για να ολοκληρωθεί η διαδικασία.',
    passengersInfoAvailableToDriver: 'Οι πληροφορίες επιβατών είναι διαθέσιμες στον οδηγό',
    colorPicker: 'Μπορείτε να επιλέξετε μοναδικό χρώμα για κάθε χρήστη για εύκολη ταυτοποίηση.',
  },
  clientsPageHeaders: {
    name: 'Όνομα',
    phoneNumber: 'Αριθμός τηλεφώνου',
    address: 'Διεύθυνση',
    email: 'Email',
    companyId: 'ΑΦΜ/Αριθμός Ταυτότητας',
    clientType: 'Τύπος πελάτη',
    percentage: 'Ποσοστό',
    active: 'Ενεργός',
    actions: 'Ενέργειες',
  },
  clientsPage: {
    newItem: 'Νέα εγγραφή πελάτη',
    editItem: 'Επεξεργασία πελάτη',
    id: 'Κωδικός',
    name: 'Όνομα',
    address: 'Διεύθυνση',
    phoneNumber: 'Αριθμός τηλεφώνου',
    email: 'Email',
    companyId: 'ΑΦΜ/Αριθμός Ταυτότητας',
    clientType: 'Τύπος πελάτη',
    individual: 'Ιδιώτης',
    business: 'Επιχείρηση',
    percentage: 'Ποσοστό',
    deleteClient: 'Διαγραφή πελάτη',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον πελάτη;',
    tabs: {
      general: 'Γενικά',
      transfers: 'Μεταφορές',
      users: 'Χρήστες',
      invoices: 'Τιμολόγια',
      cards: 'Κάρτες',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Αριθμός παραγγελίας διαδρομής',
    drivingType: 'Τύπος κράτησης',
    drivingStatus: 'Κατάσταση',
    locationFrom: 'Σημείο εκκίνησης',
    locationTo: 'Προορισμός',
    vehicleClass: 'Κατηγορία οχήματος',
    vehicle: 'Όχημα',
    driversName: 'Όνομα οδηγού',
    startTime: 'Ώρα παραλαβής',
    actions: 'Ενέργειες',
    exportExcel: 'Εξαγωγή σε Excel',
    price: 'Τιμή',
    totalPrice: 'Συνολική τιμή',
    confirmed: 'Επιβεβαιωμένο',
    paid: 'Πληρωμένο',
    numberOfDrivings: 'Αριθμός παραγγελιών διαδρομής',
  },
  vehiclesHeaders: {
    plateNumber: 'Αριθμός κυκλοφορίας',
    brand: 'Μάρκα οχήματος',
    vehicleClass: 'Κατηγορία οχήματος',
    passengersNumber: 'Αριθμός επιβατών',
    fuelConsumption: 'Κατανάλωση καυσίμου',
    yearOfManufacture: 'Έτος κατασκευής',
    pricePerKm: 'Τιμή ανά χλμ',
    pricePerMi: 'Τιμή ανά μίλι',
    pricePerHour: 'Τιμή ανά ώρα',
    pricePerWaitingHour: 'Τιμή ανά ώρα αναμονής',
    active: 'Ενεργό',
    actions: 'Ενέργειες',
  },
  vehiclesPage: {
    id: 'Κωδικός',
    newItem: 'Νέα εγγραφή οχήματος',
    editItem: 'Αλλαγή οχήματος',
    vehiclePlates: 'Πινακίδες κυκλοφορίας',
    vehicleBrand: 'Μάρκα οχήματος',
    vehicleClass: 'Κατηγορία οχήματος',
    passengersNumber: 'Αριθμός επιβατών',
    fuelConsumption: 'Κατανάλωση καυσίμου (100 χλμ)',
    fuelConsumptionMi: 'Κατανάλωση καυσίμου (100 μίλια)',
    manufactureYear: 'Έτος κατασκευής',
    price: 'Τιμή ανά χλμ',
    priceMi: 'Τιμή ανά μίλι',
    hourPrice: 'Τιμή ανά ώρα',
    image: 'Εικόνα',
    hourWaitingPrice: 'Τιμή ανά ώρα αναμονής',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το όχημα;',
    tabs: {
      general: 'Γενικά',
      transfers: 'Μεταφορές',
      costs: 'Κόστη',
      damages: 'Βλάβες',
    },
    licence: 'Αριθμός άδειας',
    color: 'Χρώμα',
    colorPicker: 'Επιλέξτε μοναδικό χρώμα για κάθε όχημα για ευκολότερη αναγνώριση.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Κατηγορία οχήματος',
    numberOfPassengers: 'Αριθμός επιβατών',
    numberOfSuitcases: 'Αριθμός αποσκευών',
    pricePerKm: 'Τιμή ανά χλμ',
    pricePerMi: 'Τιμή ανά μίλι',
    pricePerHour: 'Τιμή ανά ώρα',
    pricePerWaitingHour: 'Τιμή ανά ώρα αναμονής',
    availableForPublicBookingForm: 'Διαθέσιμο για δημόσια φόρμα κράτησης',
    active: 'Ενεργό',
    actions: 'Ενέργειες',
  },
  vehicleClassesPage: {
    newItem: 'Νέα εγγραφή κατηγορίας οχήματος',
    editItem: 'Αλλαγή κατηγορίας οχήματος',
    id: 'Κωδικός',
    vehicleClass: 'Κατηγορία οχήματος',
    numberOfPassengers: 'Αριθμός επιβατών',
    numberOfSuitcases: 'Αριθμός αποσκευών',
    availableForPublicBookingForm: 'Διαθέσιμο για δημόσια φόρμα κράτησης',
    price: 'Τιμή ανά χλμ',
    priceMi: 'Τιμή ανά μίλι',
    hourPrice: 'Τιμή ανά ώρα',
    image: 'Εικόνα',
    hourWaitingPrice: 'Τιμή ανά ώρα αναμονής',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την κατηγορία οχήματος;',
  },
  expensesHeaders: {
    name: 'Όνομα',
    vehicle: 'Όχημα',
    type: 'Τύπος',
    description: 'Περιγραφή',
    price: 'Τιμή',
    expenseDate: 'Ημερομηνία',
    actions: 'Ενέργειες',
    driving: 'Διαδρομή',
    exportExcel: 'Εξαγωγή σε Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Μικρή συντήρηση',
    majorService: 'Μεγάλη συντήρηση',
    fuel: 'Καύσιμα',
    tires: 'Ελαστικά',
    parking: 'Στάθμευση',
    other: 'Άλλο',
  },
  expensesPage: {
    newItem: 'Νέα εγγραφή κόστους',
    editItem: 'Αλλαγή κόστους',
    id: 'Κωδικός',
    name: 'Όνομα',
    vehicle: 'Όχημα',
    costType: 'Τύπος κόστους',
    price: 'Τιμή',
    currency: 'Νόμισμα',
    costTime: 'Ώρα κόστους',
    description: 'Περιγραφή',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το κόστος;',
    costTypes: {
      'Mali servis': 'Μικρή συντήρηση',
      'Veliki servis': 'Μεγάλη συντήρηση',
      Gorivo: 'Καύσιμα',
      Gume: 'Ελαστικά',
      Parking: 'Στάθμευση',
      Ostalo: 'Άλλο',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Όχημα',
    details: 'Λεπτομέρειες',
    dateFrom: 'Ημερομηνία από',
    dateTo: 'Ημερομηνία έως',
    actions: 'Ενέργειες',
  },
  vehicleMaintenancePage: {
    newItem: 'Νέα εγγραφή βλάβης οχήματος',
    editItem: 'Αλλαγή βλάβης οχήματος',
    id: 'Κωδικός',
    vehicle: 'Όχημα',
    description: 'Περιγραφή',
    dateFrom: 'Ώρα από',
    dateTo: 'Ώρα έως',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη βλάβη;',
  },
  customerCardsHeaders: {
    cardholderName: 'Όνομα κατόχου κάρτας',
    cardNumber: 'Αριθμός κάρτας',
    expiryDate: 'Ημερομηνία λήξης',
    cvv: 'CVV',
    actions: 'Ενέργειες',
  },
  customerCardsPage: {
    newItem: 'Νέα εγγραφή κάρτας',
    editItem: 'Αλλαγή κάρτας',
    cardholderName: 'Όνομα κατόχου κάρτας',
    cardNumber: 'Αριθμός κάρτας',
    expiryDate: 'Ημερομηνία λήξης',
    cvv: 'CVV',
    deleteCard: 'Διαγραφή κάρτας',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την κάρτα;',
  },
  pricingRegionsHeaders: {
    name: 'Όνομα',
    actions: 'Ενέργειες',
  },
  pricingRegionsPage: {
    newItem: 'Νέα εγγραφή περιοχής τιμολόγησης',
    editItem: 'Αλλαγή περιοχής τιμολόγησης',
    name: 'Όνομα',
    chooseCurrency: 'Επιλέξτε νόμισμα',
    soloPrice: 'Τιμή ατομικής χρήσης - Έτος',
    soloPaddlePriceId: 'ID τιμής Paddle ατομικής χρήσης - Έτος',
    team5Price: 'Τιμή για ομάδα 5 ατόμων - Έτος',
    team5PaddlePriceId: 'ID τιμής Paddle για ομάδα 5 ατόμων - Έτος',
    team10Price: 'Τιμή για ομάδα 10 ατόμων - Έτος',
    team10PaddlePriceId: 'ID τιμής Paddle για ομάδα 10 ατόμων - Έτος',
    team25Price: 'Τιμή για ομάδα 25 ατόμων - Έτος',
    team25PaddlePriceId: 'ID τιμής Paddle για ομάδα 25 ατόμων - Έτος',
    team50Price: 'Τιμή για ομάδα 50 ατόμων - Έτος',
    team50PaddlePriceId: 'ID τιμής Paddle για ομάδα 50 ατόμων - Έτος',
    soloPriceMonthly: 'Τιμή ατομικής χρήσης - Μήνας',
    soloPaddlePriceIdMonthly: 'ID τιμής Paddle ατομικής χρήσης - Μήνας',
    team5PriceMonthly: 'Τιμή για ομάδα 5 ατόμων - Μήνας',
    team5PaddlePriceIdMonthly: 'ID τιμής Paddle για ομάδα 5 ατόμων - Μήνας',
    team10PriceMonthly: 'Τιμή για ομάδα 10 ατόμων - Μήνας',
    team10PaddlePriceIdMonthly: 'ID τιμής Paddle για ομάδα 10 ατόμων - Μήνας',
    team25PriceMonthly: 'Τιμή για ομάδα 25 ατόμων - Μήνας',
    team25PaddlePriceIdMonthly: 'ID τιμής Paddle για ομάδα 25 ατόμων - Μήνας',
    team50PriceMonthly: 'Τιμή για ομάδα 50 ατόμων - Μήνας',
    team50PaddlePriceIdMonthly: 'ID τιμής Paddle για ομάδα 50 ατόμων - Μήνας',
    chooseCountries: 'Επιλέξτε χώρες',
    description: 'Περιγραφή',
    deletePricingRegion: 'Διαγραφή περιοχής τιμολόγησης',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την περιοχή τιμολόγησης;',
  },
  notificationsHeaders: {
    time: 'Ώρα',
    notification: 'Ειδοποίηση',
  },
  notificationsPage: {
    showUnseenOnly: 'Εμφάνιση μόνο μη αναγνωσμένων',
    markAllAsRead: 'Σήμανση όλων ως αναγνωσμένων',
  },
  organisationsHeaders: {
    name: 'Οργάνωση',
    phoneNumber: 'Τηλέφωνο',
    email: 'Email',
    address: 'Διεύθυνση',
    companyId: 'Αριθμός Μητρώου',
    vat: 'ΑΦΜ',
    active: 'Ενεργή',
    createdAt: 'Εγγεγραμμένη',
    actions: 'Ενέργειες',
    billingDate: 'Ημερομηνία τιμολόγησης',
    referral: 'Παραπέμπων',
  },
  organisationsPage: {
    newItem: 'Νέα εγγραφή χρήστη',
    editItem: 'Επεξεργασία χρήστη',
    id: 'Κωδικός',
    name: 'Οργάνωση',
    address: 'Διεύθυνση',
    phoneNumber: 'Τηλέφωνο',
    email: 'Email',
    vat: 'ΑΦΜ',
    companyId: 'Αριθμός Μητρώου',
    warrantNumber: 'Αριθμός απόφασης',
    image: 'Εικόνα',
    signature: 'Υπογραφή',
    seal: 'Σφραγίδα',
    deactivateMessage: 'Είστε σίγουροι ότι θέλετε να',
    deactivate: 'ΑΠΕΝΕΡΓΟΠΟΙΗΣΗ',
    activate: 'ΕΝΕΡΓΟΠΟΙΗΣΗ',
    organisation: 'οργάνωση',
    deleteOrganisation: 'Διαγραφή οργάνωσης',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την οργάνωση;',
    cancelSubscrMessage:
      'Είστε σίγουροι ότι θέλετε να ακυρώσετε τη συνδρομή σας; Λάβετε υπόψη ότι με την επιβεβαίωση αυτής της ενέργειας: 1. Ο λογαριασμός της οργάνωσής σας και όλα τα σχετικά δεδομένα θα διαγραφούν μόνιμα. Αυτή η ενέργεια δεν μπορεί να αναιρεθεί. 2. Θα αποσυνδεθείτε αυτόματα από το σύστημα αμέσως μετά την ακύρωση. 3. Δεν θα έχετε πλέον πρόσβαση ή δυνατότητα ανάκτησης οποιουδήποτε δεδομένου που σχετίζεται με την οργάνωσή σας στο μέλλον. Σας συνιστούμε ανεπιφύλακτα να επανεξετάσετε τις απαραίτητες πληροφορίες ή να εξαγάγετε οποιαδήποτε κρίσιμα δεδομένα πριν προχωρήσετε σε αυτήν την μη αναστρέψιμη ενέργεια. Αν έχετε ερωτήσεις ή χρειάζεστε βοήθεια, μη διστάσετε να επικοινωνήσετε με την ομάδα υποστήριξής μας. Θέλετε να συνεχίσετε με την ακύρωση;',
    allDrivings: 'Συνολικός αριθμός παραγγελιών διαδρομής',
    allDrivingsAccepted: 'Συνολικές αποδεκτές παραγγελίες διαδρομής',
    allDrivingsCanceled: 'Συνολικές απορριφθείσες παραγγελίες διαδρομής',
    allVehicles: 'Συνολικός αριθμός οχημάτων',
    allUsers: 'Συνολικός αριθμός χρηστών',
    areYouSure: 'Είστε σίγουροι;',
    makeRoutesAvailable: 'Κάντε τις διαδρομές ενσωμάτωσης διαθέσιμες',
    percentage: 'Ποσοστό',
    deleteImageMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την εικόνα;',
    deleteImageTitle: 'Διαγραφή εικόνας',
    cancelSubscriptionTitle: 'Ακύρωση συνδρομής',
    subscriptionType: 'Τύπος συνδρομής',
    billingDate: 'Ημερομηνία τιμολόγησης',
    trialEndsAtDate: 'Ημερομηνία λήξης δοκιμαστικής περιόδου',
    articlesOfLaw: 'Άρθρα νόμου',
    country: 'Χώρα',
    referent: 'Αναφορά',
    language: 'Γλώσσα',
    gnetId: 'GNET ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Η δοκιμαστική περίοδος λήγει',
    languageTooltip:
      'Προεπιλεγμένη γλώσσα για νέους χρήστες και η γλώσσα στην οποία θα αποστέλλονται όλα τα email της εταιρείας (όχι τα email των μεμονωμένων χρηστών).',
    organisationType: 'Τύπος οργάνωσης',
    regular: 'Κανονική',
    affiliateSender: 'Αποστολέας συνεργατών',
    affiliateReceiver: 'Παραλήπτης συνεργατών',
    dispatcher: 'Διαχειριστής',
    referral: 'Παραπέμπων',
    selectLocation: 'Τοποθεσία έδρας',
  },
  transactionsHeaders: {
    invoiceNumber: 'Αριθμός τιμολογίου',
    status: 'Κατάσταση',
    total: 'Σύνολο',
    tax: 'Φόρος',
    currency: 'Νόμισμα',
    billedAt: 'Τιμολογήθηκε στις',
    actions: 'Ενέργειες',
  },
  transactionsPage: {
    newItem: 'Νέα εγγραφή συναλλαγής',
    editItem: 'Επεξεργασία συναλλαγής',
    id: 'Κωδικός',
    invoiceNumber: 'Αριθμός τιμολογίου',
    status: 'Κατάσταση',
    sent: 'Απεσταλμένο',
    completed: 'Ολοκληρωμένο',
    total: 'Σύνολο',
    currency: 'Νόμισμα',
    billedAt: 'Τιμολογήθηκε στις',
    actions: 'Ενέργειες',
    deleteTransaction: 'Διαγραφή συναλλαγής',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη συναλλαγή;',
    currentSubscription: 'Τρέχον πακέτο',
    nextBillingDate: 'Επόμενη ημερομηνία τιμολόγησης',
    numberOfSeatsTaken: 'Αριθμός κατειλημμένων θέσεων',
    numberOfSeatsAvailable: 'Αριθμός διαθέσιμων θέσεων',
  },
  currenciesHeaders: {
    name: 'Όνομα',
    code: 'Κωδικός',
    symbol: 'Σύμβολο',
    active: 'Ενεργό',
    default: 'Κύριο',
    actions: 'Ενέργειες',
  },
  currenciesPage: {
    newItem: 'Νέα εγγραφή νομίσματος',
    editItem: 'Αλλαγή νομίσματος',
    id: 'Κωδικός',
    currency: 'Νόμισμα',
    code: 'Κωδικός',
    symbol: 'Σύμβολο',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το νόμισμα;',
  },
  paymentMethodsHeaders: {
    name: 'Όνομα',
    active: 'Ενεργό',
    default: 'Κύριο',
    priceVisible: 'Ορατή τιμή',
    actions: 'Ενέργειες',
  },
  paymentMethodsPage: {
    newItem: 'Νέα εγγραφή τρόπου πληρωμής',
    editItem: 'Αλλαγή τρόπου πληρωμής',
    id: 'Κωδικός',
    paymentMethod: 'Τρόπος πληρωμής',
    deleteMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον τρόπο πληρωμής;',
  },
  feedbacksHeaders: {
    subject: 'Όνομα',
    organisation: 'Οργάνωση',
    user: 'Χρήστης',
    actions: 'Ενέργειες',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Τοποθεσία στάσης',
    arrivalTime: 'Ώρα άφιξης',
    actions: 'Ενέργειες',
  },
  tables: {
    noDataText: 'Δεν υπάρχουν δεδομένα',
    search: 'Αναζήτηση',
    drivings: 'Παραγγελίες διαδρομών',
    drivingsByPage: 'Παραγγελίες διαδρομών ανά σελίδα',
    vehicles: 'Οχήματα',
    vehiclesByPage: 'Οχήματα ανά σελίδα',
    vehiclesClasses: 'Κατηγορίες οχημάτων',
    vehiclesClassesByPage: 'Κατηγορίες οχημάτων ανά σελίδα',
    expenses: 'Κόστη',
    expensesByPage: 'Κόστη ανά σελίδα',
    vehiclesMaintenance: 'Βλάβες οχημάτων',
    vehiclesMaintenanceByPage: 'Βλάβες οχημάτων ανά σελίδα',
    reportsByPage: 'Αναφορές ανά σελίδα',
    currencies: 'Νομίσματα',
    currencieseByPage: 'Νομίσματα ανά σελίδα',
    payments: 'Τρόποι πληρωμής',
    paymentsByPage: 'Τρόποι πληρωμής ανά σελίδα',
    saasAdministrators: 'Όλοι οι διαχειριστές',
    users: 'Όλοι οι χρήστες',
    usersByPage: 'Χρήστες ανά σελίδα',
    clients: 'Όλοι οι πελάτες',
    clientsByPage: 'Πελάτες ανά σελίδα',
    organisations: 'Όλες οι οργανώσεις',
    organisationsByPage: 'Οργανώσεις ανά σελίδα',
    cards: 'Κάρτες',
    cardsByPage: 'Κάρτες ανά σελίδα',
    transactions: 'Συναλλαγές',
    transactionsByPage: 'Συναλλαγές ανά σελίδα',
    pricingRegions: 'Περιοχές τιμολόγησης',
    pricingRegionsByPage: 'Περιοχές τιμολόγησης ανά σελίδα',
    feedback: 'Ανατροφοδοτήσεις',
    feedbacksByPage: 'Ανατροφοδοτήσεις ανά σελίδα',
    notifications: 'Ειδοποιήσεις',
    notificationsByPage: 'Ειδοποιήσεις ανά σελίδα',
    all: 'Όλα',
    passengers: 'Επιβάτες',
    passengersByPage: 'Επιβάτες ανά σελίδα',
    of: 'από',
    checkpoints: 'Στάσεις',
    checkpointsByPage: 'Στάσεις ανά σελίδα',
    invoices: 'Τιμολόγια',
    invoicesByPage: 'Τιμολόγια ανά σελίδα',
    drivingOffers: 'Προσφορές διαδρομών',
    drivingOffersByPage: 'Προσφορές ανά σελίδα',
    resourcesUsed: 'Χρησιμοποιηθέντες πόροι',
    resourcesUsedByPage: 'Πόροι ανά σελίδα',
    inNext3Days: 'Στις επόμενες 3 ημέρες',
    notConfirmedOrPaid: 'Μη επιβεβαιωμένες ή απλήρωτες',
    inNext5Days: 'Στις επόμενες 5 ημέρες',
    latestReviews: 'Πρόσφατες κριτικές',
    passengerReviews: 'Κριτικές επιβατών',
    driversByPage: 'Οδηγοί ανά σελίδα',
    reviewsByPage: 'Κριτικές ανά σελίδα',
    partners: 'Συνεργάτες',
    partnersByPage: 'Συνεργάτες ανά σελίδα',
    sentRequests: 'Απεσταλμένα αιτήματα',
    receivedRequests: 'Εισερχόμενα αιτήματα',
    requestsByPage: 'Αιτήματα ανά σελίδα',
    pricingZones: 'Ζώνες και περιοχές',
    pricingZonesByPage: 'Ζώνες και περιοχές ανά σελίδα',
    pricingPerZones: 'Τιμολόγηση ανά ζώνες',
    pricingPerZonesByPage: 'Τιμολόγηση ανά σελίδα',
    pricingPerHour: 'Τιμολόγηση ανά ώρα',
    pricingPerHourByPage: 'Τιμολόγηση ανά σελίδα',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Αριθμός αποδεκτών παραγγελιών διαδρομών',
    numberOfRejectedDrivings: 'Αριθμός απορριφθεισών παραγγελιών διαδρομών ανά οδηγό',
    vehicleDrivingsNumber: 'Αριθμός παραγγελιών διαδρομών ανά όχημα',
    vehicleProfit: 'Κέρδη ανά όχημα',
    monthDrivingsNumber: 'Αριθμός παραγγελιών διαδρομών ανά μήνα',
    driverProfit: 'Κέρδη ανά οδηγό',
  },
  reportsHeaders: {
    vehicle: 'Όχημα',
    expenses: 'Έξοδα',
    profit: 'Κέρδος',
    earnings: 'Έσοδα',
    daysWorking: 'Ημέρες εργασίας',
    usageIndex: 'Δείκτης χρήσης',
    client: 'Πελάτης',
    numberOfDrivings: 'Αριθμός διαδρομών',
    percentForClient: 'Τιμή για τον πελάτη',
    sumOfPrice: 'Σύνολο ποσού',
  },
  packages: {
    active: 'Ενεργό',
    starterTitle: 'Βασικό',
    monthlyPackagesMessage: 'Μηνιαία συνδρομή ανά οργανισμό.',
    starterText: 'Ιδανικό για άτομα ή μικρές ομάδες που χρειάζονται μόνο βασικές λειτουργίες.',
    starterListItemOne: 'Εφαρμογές για κινητά',
    starterListItemTwo: 'Ειδοποιήσεις push',
    starterListItemThree: 'Πρόσβαση στη διαχείριση',
    starterListItemFour: 'Ενσωμάτωση ιστοσελίδας',
    standardTitle: 'Κανονικό',
    standardText: 'Ιδανικό για οργανισμούς με πάνω από 5 οδηγούς.',
    standardListItemOne: 'Συμπεριλαμβάνεται το πακέτο ΒΑΣΙΚΟ',
    standardListItemTwo: 'Δεν υπάρχει όριο στον αριθμό των οδηγών',
    standardListItemThree: '99.9% Διαθεσιμότητα',
    standardListItemFour: 'Αναφορές & Διαχείριση Οχημάτων',
    premiumTitle: 'Πλήρες',
    premiumText: 'Ιδανικό για οργανισμούς υπηρεσιών λιμουζίνων που λειτουργούν με ακριβή δεδομένα.',
    premiumListItemOne: 'Συμπεριλαμβάνεται το πακέτο ΚΑΝΟΝΙΚΟ',
    premiumListItemTwo: 'BI & Πρόβλεψη Κόστους',
    premiumListItemThree: 'Εξαγωγή δεδομένων',
    premiumListItemFour: 'Εβδομαδιαίες και Μηνιαίες Αναφορές',
    warningOne: '* Οι τιμές που εμφανίζονται είναι μηνιαία συνδρομή ανά χρήστη',
    warningTwo:
      '* Μετά την αλλαγή σε νέο πακέτο, δεν είναι δυνατή η επιστροφή σε μικρότερο πακέτο για τους επόμενους 6 μήνες',
    warningThree: '* Οι χρήστες του demo plan έχουν πρόσβαση στις λειτουργίες του premium πακέτου',
    warningOneAnnualPackage: 'Χρειάζεστε περισσότερα; Διαθέτουμε πακέτο ENTERPRISE για εσάς.',
    warningTwoAnnualPackage: 'και η ομάδα πωλήσεών μας θα επικοινωνήσει σύντομα μαζί σας.',
    packageChange: 'Αλλαγή πακέτου',
    changePackage: 'Είστε σίγουροι ότι θέλετε να αλλάξετε στο',
    package: 'πακέτο',
    annualPricing: 'Ετήσια τιμολόγηση',
    monthlyPricing: 'Μηνιαία τιμολόγηση',
    packageType5: 'Ομάδα 5',
    packageType10: 'Ομάδα 10',
    packageType25: 'Ομάδα 25',
    packageType50: 'Ομάδα 50',
    'Solo - Yearly': 'Ατομικό - Ετήσιο',
    'Team 5 - Yearly': 'Ομάδα 5 - Ετήσιο',
    'Team 10 - Yearly': 'Ομάδα 10 - Ετήσιο',
    'Team 25 - Yearly': 'Ομάδα 25 - Ετήσιο',
    'Team 50 - Yearly': 'Ομάδα 50 - Ετήσιο',
    'Solo - Monthly': 'Ατομικό - Μηνιαίο',
    'Team 5 - Monthly': 'Ομάδα 5 - Μηνιαίο',
    'Team 10 - Monthly': 'Ομάδα 10 - Μηνιαίο',
    'Team 25 - Monthly': 'Ομάδα 25 - Μηνιαίο',
    'Team 50 - Monthly': 'Ομάδα 50 - Μηνιαίο',
    annualPackagesMessage: 'Ετήσια συνδρομή ανά οργανισμό.',
    packageTypeSoloMainMessage: 'Μόνο 1 λογαριασμός χρήστη.',
    packageType5MainMessage: 'Έως 5 λογαριασμοί χρηστών.',
    packageType10MainMessage: 'Έως 10 λογαριασμοί χρηστών.',
    packageType25MainMessage: 'Έως 25 λογαριασμοί χρηστών.',
    packageType50MainMessage: 'Έως 50 λογαριασμοί χρηστών.',
    allFeaturesIncluded: 'Όλες οι λειτουργίες συμπεριλαμβάνονται.',
    unlimitedRides: 'Απεριόριστος αριθμός διαδρομών και οχημάτων.',
    trialPeriod: 'Περιλαμβάνεται δοκιμαστική περίοδος.',
    onboardingFeeOptional: 'Προαιρετικό τέλος εκπαίδευσης.',
    noHiddenCosts: 'Χωρίς κρυφές χρεώσεις.',
    freeSmsFlights10: 'Δωρεάν 15 SMS και 50 έλεγχοι πτήσεων μηνιαίως.',
    freeSmsFlights25: 'Δωρεάν 35 SMS και 100 έλεγχοι πτήσεων μηνιαίως.',
    freeSmsFlights50: 'Δωρεάν 50 SMS και 200 έλεγχοι πτήσεων μηνιαίως.',
  },
  successPaymentPage: {
    message: 'Η πληρωμή σας ολοκληρώθηκε με επιτυχία. Σας ευχαριστούμε.',
  },
  demoExpiring: {
    notification: 'Ειδοποίηση',
    expiredSubscriptionMessage:
      'Σας ενημερώνουμε ότι η συνδρομή σας στην πλατφόρμα έχει λήξει. Για να συνεχίσετε να χρησιμοποιείτε τις υπηρεσίες μας, παρακαλώ ανανεώστε τη συνδρομή σας.',
  },
  login: {
    username: 'Όνομα χρήστη',
    password: 'Κωδικός πρόσβασης',
    rememberMe: 'Να με θυμάσαι',
    login: 'Σύνδεση',
    noAccount: 'Δεν έχετε ακόμα λογαριασμό; Μπορείτε να εγγραφείτε',
    forgetPassword: 'Ξεχάσατε τον κωδικό σας; Μπορείτε να τον επαναφέρετε',
    registerHere: 'Εδώ',
    email: 'Email',
    enterEmail: 'Παρακαλώ εισάγετε το email σας',
    enterPassword: 'Παρακαλώ εισάγετε τον νέο σας κωδικό πρόσβασης',
    signInWithGoogle: 'Σύνδεση με Google',
    passwordChangeError:
      'Κάτι πήγε στραβά με την αλλαγή του κωδικού πρόσβασης. Δοκιμάστε ξανά ή επικοινωνήστε με την υποστήριξη.',
  },
  registration: {
    register: 'Εγγραφή',
    name: 'Όνομα οργανισμού',
    address: 'Διεύθυνση',
    phoneNumber: 'Αριθμός τηλεφώνου',
    email: 'Email',
    companyId: 'ΑΦΜ',
    vat: 'ΦΠΑ',
    language: 'Γλώσσα',
    package: 'Πακέτο',
    accept: 'Αποδέχομαι',
    terms: 'τους όρους χρήσης',
    successfullyRegistered: 'Ο χρήστης έχει εγγραφεί με επιτυχία. Θα λάβετε ένα email με σύνδεσμο επιβεβαίωσης.',
    haveAccount: 'Έχετε ήδη λογαριασμό; Μπορείτε να συνδεθείτε',
    loginHere: 'Εδώ',
    trialPeriodInfo:
      'Συμπεριλαμβάνεται δωρεάν δοκιμαστική περίοδος 14 ημερών. Η χρέωση θα ακολουθήσει μετά το τέλος της δοκιμαστικής περιόδου.',
  },
  driving: {
    drivingType: 'Τύπος κράτησης',
    general: 'Γενικές πληροφορίες',
    newDriving: 'Προγραμματισμός μεταφοράς',
    newDailyRent: 'Προγραμματισμός ημερήσιας ενοικίασης',
    areYouSure: 'Είστε σίγουροι;',
    vehicleIsUnavailable: 'Το όχημα είναι κατειλημμένο',
    vehicleIsDamaged: 'Το όχημα είναι χαλασμένο',
    sureAboutThisVehicle: 'Είστε σίγουροι ότι θέλετε να επιλέξετε αυτό το όχημα;',
    driverUnavaliable: 'Ο οδηγός είναι απασχολημένος',
    sureAboutThisDriver: 'Είστε σίγουροι ότι θέλετε να επιλέξετε αυτόν τον οδηγό;',
    checkVehicleSeatAvailability: 'Αυτό το όχημα δεν μπορεί να φιλοξενήσει τόσους επιβάτες',
    cancelDriving: 'Είστε σίγουροι ότι θέλετε να ακυρώσετε την παραγγελία διαδρομής;',
    finishDriving: 'Είστε σίγουροι ότι θέλετε να ολοκληρώσετε την παραγγελία διαδρομής;',
    noShowDriving: 'Είστε σίγουροι ότι θέλετε να σημειώσετε την παραγγελία ως μη εμφάνιση;',
    markDrivingAsStarted: 'Είστε σίγουροι ότι θέλετε να σημειώσετε την παραγγελία ως ξεκινήθηκε;',
    deleteDriving: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την παραγγελία διαδρομής;',
    newTransfer: 'Νέα μεταφορά',
    driving: 'Παραγγελία διαδρομής',
    comments: 'Σχόλια',
    id: 'Id',
    locationFrom: 'Αφετηρία',
    locationTo: 'Προορισμός',
    vehicleClass: 'Κατηγορία οχήματος',
    vehicle: 'Όχημα',
    driver: 'Οδηγός',
    pickupTime: 'Ώρα παραλαβής',
    clientConfirmed: 'Επιβεβαιώθηκε από τον πελάτη',
    description: 'Περιγραφή',
    extraInfo: 'Επιπρόσθετες πληροφορίες',
    drivingNotAccepted: 'Δεν αποδέχτηκαν την παραγγελία διαδρομής:',
    currency: 'Νόμισμα',
    paymentMethod: 'Τρόπος πληρωμής',
    price: 'Τιμή',
    paid: 'Πληρωμένο',
    distance: 'Απόσταση (χλμ)',
    distanceMi: 'Απόσταση (μίλια)',
    expectedTime: 'Αναμενόμενη διάρκεια',
    waitingTime: 'Χρόνος αναμονής (ώρες)',
    EnterTheNumberOfHoursWaiting: 'Εισαγάγετε τον αριθμό ωρών αναμονής',
    dropOffTime: 'Ώρα άφιξης',
    comebackTime: 'Ώρα επιστροφής',
    acceptUntil: 'Αποδοχή έως',
    drivingRequired: 'Η παραγγελία διαδρομής είναι υποχρεωτική',
    comment: 'Σχόλιο',
    attachments: 'Συνημμένα',
    addFiles: '+ Προσθήκη αρχείων',
    deleteFiles: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο;',
    passengers: 'Επιβάτες',
    addPassenger: '+ Προσθήκη επιβάτη',
    deletePassenger: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον επιβάτη;',
    chooseClient: 'Επιλέξτε πελάτη',
    costs: 'Κόστη',
    checkpoints: 'Στάσεις',
    addCheckpoint: 'Προσθέστε μια στάση',
    deleteCheckpoint: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη στάση;',
    waitingBoardText: 'Κείμενο πίνακα αναμονής',
    flightNumber: 'Αριθμός πτήσης',
    flightInfo: 'Πληροφορίες πτήσης',
    createdBy: 'Η κράτηση δημιουργήθηκε στις <b>{time}</b> από <b>{user}</b>',
    client: 'Πελάτης',
    gnet: 'GNET',
    note: 'Σημείωση',
    noteDriver: 'Σημείωση για τον οδηγό',
    noteDispatcher: 'Σημείωση για τον διαχειριστή',
    passenger: 'Επιβάτης',
    fullName: 'Πλήρες όνομα',
    phoneNumber: 'Αριθμός τηλεφώνου',
    email: 'Email',
    suitcasesNumber: 'Αριθμός βαλιτσών',
    passengersNumber: 'Αριθμός επιβατών',
    babySeatsNumber: 'Αριθμός παιδικών καθισμάτων',
    stopsNumber: 'Αριθμός στάσεων',
    steps: {
      mainInfo: 'Κύριες Πληροφορίες',
      additionalInfo: 'Επιπρόσθετες Πληροφορίες',
      review: 'Ανασκόπηση',
    },
    commissionAmount: 'Προμήθεια',
    sendSms: 'Αποστολή SMS',
    message: 'Μήνυμα',
    acceptDriving: 'Είστε σίγουροι ότι θέλετε να αποδεχθείτε την παραγγελία διαδρομής;',
    rejectDriving: 'Είστε σίγουροι ότι θέλετε να απορρίψετε την παραγγελία διαδρομής;',
    calculatePriceViaZonePricing: 'Υπολογισμός τιμής μέσω ζωνών',
    disableCalculatePriceWithoutHours: 'Δεν είναι δυνατός ο υπολογισμός τιμής χωρίς αριθμό ωρών αναμονής',
    disableCalculatePriceViaZonePricing:
      'Δεν είναι δυνατός ο υπολογισμός τιμής χωρίς τοποθεσία, νόμισμα και κατηγορία οχήματος',
    calculatePriceViaDistanceScales: 'Υπολογισμός τιμής μέσω αποστάσεων',
    disableCalculatePriceViaDistanceScales:
      'Δεν είναι δυνατός ο υπολογισμός τιμής χωρίς απόσταση, νόμισμα και κατηγορία οχήματος',
    numberOfPassengers: 'Αριθμός επιβατών',
    numberOfSuitcases: 'Αριθμός βαλιτσών',
    vat: 'ΦΠΑ',
    vatPercentage: 'Ποσοστό ΦΠΑ',
    priceType: 'Τύπος τιμής',
    accessibleForWheelchair: 'Προσβάσιμο για αναπηρικό αμαξίδιο',
    priceForWaiting: 'Τιμή αναμονής',
    numberOfHours: 'Αριθμός ωρών',
    getAQuote: 'Ζητήστε προσφορά',
    selectPassenger: 'Επιλέξτε επιβάτη',
  },

  drivingStatus: {
    pending: 'Σε εκκρεμότητα',
    accepted: 'Αποδεκτό',
    rejected: 'Απορρίφθηκε',
    expired: 'Έληξε',
    canceled: 'Ακυρώθηκε',
    draft: 'Προσχέδιο',
    done: 'Ολοκληρώθηκε',
    noShow: 'Δεν εμφανίστηκε',
    ongoing: 'Ξεκίνησε',
  },
  drivingOrderType: {
    dailyRent: 'Ημερήσια ενοικίαση',
    transfer: 'Μεταφορά',
    hourlyDaily: 'Ωριαία/Ημερήσια',
    oneWayTransfer: 'Μονής κατεύθυνσης μεταφορά',
    fromAirport: 'Από αεροδρόμιο',
    toAirport: 'Προς αεροδρόμιο',
  },
  flightInfo: {
    airlineName: 'Όνομα αεροπορικής εταιρείας',
    flightNumber: 'Αριθμός πτήσης',
    departureAirport: 'Αεροδρόμιο αναχώρησης',
    departureCountry: 'Χώρα αναχώρησης',
    departureTime: 'Ώρα αναχώρησης',
    arrivalAirport: 'Αεροδρόμιο άφιξης',
    arrivalCountry: 'Χώρα άφιξης',
    arrivalTime: 'Ώρα άφιξης',
    departureDelayed: 'Καθυστέρηση αναχώρησης',
    flightStatus: 'Κατάσταση πτήσης',
    percentageOfFlight: 'Ποσοστό πτήσης',
  },
  customValidations: {
    fileType: 'Ο τύπος αρχείου .{extension} δεν υποστηρίζεται',
    fileSize: 'Τα αρχεία δεν μπορούν να είναι μεγαλύτερα από {size}',
    fieldRequired: 'Το πεδίο {field} είναι απαραίτητο',
    fieldType: 'Το πεδίο {field} πρέπει να είναι τύπου {type}',
    fieldLengthMin: 'Το πεδίο {field} πρέπει να έχει μήκος τουλάχιστον {length}',
    fieldLengthMax: 'Το πεδίο {field} πρέπει να έχει μήκος το πολύ {length}',
    fieldUnique: 'Το πεδίο {field} πρέπει να είναι μοναδικό',
    fieldValid: 'Το πεδίο {field} πρέπει να είναι έγκυρο',
    noActiveSubscription: 'Δεν βρέθηκε ενεργή συνδρομή',
    usersLimitExceeded: 'Έχει φτάσει το όριο χρηστών',
    fieldBefore: 'Το {field1} πρέπει να είναι πριν από το {field2}',
    flightNotExist: 'Η πτήση {field} δεν υπάρχει',
    gnetKeyNotValid: 'Το παρεχόμενο κλειδί Gnet δεν είναι έγκυρο.',
    gnetPassengersRequired: 'Η εντολή οδήγησης δεν μπορεί να αποσταλεί στο GNET χωρίς επιβάτες.',
    priceAndCurrencyRequired: 'Ο σύνδεσμος πληρωμής δεν μπορεί να δημιουργηθεί χωρίς τιμή και νόμισμα',
    fieldValueNotSupported: 'Η τιμή του πεδίου {field} δεν υποστηρίζεται',
    noCountryCode: 'Δεν έχετε επιλέξει χώρα',
    noStripeForYourCountry: 'Το Stripe δεν είναι διαθέσιμο στη χώρα σας',
    stripeAlreadyEnabled: 'Το Stripe έχει ήδη ενεργοποιηθεί',
    fieldValueMin: 'Η τιμή του πεδίου {field} πρέπει να είναι τουλάχιστον {min}',
    fieldValueMax: 'Η τιμή του πεδίου {field} πρέπει να είναι το πολύ {max}',
    markAsDoneDate: 'Η ώρα παραλαβής δεν μπορεί να είναι στο μέλλον',
    phoneNumberNotValidForSms:
      'Ο αριθμός τηλεφώνου δεν είναι έγκυρος για αποστολή SMS. Ένας έγκυρος αριθμός πρέπει να περιλαμβάνει τον κωδικό χώρας.',
    affiliateIdDoesNotExist: 'Η οργάνωση με το συγκεκριμένο affiliate ID δεν υπάρχει.',
    numericDecimal: 'Το πεδίο {field} μπορεί να περιέχει μόνο αριθμητικούς χαρακτήρες (επιτρέπονται δεκαδικά)',
  },
  fieldTypes: {
    string: 'κείμενο',
    integer: 'αριθμός',
    date: 'ημερομηνία',
  },
  names: {
    username: 'όνομα χρήστη',
    password: 'κωδικός πρόσβασης',
    choose_client: 'επιλογή πελάτη',
    choose_month: 'επιλογή μήνα',
    currency: 'νόμισμα',
    payment_method: 'μέθοδος πληρωμής',
    vehicle_class: 'κατηγορία οχήματος',
    first_name: 'όνομα',
    last_name: 'επώνυμο',
    passport: 'διαβατήριο',
    nationality: 'εθνικότητα',
    plate_number: 'αριθμός πινακίδας',
    brand: 'μάρκα αυτοκινήτου',
    number_of_passengers: 'αριθμός επιβατών',
    fuel_consumption: 'κατανάλωση καυσίμου',
    year_of_manufacture: 'έτος κατασκευής',
    price_per_km: 'τιμή ανά χιλιόμετρο',
    price_per_mi: 'τιμή ανά μίλι',
    price_per_hour: 'τιμή ανά ώρα',
    price_per_waiting_hour: 'τιμή ανά ώρα αναμονής',
    vehicle: 'όχημα',
    description: 'περιγραφή',
    time_from: 'ώρα από',
    time_to: 'ώρα έως',
    name: 'όνομα',
    cost_type: 'τύπος κόστους',
    cost_time: 'χρόνος κόστους',
    price: 'τιμή',
    phone_number: 'αριθμός τηλεφώνου',
    organisation: 'οργάνωση',
    role: 'ρόλος',
    image: 'εικόνα',
    location_from: 'τοποθεσία από',
    location_to: 'τοποθεσία προς',
    pickup_time: 'ώρα παραλαβής',
    distance: 'απόσταση',
    waiting_time: 'χρόνος αναμονής',
    comment: 'σχόλιο',
    address: 'διεύθυνση',
    vat: 'ΦΠΑ',
    company_id: 'ΑΦΜ εταιρείας',
    company_personal_id: 'ΑΦΜ/προσωπική ταυτότητα',
    licence: 'άδεια',
    warrant_number: 'αριθμός απόφασης',
    seal: 'σφραγίδα',
    signature: 'υπογραφή',
    email: 'ηλεκτρονική διεύθυνση',
    percentage: 'ποσοστό',
    organizationName: 'όνομα οργανισμού',
    package: 'πακέτο',
    choose_currency: 'επιλογή νομίσματος',
    team_10_price: 'τιμή για ομάδα 10',
    team_10_paddle_price_id: 'paddle ID για ομάδα 10',
    team_25_price: 'τιμή για ομάδα 25',
    team_25_paddle_price_id: 'paddle ID για ομάδα 25',
    team_50_price: 'τιμή για ομάδα 50',
    team_50_paddle_price_id: 'paddle ID για ομάδα 50',
    team_10_price_monthly: 'τιμή για ομάδα 10 (μηνιαία)',
    team_10_paddle_price_id_monthly: 'paddle ID για ομάδα 10 (μηνιαία)',
    team_25_price_monthly: 'τιμή για ομάδα 25 (μηνιαία)',
    team_25_paddle_price_id_monthly: 'paddle ID για ομάδα 25 (μηνιαία)',
    team_50_price_monthly: 'τιμή για ομάδα 50 (μηνιαία)',
    team_50_paddle_price_id_monthly: 'paddle ID για ομάδα 50 (μηνιαία)',
    countries: 'χώρες',
    invoice_number: 'αριθμός τιμολογίου',
    status: 'κατάσταση',
    total: 'σύνολο',
    billed_at: 'χρεώθηκε στις',
    articles_of_law: 'άρθρα νόμου',
    country: 'χώρα',
    referent: 'υπεύθυνος',
    language: 'γλώσσα',
    location: 'τοποθεσία',
    arrival_time: 'ώρα άφιξης',
    waiting_board_text: 'κείμενο πινακίδας αναμονής',
    flight_number: 'αριθμός πτήσης',
    gnet_id: 'GNET ID',
    receiver_id: 'ID παραλήπτη',
    preferred_vehicle_type: 'προτιμώμενος τύπος οχήματος',
    reservation_type: 'τύπος κράτησης',
    run_type: 'τύπος διαδρομής',
    trip_duration_minutes: 'διάρκεια διαδρομής',
    client: 'πελάτης',
    client_type: 'τύπος πελάτη',
    card_holder_name: 'όνομα κατόχου κάρτας',
    card_number: 'αριθμός κάρτας',
    expiry_date: 'ημερομηνία λήξης',
    cvv: 'cvv',
    code: 'κωδικός',
    symbol: 'σύμβολο',
    quantity: 'ποσότητα',
    discount: 'έκπτωση',
    driving_type: 'τύπος διαδρομής',
    full_name: 'πλήρες όνομα',
    suitcases_number: 'αριθμός βαλιτσών',
    passengers_number: 'αριθμός επιβατών',
    baby_seats_number: 'αριθμός καθισμάτων για μωρά',
    stops_number: 'αριθμός στάσεων',
    commission_amount: 'ποσό προμήθειας',
    message: 'μήνυμα',
    affiliate_id: 'affiliate id',
    coordinates: 'συντεταγμένες',
    zone_one: 'ζώνη ένα',
    zone_two: 'ζώνη δύο',
    price_type: 'τύπος τιμής',
    price_for_waiting: 'τιμή για αναμονή',
    number_of_hours: 'αριθμός ωρών',
    zones: 'ζώνες',
  },
  weekDays: {
    monday: 'Δευτέρα',
    tuesday: 'Τρίτη',
    wednesday: 'Τετάρτη',
    thursday: 'Πέμπτη',
    friday: 'Παρασκευή',
    saturday: 'Σάββατο',
    sunday: 'Κυριακή',
  },
  select: {
    noDataAvailable: 'Δεν υπάρχουν διαθέσιμα δεδομένα',
    selected: 'επιλεγμένο',
  },
  excelExport: {
    to_date_range:
      'Η αναφορά δεν μπορεί να δημιουργηθεί για μελλοντικές ημερομηνίες. Παρακαλώ εισάγετε έγκυρες ημερομηνίες',
    overlap_range: 'Η ώρα λήξης δεν μπορεί να είναι μεγαλύτερη από την ώρα έναρξης.',
  },
  setPassword: {
    success: 'Το email στάλθηκε',
    checkEmail: 'Ελέγξτε το email σας για περαιτέρω οδηγίες.',
    already_changed: 'Ήδη άλλαξε',
    send_new_reset_request:
      'Έχετε ήδη αλλάξει τον κωδικό πρόσβασης με αυτόν τον σύνδεσμο. Στείλτε νέο αίτημα επαναφοράς κωδικού.',
  },
  statisticsPage: {
    reportType: 'Τύπος αναφοράς',
    reportTime: 'Χρόνος αναφοράς',
    vehicleUtilization: 'Χρήση οχήματος',
    costEffectiveness: 'Αποτελεσματικότητα κόστους',
    generalReport: 'Γενική αναφορά',
    clientsReport: 'Αναφορά ανά πελάτες',
  },
  profile: {
    yourProfile: 'Το Προφίλ σας',
    name: 'Όνομα',
    role: 'Ρόλος',
    username: 'Όνομα χρήστη',
    password: 'Κωδικός πρόσβασης',
    phoneNumber: 'Αριθμός τηλεφώνου',
    email: 'Email',
    percentage: 'Ποσοστό',
  },
  gpsMaps: {
    noTransfers: 'Δεν υπάρχουν τρέχουσες ή επερχόμενες μεταφορές.',
    map: 'Χάρτης',
    transfers: 'Μεταφορές',
    drivers: 'Οδηγοί',
    ongoing: 'Σε εξέλιξη',
    upcoming: 'Επερχόμενες',
    driving: 'Οδήγηση',
    driver: 'Οδηγός',
    vehicle: 'Όχημα',
    requestDriverLocation: 'Αίτημα για την τοποθεσία του οδηγού',
    lastRecordedLocation: 'Τελευταία καταγεγραμμένη τοποθεσία',
  },
  driverStatus: {
    available: 'Διαθέσιμος',
    offline: 'Εκτός σύνδεσης',
    inTransfer: 'Σε μεταφορά',
  },
  onboardingTutorialMain: {
    languageStep: 'Εδώ μπορείτε να ορίσετε την προτιμώμενη γλώσσα σας για την πλατφόρμα.',
    notificationsStep: 'Εδώ μπορείτε να βρείτε τις ειδοποιήσεις σας.',
    addTransferStep:
      'Κάντε κλικ σε αυτό το κουμπί για να δημιουργήσετε μια νέα κράτηση, μεταφορές μίας διαδρομής και ημερήσιες ενοικιάσεις.',
    sideMenuButtonStep: 'Κάντε κλικ σε αυτό το εικονίδιο για να εμφανίσετε ή να αποκρύψετε τη γραμμή μενού.',
    sideMenuExpStep:
      'Από εδώ μπορείτε να πλοηγηθείτε στις κύριες ενότητες της πλατφόρμας: Κρατήσεις, Στόλος, Κόστη, Διαχείριση, Τιμολόγια, Στατιστικά κ.λπ.',
    sideMenuButtonCloseStep: 'Κάντε κλικ στο ίδιο εικονίδιο για να αποκρύψετε τη γραμμή μενού.',
    supportStep:
      'Εάν αντιμετωπίζετε οποιοδήποτε πρόβλημα ή έχετε απορίες, μη διστάσετε να επικοινωνήσετε με την Εξυπηρέτηση Πελατών κάνοντας κλικ εδώ.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Τα οχήματα θα εμφανιστούν σε αυτόν τον πίνακα μετά την προσθήκη τους.',
    searchStep: 'Μπορείτε εύκολα να βρείτε οχήματα αναζητώντας απευθείας βάσει μάρκας, πινακίδας, έτους κ.λπ.',
    addStep:
      "Προσθέστε νέα οχήματα κάνοντας κλικ στο κουμπί 'Προσθήκη'. Σημειώστε ότι όλες οι 'Κατηγορίες Οχημάτων' πρέπει να προστεθούν πριν την εισαγωγή οχημάτων.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'Σε αυτήν την ενότητα μπορείτε να βρείτε όλα τα στατιστικά σας δεδομένα σε γραφήματα. Επιλέξτε τον τύπο των στατιστικών εδώ.',
    intervalStep: 'Επιλέξτε ένα διάστημα για να δείτε τα στατιστικά.',
    downloadStep: 'Μπορείτε επίσης να κατεβάσετε και να εκτυπώσετε αυτούς τους πίνακες.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'Στην ενότητα GPS μπορείτε να παρακολουθείτε τους οδηγούς και τις μεταφορές σας σε ένα μέρος. Επιλέξτε αυτή την καρτέλα για να δείτε τις τρέχουσες και τις επερχόμενες (εντός 24 ωρών) μεταφορές.',
    tabTwoStep:
      'Επιλέξτε οχήματα για να τα δείτε στον χάρτη. Μπορείτε να αναθέσετε διαθέσιμα οχήματα απευθείας σε μεταφορές από εδώ.',
    mapStep: 'Επιλέξτε ή αποεπιλέξτε οχήματα κάνοντας κλικ στο εικονίδιό τους στον χάρτη.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Όλα τα τιμολόγια θα εμφανίζονται σε αυτόν τον πίνακα από το νεότερο προς το παλαιότερο.',
    searchStep:
      'Βρείτε εύκολα τιμολόγια αναζητώντας βάσει αριθμού, ποσού, ονόματος πελάτη, ημερομηνίας δημιουργίας κ.λπ.',
    addStep: "Δημιουργήστε νέα τιμολόγια κάνοντας κλικ στο κουμπί 'Προσθήκη'.",
  },
  onboardingTutorialOffers: {
    tableStep: 'Οι προσφορές θα εμφανίζονται σε αυτόν τον πίνακα από το νεότερο προς το παλαιότερο.',
    searchStep: 'Βρείτε εύκολα προσφορές αναζητώντας βάσει αριθμού, ποσού ή περιγραφής.',
    addStep: "Δημιουργήστε νέες προσφορές κάνοντας κλικ στο κουμπί 'Προσθήκη'.",
  },
  onboardingTutorialResources: {
    infoStep: 'Δείτε τα υπόλοιπα των πόρων και αγοράστε περισσότερα μηνύματα SMS κατά παραγγελία.',
    tableStep:
      'Σε αυτόν τον πίνακα μπορείτε να βρείτε το ιστορικό όλων των χρησιμοποιημένων πόρων (απεσταλμένα SMS και ελέγχους πτήσεων).',
    searchStep: 'Βρείτε εύκολα πόρους αναζητώντας βάσει περιεχομένου, χρήστη ή ημερομηνίας δημιουργίας.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Οι κριτικές επιβατών θα εμφανίζονται σε αυτόν τον πίνακα από το νεότερο προς το παλαιότερο.',
    searchStep: 'Βρείτε εύκολα κριτικές επιβατών αναζητώντας βάσει αριθμού ή σχολίου επιβάτη.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Οι ζώνες τιμολόγησης θα εμφανίζονται σε αυτόν τον πίνακα μετά την προσθήκη τους.',
    searchStep: 'Βρείτε εύκολα ζώνες τιμολόγησης αναζητώντας απευθείας.',
    addStep: "Δημιουργήστε νέες ζώνες τιμολόγησης κάνοντας κλικ στο κουμπί 'Προσθήκη'.",
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Οι σχέσεις ζωνών τιμολόγησης (τιμές) θα εμφανίζονται σε αυτόν τον πίνακα μετά την προσθήκη τους.',
    searchStep: 'Βρείτε εύκολα σχέσεις ζωνών τιμολόγησης αναζητώντας απευθείας.',
    addStep:
      "Δημιουργήστε νέες σχέσεις ζωνών τιμολόγησης (τιμές) κάνοντας κλικ στο κουμπί 'Προσθήκη'. Σημειώστε ότι οι 'Ζώνες Τιμολόγησης' πρέπει να έχουν προστεθεί πρώτα.",
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Όλες οι ζώνες τιμολόγησης εμφανίζονται σε αυτόν τον πίνακα. Ανοίγοντας μια συγκεκριμένη ζώνη, μπορείτε να ορίσετε τιμές για αυτή τη ζώνη.',
    searchStep: 'Βρείτε εύκολα ζώνες τιμολόγησης αναζητώντας απευθείας.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Δείτε μια σύνοψη των στατιστικών του οργανισμού σας για τις τελευταίες 30 ημέρες.',
    next3DaysStep: 'Οι κρατήσεις για τις επόμενες 3 ημέρες θα εμφανίζονται σε αυτόν τον πίνακα.',
    notPaidOrConfirmedStep:
      'Οι κρατήσεις για τις επόμενες 7 ημέρες που δεν έχουν επιβεβαιωθεί ή πληρωθεί θα εμφανίζονται σε αυτόν τον πίνακα.',
    next5DaysStep: 'Οι απασχολημένοι οδηγοί για τις επόμενες 5 ημέρες θα εμφανίζονται σε αυτόν τον πίνακα.',
    latestReviewsStep: 'Οι τελευταίες κριτικές διαδρομών σας θα εμφανίζονται σε αυτόν τον πίνακα.',
  },
  onboardingTutorialAffiliate: {
    tabStep:
      'Εναλλάξτε μεταξύ καρτελών για να δείτε τους συνεργάτες σας, τα απεσταλμένα αιτήματα ή τα ληφθέντα αιτήματα.',
    idStep: 'Βρείτε το ID συνεργάτη σας εδώ, το οποίο μπορείτε να μοιραστείτε με συνεργάτες.',
    addStep: "Δημιουργήστε ένα νέο αίτημα συνεργάτη κάνοντας κλικ στο κουμπί 'Προσθήκη'.",
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Κάντε κλικ εδώ για να αποκτήσετε πρόσβαση στις Προχωρημένες Ρυθμίσεις του οργανισμού σας, όπου μπορείτε να διαμορφώσετε όλες τις ιδιότητες και τις ενσωματώσεις.',
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Εδώ μπορείτε να ορίσετε και να διαχειριστείτε τις κλίμακες τιμολόγησης αποστάσεων για κάθε κατηγορία οχημάτων και νόμισμα. Ορίστε εύκολα τιμές για συγκεκριμένα εύρη αποστάσεων (π.χ. 0-5 χλμ., 5-15 χλμ.) για να προσαρμόσετε τους υπολογισμούς των ναύλων σας.',
  },
  advancedSettings: {
    advancedSettings: 'Ρυθμίσεις για προχωρημένους',
    distanceUnit: 'Μονάδα μέτρησης απόστασης',
    distanceUnitDescription: 'Επιλέξτε τη μονάδα μέτρησης για αποστάσεις.',
    kilometers: 'Χιλιόμετρα',
    miles: 'Μίλια',
    dateFormat: 'Μορφή ημερομηνίας',
    dateFormatDescription: 'Επιλέξτε τη μορφή ημερομηνίας που προτιμάτε να χρησιμοποιείτε.',
    ddmmyyyy: 'ΗΗ-ΜΜ-ΕΕΕΕ',
    mmddyyyy: 'ΜΜ-ΗΗ-ΕΕΕΕ',
    mandatoryDrivings: 'Υποχρεωτικές παραγγελίες διαδρομών',
    mandatoryDrivingsDescription:
      'Ελέγξτε για να καταστήσετε όλες τις παραγγελίες διαδρομών υποχρεωτικές από προεπιλογή.',
    mandatoryDrivingsDefault: 'Υποχρεωτικές Παραγγελίες Διαδρομών (Προεπιλογή)',
    paidDrivings: 'Πληρωμένες παραγγελίες διαδρομών',
    paidDrivingsDescription: 'Ελέγξτε για να καταστήσετε όλες τις παραγγελίες διαδρομών πληρωμένες από προεπιλογή.',
    paidDrivingsDefault: 'Πληρωμένες Παραγγελίες Διαδρομών (Προεπιλογή)',
    notificationsEmail: 'Email για ειδοποιήσεις',
    notificationsEmailDescription:
      'Αλλάξτε το προεπιλεγμένο email για λήψη ειδοποιήσεων (αν δεν θέλετε να χρησιμοποιήσετε το email του οργανισμού σας).',
    email: 'Email',
    articlesOfLaw: 'Άρθρα νόμου',
    integrations: 'Ενσωματώσεις',
    gnetId: 'GNET ID',
    gnetDescription:
      'Το GNET είναι μια ολοκληρωμένη πλατφόρμα σχεδιασμένη για τη βελτιστοποίηση σε πραγματικό χρόνο της παρακολούθησης, της διαχείρισης στόλου και των λειτουργιών dispatch για τη βιομηχανία μεταφορών.',
    makeRoutesAvailable: 'Διαθέστε διαδρομές ενσωμάτωσης',
    makeRoutesAvailableDescription:
      'Ενεργοποιήστε το API key σας για να ενσωματώσετε το LimoExpress με την ιστοσελίδα σας, το ERP σύστημα κ.λπ.',
    makePublicFormAvailable: 'Διαθέστε δημόσια φόρμα',
    makePublicFormAvailableDescription:
      'Ενεργοποιήστε αυτήν την επιλογή για πρόσβαση στη δημόσια σελίδα κρατήσεων ώστε οι πελάτες σας να μπορούν να χρησιμοποιούν τη φόρμα κρατήσεων και να δημιουργούν λογαριασμούς εύκολα. Μπορείτε να χρησιμοποιήσετε τον σύνδεσμο σε newsletters, στην ιστοσελίδα, φυλλάδια· είναι ένας μοναδικός σύνδεσμος μόνο για την εταιρεία σας. Εναλλακτικά, μπορείτε επίσης να λάβετε ένα κομμάτι κώδικα για να ενσωματώσετε τη φόρμα κρατήσεων απευθείας στον ιστότοπό σας.',
    emailsEnabled: 'Ενεργοποίηση emails',
    emailsEnabledDefault: 'Ενεργοποιημένα emails',
    emailsEnabledDescription: 'Ελέγξτε για να λαμβάνετε ειδοποιήσεις μέσω email.',
    passengerReviewsEnabled: 'Ενεργοποίηση κριτικών επιβατών',
    passengerReviewsEnabledDefault: 'Ενεργοποιημένες κριτικές επιβατών',
    passengerReviewsEnabledDescription:
      'Με την ενεργοποίηση αυτής της δυνατότητας, οι επιβάτες σας (αν έχει καταχωρηθεί email) θα λαμβάνουν email επιβεβαίωσης μετά την ολοκλήρωση της διαδρομής (σημειώνεται ως Ολοκληρωμένη) και θα έχουν τη δυνατότητα να δώσουν αξιολογήσεις (σχόλια) για τις υπηρεσίες σας.',
    vat: 'ΦΠΑ',
    vatPercentage: 'Ποσοστό ΦΠΑ',
    vatPercentageDescription: 'Εισάγετε το ποσοστό ΦΠΑ.',
    invoicePaymentInstructions: 'Οδηγίες πληρωμής τιμολογίου',
    stripe: 'Stripe',
    stripeDescription:
      'Ενεργοποιήστε το Stripe για να μπορείτε να χρεώνετε τους πελάτες σας για τις υπηρεσίες σας. Το LimoExpress δεν λαμβάνει καμία προμήθεια.',
    dataExport: 'Εξαγωγή δεδομένων',
    dataExportDescription: 'Μπορείτε να εξάγετε όλα τα δεδομένα σας, και θα σταλούν στο email σας.',
    sureAboutDataExport:
      'Είστε βέβαιοι ότι θέλετε να εξάγετε όλα τα δεδομένα σας; Αν ναι, όλα τα δεδομένα θα σταλούν στο email σας μέσα στις επόμενες 2 ώρες.',
    defaultPriceType: 'Προεπιλεγμένος τύπος τιμής',
    defaultPriceTypeDescription: 'Ορίστε τον προεπιλεγμένο τύπο τιμής για να καθορίσετε πώς θα εμφανίζονται οι τιμές.',
    NET: 'ΚΑΘΑΡΟ',
    GROSS: 'ΜΙΚΤΟ',
    link: 'Σύνδεσμος',
    code: 'Κώδικας',
    language: 'Γλώσσα',
    chooseSize: 'Επιλέξτε μέγεθος',
    small: 'Μικρό',
    medium: 'Μεσαίο',
    large: 'Μεγάλο',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Αριθμός παραγγελίας διαδρομής',
    drivingType: 'Τύπος κράτησης',
    drivingStatus: 'Κατάσταση',
    locationFrom: 'Σημείο εκκίνησης',
    locationTo: 'Προορισμός',
    driver: 'Οδηγός',
    client: 'Πελάτης',
    plateNumber: 'Αριθμός κυκλοφορίας',
    note: 'Περιγραφή',
    fuelConsumption: 'Κατανάλωση καυσίμου',
    pickupTime: 'Ώρα παραλαβής',
    numberOfPassengers: 'Αριθμός επιβατών',
    passengers: 'Επιβάτες',
    price: 'Τιμή',
    paid: 'Πληρωμένο',
    paymentMethod: 'Τρόπος πληρωμής',
    waitingTime: 'Χρόνος αναμονής (h)',
    distance: 'Απόσταση (km)',
    distanceMi: 'Απόσταση (miles)',
    selectAll: 'Επιλογή όλων',
  },
  gnet: {
    gnetInfo: 'Πληροφορίες GNET',
    requesterId: 'ID αιτούντος',
    receiverId: 'ID παραλήπτη',
    preferredVehicleType: 'Προτιμώμενος τύπος οχήματος',
    reservationType: 'Τύπος κράτησης',
    runType: 'Τύπος δρομολογίου',
    price: 'Τιμή',
    currency: 'Νόμισμα',
    noteDriver: 'Σημείωση για τον οδηγό',
    noteDispatcher: 'Σημείωση για τον διαχειριστή',
    tripDurationMinutes: 'Διάρκεια ταξιδιού (λεπτά)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Αριθμός τιμολογίου',
    client: 'Πελάτης',
    totalPrice: 'Συνολική τιμή',
    drivings: 'Διαδρομές',
    paid: 'Πληρωμένο',
    currency: 'Νόμισμα',
    createdAt: 'Ημερομηνία δημιουργίας',
    actions: 'Ενέργειες',
  },
  invoicesPage: {
    newItem: 'Νέα καταχώρηση τιμολογίου',
    chooseClient: 'Επιλέξτε πελάτη',
    chooseDrivings: 'Επιλέξτε διαδρομές',
    allDrivings: 'Όλες οι διαδρομές',
    deleteMessage: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το τιμολόγιο;',
    markInvoiceAsPaid: 'Είστε βέβαιοι ότι θέλετε να σημειώσετε αυτό το τιμολόγιο ως πληρωμένο;',
  },
  drivingOffersHeaders: {
    number: 'Αριθμός προσφοράς διαδρομής',
    totalPrice: 'Συνολική τιμή',
    description: 'Περιγραφή',
    paid: 'Πληρωμένο',
    currency: 'Νόμισμα',
    createdAt: 'Ημερομηνία δημιουργίας',
    actions: 'Ενέργειες',
  },
  drivingOffersPage: {
    newItem: 'Νέα καταχώρηση προσφοράς διαδρομής',
    item: 'Αντικείμενο',
    description: 'Περιγραφή',
    title: 'Τίτλος',
    vehicleClass: 'Κατηγορία οχήματος',
    quantity: 'Ποσότητα',
    price: 'Τιμή',
    discount: 'Έκπτωση',
    vat: 'ΦΠΑ',
    deleteMessage: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν την προσφορά διαδρομής;',
    markDrivingOfferAsPaid: 'Είστε βέβαιοι ότι θέλετε να σημειώσετε αυτή την προσφορά διαδρομής ως πληρωμένη;',
  },
  modal: {
    reservationSuccessTitle: 'Επιτυχία',
    reservationSuccessMessage: 'Το αίτημα κράτησης στάλθηκε επιτυχώς. Κάποιος θα επικοινωνήσει μαζί σας σύντομα.',
    reservationErrorTitle: 'Η φόρμα είναι απενεργοποιημένη',
    reservationErrorMessage:
      'Η φόρμα είναι προσωρινά απενεργοποιημένη από τον οργανισμό. Παρακαλώ επικοινωνήστε με την υποστήριξη ή δοκιμάστε αργότερα.',
  },
  dashboardPage: {
    drivingCountToday: 'Αριθμός διαδρομών (σήμερα)',
    drivingCountThisMonth: 'Αριθμός διαδρομών (τελευταίες 30 ημέρες)',
    dailyRentsAndTransfers: 'Αριθμός διαδρομών (ημερήσιες ενοικιάσεις/μεταφορές)',
    drivingTotalAmount: 'Συνολικό ποσό',
    driversCount: 'Ενεργοί οδηγοί (±7 ημέρες)',
    passengerCount: 'Αριθμός επιβατών (τελευταίες 30 ημέρες)',
  },
  reviewsHeaders: {
    rating: 'Βαθμολογία',
    comment: 'Σχόλιο',
    createdAt: 'Ημερομηνία δημιουργίας',
  },
  resourceOverviewHeaders: {
    actionType: 'Τύπος ενέργειας',
    user: 'Χρήστης',
    action: 'Ενέργεια',
    createdAt: 'Ημερομηνία δημιουργίας',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Αριθμός υπολοίπων SMS',
    flightsCheckLeft: 'Αριθμός υπολοίπων ελέγχων πτήσεων',
    sms: 'SMS',
    flightCheck: 'Έλεγχος πτήσης',
  },
  affiliateHeaders: {
    organisation: 'Οργανισμός',
    createdAt: 'Ημερομηνία δημιουργίας',
    actions: 'Ενέργειες',
  },
  affiliatePage: {
    partners: 'Συνεργάτες',
    sentRequests: 'Αιτήματα που έχουν αποσταλεί',
    receivedRequests: 'Αιτήματα που έχουν ληφθεί',
    acceptRequest: 'Είστε βέβαιοι ότι θέλετε να αποδεχτείτε αυτό το αίτημα;',
    rejectRequest: 'Είστε βέβαιοι ότι θέλετε να απορρίψετε αυτό το αίτημα;',
    deletePartner: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον συνεργάτη;',
    deleteRequest: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το αίτημα;',
    newItem: 'Νέο αίτημα',
    affiliateId: 'ID Συνεργάτη',
    registerNewPartner: 'Εγγραφή νέου συνεργάτη',
    registrationSuccessfull: 'Η εγγραφή ολοκληρώθηκε με επιτυχία',
    registrationSuccessfullDescription:
      'Ο συνεργάτης εγγράφηκε επιτυχώς! Παρακαλώ ενημερώστε τον συνεργάτη ότι θα λάβει email για επιβεβαίωση του λογαριασμού και για τη δημιουργία κωδικού πρόσβασης. Μόλις ολοκληρωθεί, μπορεί να αρχίσει να χρησιμοποιεί την πλατφόρμα.',
  },
  affiliate: {
    affiliateInfo: 'Πληροφορίες συνεργάτη',
    affiliate: 'Συνεργάτης',
    partner: 'Συνεργάτης',
    preferredVehicleType: 'Προτιμώμενος τύπος οχήματος',
    driverInfo: 'Πληροφορίες οδηγού',
    vehicleInfo: 'Πληροφορίες οχήματος',
    price: 'Τιμή',
    currency: 'Νόμισμα',
  },
  pricingZonesHeaders: {
    name: 'Όνομα ζώνης',
    code: 'Κωδικός',
    postalCode: 'Ταχυδρομικός κώδικας',
    actions: 'Ενέργειες',
  },
  pricingZonesPage: {
    newItem: 'Νέα ζώνη τιμολόγησης',
    editItem: 'Αλλαγή ζώνης τιμολόγησης',
    id: 'Id',
    name: 'Όνομα',
    code: 'Κωδικός',
    deleteMessage: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τη ζώνη τιμολόγησης;',
    postalCode: 'Ταχυδρομικός κώδικας',
    postalCodeDescription: 'Ορίστε μια ζώνη χάρτη χρησιμοποιώντας έναν ταχυδρομικό κώδικα',
    postalCodeDisabled: 'Παρακαλώ εισάγετε πρώτα έναν ταχυδρομικό κώδικα',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Ζώνη 1',
    zoneTwo: 'Ζώνη 2',
    client: 'Πελάτης',
    actions: 'Ενέργειες',
  },
  pricingZoneRelationsPage: {
    newItem: 'Νέα σχέση ζώνης τιμολόγησης',
    editItem: 'Αλλαγή σχέσης ζώνης τιμολόγησης',
    zone: 'Ζώνη',
    zoneOne: 'Ζώνη 1',
    zoneTwo: 'Ζώνη 2',
    chooseClient: 'Επιλέξτε πελάτη',
    chooseClientFrom: 'Αντιγραφή από πελάτη',
    chooseClientTo: 'Αντιγραφή σε πελάτη',
    cloneItem: 'Αντιγραφή σχέσης ζώνης τιμολόγησης',
    zoneRelations: 'Σχέσεις ζωνών',
    cloneItemHourly: 'Αντιγραφή ωριαίας τιμολόγησης',
    zones: 'Ζώνες',
    fromTo: 'Από - Προς',
    discountPercentage: 'Ποσοστό έκπτωσης (%)',
    deleteMessage: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τη σχέση ζώνης τιμολόγησης;',
  },
  referralPrize: {
    referToAFriend: 'Προτείνετε σε φίλο',
    referToAFriendDescription:
      'Προσκαλέστε φίλους να συμμετάσχουν στην πλατφόρμα εισάγοντας τη διεύθυνση email τους παρακάτω. Θα λάβουν μια εξατομικευμένη πρόσκληση για εγγραφή και χρήση της πλατφόρμας. Εναλλακτικά, μπορείτε να μοιραστείτε τον μοναδικό σύνδεσμο εγγραφής σας απευθείας με άλλους! Όταν κάποιος εγγραφεί χρησιμοποιώντας τον σύνδεσμό σας, θα κερδίσετε ένα δώρο σύστασης ως ευχαριστήριο από εμάς. Ξεκινήστε τις προσκλήσεις και μοιραστείτε σήμερα!',
  },
  distanceScalesPage: {
    distanceScales: 'Κλίμακες απόστασης',
  },
  passengersPage: {
    newItem: 'Νέα καταχώρηση επιβάτη',
    deletePassenger: 'Διαγραφή επιβάτη',
    deleteMessage: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τον επιβάτη;',
  },
  formsAndTables: {
    actions: 'Ενέργειες',
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    email: 'Email',
    phoneNumber: 'Τηλέφωνο',
    passport: 'Διαβατήριο',
    country: 'Χώρα',
    nationality: 'Εθνικότητα',
  },
};
