import Vue from 'vue';
import store from '@/store';
import VueI18n from 'vue-i18n';
import meLocale from '@/locales/me.js';
import enLocale from '@/locales/en.js';
import deLocale from '@/locales/de.js';
import esLocale from '@/locales/es.js';
import frLocale from '@/locales/fr.js';
import hrLocale from '@/locales/hr.js';
import itLocale from '@/locales/it.js';
import nlLocale from '@/locales/nl.js';
import ptLocale from '@/locales/pt.js';
import ruLocale from '@/locales/ru.js';
import slLocale from '@/locales/sl.js';
import srLocale from '@/locales/sr.js';
import trLocale from '@/locales/tr.js';
import elLocale from '@/locales/el.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.language.currentLanguage,
  messages: {
    me: meLocale,
    en: enLocale,
    de: deLocale,
    es: esLocale,
    fr: frLocale,
    hr: hrLocale,
    it: itLocale,
    nl: nlLocale,
    pt: ptLocale,
    ru: ruLocale,
    sl: slLocale,
    sr: srLocale,
    tr: trLocale,
    el: elLocale,
  },
});

// Watch for changes in the Vuex store's currentLanguage and update the VueI18n locale
store.watch(
  (state) => state.language.currentLanguage,
  (newLanguage) => {
    i18n.locale = newLanguage;
  }
);

export default i18n;
