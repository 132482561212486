<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.passengersByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="passengersPageHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="passengers"
            :search="searchString"
            class="elevation-1 cursor-pointer"
            @click:row="editItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t('tables.passengers') }} </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>

                    <passenger-form
                      ref="passengerForm"
                      :editedItem="editedItem"
                      :countries="countries"
                      @loadAllPassengers="loadAllPassengers"
                      @closePassengerEdit="close" />
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs" v-on="on"> mdi-pencil </v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showDeletePassengerModal(item)">
                        <v-icon class="mx-1" color="red darken-3" small v-bind="attrs" v-on="on">
                          mdi-trash-can-outline
                        </v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog v-model="deletePassengerModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('passengersPage.deletePassenger') }}
            <v-spacer />
            <v-icon class="mdi mdi-close" style="color: white" @click="closeDeletePassengerModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('passengersPage.deleteMessage') }} <b>{{ editedItemDeleted?.full_name }}</b
                >?
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!enableDelete" class="primary" text @click="deletePassenger">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { passengersPageHeaders } from '@/mixins/data-table-headers';
import { defaultPassenger } from '@/mixins/default-items';
import PassengerForm from '@/components/passengers/PassengerForm.vue';

export default {
  name: 'Passengers',
  components: { PassengerForm },
  data: () => ({
    searchString: '',
    dialog: false,
    passengers: [],
    formTitle: 'passengersPage.newItem',
    editedItem: {},
    editedItemDeleted: {},
    enableDelete: true,
    deletePassengerModal: false,
    passenger: {},
    countries: [],
  }),
  created() {
    this.editedItem = Object.assign({}, defaultPassenger);
    this.loadAllCountries();
    this.loadAllPassengers();
  },
  computed: {
    passengersPageHeaders() {
      return passengersPageHeaders(i18n);
    },
  },
  methods: {
    async loadAllCountries() {
      await this.$store.dispatch('pricingRegions/getAllCountries').then((res) => {
        this.countries = res.data;
      });
    },
    async loadAllPassengers() {
      await this.$store.dispatch('passengers/getAllPassengers').then((res) => {
        this.passengers = res.data.map((e) => {
          return e;
        });
      });
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.country_id = item?.country?.id;
      this.formTitle = `${this.editedItem.full_name}`;
      this.dialog = true;
    },

    async deletePassenger() {
      this.enableDelete = false;

      await this.$store
        .dispatch('passengers/deletePassenger', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllPassengers();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeletePassengerModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.enableDelete = true;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultPassenger);
      });
      this.$refs.passengerForm.resetForm();
      this.formTitle = 'passengersPage.newItem';
    },

    showDeletePassengerModal(item) {
      this.editedItemDeleted = item;
      this.deletePassengerModal = true;
    },

    closeDeletePassengerModal() {
      this.deletePassengerModal = false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
