<template>
  <v-col cols="4" lg="4" md="4" sm="4">
    <validation-provider
      rules="required|numeric_decimal|max:10"
      v-slot="{ errors }"
      :name="currency.id + label"
      :custom-messages="{
        required: $t('customValidations.fieldRequired', {
          field: $t('names.price'),
        }),
        max: $t('customValidations.fieldLengthMax', {
          field: $t('names.price'),
          length: 10,
        }),
        numeric_decimal: $t('customValidations.numericDecimal', {
          field: $t('names.price'),
        }),
      }">
      <v-text-field
        v-model="localValue"
        clearable
        counter="10"
        name="price_per_km"
        :label="label"
        @input="updateValue"
        :error-messages="errors"></v-text-field>
    </validation-provider>
  </v-col>
</template>

<script>
export default {
  name: 'DistanceScalePrice',
  components: {},
  props: ['value', 'currency', 'label'],
  data() {
    return {
      localValue: this.value,
    };
  },
  async created() {},
  computed: {},
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue); // Emit updated value
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue; // Sync prop changes to local state
    },
  },
};
</script>

<style></style>
