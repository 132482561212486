<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.notificationsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="notificationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="notifications"
            :search="searchNotificationString"
            class="elevation-1 cursor-pointer"
            @click:row="handleNotificationClick"
            :item-class="addCustomClass"
            multi-sort>
            <template v-slot:top>
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-toolbar flat>
                    <v-toolbar-title>
                      {{ $t('tables.notifications') }}
                    </v-toolbar-title>

                    <v-divider class="mx-4" inset vertical></v-divider>

                    <v-text-field
                      v-model="searchNotificationString"
                      append-icon="mdi-magnify"
                      hide-details
                      :label="$t('tables.search')"
                      single-line></v-text-field>
                  </v-toolbar>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12" class="d-sm-flex align-center justify-end px-5">
                  <v-checkbox
                    v-model="unseenOnly"
                    :label="$t('notificationsPage.showUnseenOnly')"
                    @change="filterNotifications"></v-checkbox>

                  <v-btn class="primary ml-sm-5 mb-5 mb-sm-0" @click="markAllAsRead">
                    {{ $t('notificationsPage.markAllAsRead') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { notificationsHeaders } from '@/mixins/data-table-headers';
import router from '@/router';
import { mapState } from 'vuex';

export default {
  name: 'Notifications',
  data: () => ({
    user: {},
    searchNotificationString: '',
    notificationDialog: false,
    unseenOnly: false,
  }),
  created() {
    this.loadUser();
    this.loadAllNotifications();
  },
  computed: {
    ...mapState('notifications', ['notifications']),
    notificationsHeaders() {
      return notificationsHeaders(i18n);
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },

    loadAllNotifications() {
      const params = this.unseenOnly ? { unseen_only: true } : {};
      this.$store.dispatch('notifications/getAllNotifications', params);
    },

    handleNotificationClick(notification) {
      if (!notification.read_at) {
        this.$store
          .dispatch('notifications/setNotificationAsSeen', notification)
          .then(() => {
            this.loadAllNotifications();
          })
          .catch(() => {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          });
      }
      if (notification.data.type !== 'vehicle-out-of-order') {
        router.push({ name: 'CalendarView', query: { driving_id: notification.data.driving_id } });
      }
    },

    markAllAsRead() {
      this.$store
        .dispatch('notifications/setAllAsSeen')
        .then(() => {
          this.loadAllNotifications();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    filterNotifications() {
      this.loadAllNotifications();
    },

    addCustomClass(item) {
      return item.read_at ? 'notification-read' : 'notification-unread';
    },
  },

  watch: {},
};
</script>

<style>
tbody tr.notification-read {
  background-color: #ffffff;
}

tbody tr.notification-unread {
  background-color: #ffffff;
  font-weight: bold;
}
</style>
