export default {
  namespaced: true,
  state: { loading: false, permanentLoading: false },
  mutations: {
    SET_LOADER(state, value) {
      state.loading = value;
    },
    SET_PERMANENT_LOADER(state, value) {
      state.permanentLoading = value;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SET_LOADER', true);
    },
    hideLoader({ commit }) {
      commit('SET_LOADER', false);
    },
    showPermanentLoader({ commit }) {
      commit('SET_PERMANENT_LOADER', true);
    },
    hidePermanentLoader({ commit }) {
      commit('SET_PERMANENT_LOADER', false);
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    permanentLoading(state) {
      return state.permanentLoading;
    },
  },
};
