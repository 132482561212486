<template>
  <v-container fluid class="distance-scales-page">
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="4" v-for="item in data" :key="'vehicle_class' + item.vehicleClass.id">
        <validation-observer :ref="'form' + item.vehicleClass.id" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(() => saveDistanceScale(item))">
            <v-card class="my-5" id="distance-scales-step-0">
              <v-card-title class="text-body-1">
                {{ item.vehicleClass.name }}
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="auto" v-for="currency in item.currencies" :key="'currency' + currency.id">
                    <v-card>
                      <v-card-title class="text-body-1">
                        {{ currency.name + '(' + currency.symbol + ')' }}
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_0_5"
                            :currency="currency"
                            :label="'0-5'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_5_15"
                            :currency="currency"
                            :label="'5-15'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_15_50"
                            :currency="currency"
                            :label="'15-50'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_50_100"
                            :currency="currency"
                            :label="'50-100'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_100_150"
                            :currency="currency"
                            :label="'100-150'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_150_300"
                            :currency="currency"
                            :label="'150-300'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_300_500"
                            :currency="currency"
                            :label="'300-500'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_500_1000"
                            :currency="currency"
                            :label="'500-1000'" />
                          <distance-scale-price
                            v-model="currency.distanceScalePrices.price_1000"
                            :currency="currency"
                            :label="'1000+'" />
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <button-submit
                  :failed="failed"
                  :loading="loading[item.vehicleClass.id]"
                  buttonText="buttons.save"></button-submit>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour
        name="pricingZoneHourlyRelationsTour"
        :steps="stepsTour"
        :callbacks="myCallbacks"
        :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultPricingZoneHourlyRelation } from '@/mixins/default-items';
import { getAllTourSteps, distanceScalesPageSteps } from '@/mixins/onboarding-tutorial-steps';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import DistanceScalePrice from '@/components/zone-pricing/DistanceScalePrice.vue';
import { mapState } from 'vuex';

export default {
  name: 'DistanceScalesPage',
  components: { ButtonSubmit, DistanceScalePrice },
  data() {
    return {
      loading: {},
      pricingZoneRelations: [],
      pricingZones: [],
      vehicleClasses: [],
      currencies: [],
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      clients: [],
      priceRanges: ['0-5', '5-15', '15-50', '50-100', '100-150', '150-300', '300-500', '500-1000', '1000+'],
      data: [],
    };
  },
  async created() {
    this.selectedItem = Object.assign({}, { ...defaultPricingZoneHourlyRelation, zonePricingHourlyPrices: [] });
    await this.loadDistanceScaleData();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    ...mapState('auth', ['user']),
  },
  methods: {
    async loadDistanceScaleData() {
      await this.$store.dispatch('distanceScales/getDistanceScaleData').then((res) => {
        this.data = res.data.map((e) => {
          return e;
        });
      });

      this.setStepsAndStartTour();
    },

    async saveDistanceScale(item) {
      const vehicleClassId = item.vehicleClass.id;
      this.loading[vehicleClassId] = true;
      let path = 'saveDistanceScale';

      const payload = { vehicle_class_id: vehicleClassId, currencies: [] };

      // Iterate over currencies and transform the structure
      for (let i = 0; i < item.currencies.length; i++) {
        const currency = item.currencies[i];

        const transformedCurrency = {
          ...currency.distanceScalePrices,
          currency_id: currency.id,
        };

        payload.currencies.push(transformedCurrency);
      }

      await this.$store
        .dispatch('distanceScales/' + path, payload)
        .then(() => {
          this.loadDistanceScaleData();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading[vehicleClassId] = false;
        });
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '15')
            ?.map((item) => item.step_id);
          this.steps = distanceScalesPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['pricingZoneHourlyRelationsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.pricingZoneDialog = false;
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '15',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '15',
        step_id: `#distance-scales-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '15',
            step_id: `#distance-scales-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.distance-scales-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
  }
}
</style>
