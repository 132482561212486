<template>
  <div class="packages-wrapper">
    <!-- Tabs for switching between Monthly and Annual -->
    <v-tabs v-model="selectedTab" centered>
      <v-tab>{{ $t('packages.monthlyPricing') }}</v-tab>
      <v-tab>{{ $t('packages.annualPricing') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-row class="ma-5 packages-page" justify="center">
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="monthlyPackage5"
              :key="monthlyPackage5.id"
              @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="monthlyPackage10"
              :key="monthlyPackage10.id"
              @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="monthlyPackage25"
              :key="monthlyPackage25.id"
              @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="monthlyPackage50"
              :key="monthlyPackage50.id"
              @planSelected="handleSelection" />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row class="ma-5 packages-page" justify="center">
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage :single-package="annualPackage5" :key="annualPackage5.id" @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="annualPackage10"
              :key="annualPackage10.id"
              @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="annualPackage25"
              :key="annualPackage25.id"
              @planSelected="handleSelection" />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <SinglePackage
              :single-package="annualPackage50"
              :key="annualPackage50.id"
              @planSelected="handleSelection" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-container class="d-flex flex-column align-center">
      <p>{{ $t('packages.warningOneAnnualPackage') }}</p>
      <div class="d-flex align-center">
        <a :href="websiteUrl + '#contact'" target="_blank" rel="noreferrer">
          <v-btn class="primary">{{ $t('buttons.sendMessage') }}</v-btn>
        </a>
        <span class="ml-2">{{ $t('packages.warningTwoAnnualPackage') }}</span>
      </div>
    </v-container>
  </div>
</template>

<script>
import SinglePackage from '@/components/SinglePackage';
import { initializePaddle } from '@paddle/paddle-js';
import i18n from '@/i18n/i18n';
import state from '@/store/index';
import { checkoutSuccessCallBack } from '@/mixins/paddle-functions';

export default {
  name: 'PackagesPage',
  components: { SinglePackage },
  data: () => ({
    organisationEmail: '',
    annualPackageSolo: {
      name: 'packages.packageTypeSolo',
      price: '',
      price_id: null,
      id: 15,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.packageTypeSoloMainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    annualPackage5: {
      name: 'packages.packageType5',
      price: '',
      price_id: null,
      id: 5,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.packageType5MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    annualPackage10: {
      name: 'packages.packageType10',
      price: '',
      price_id: null,
      id: 9,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.packageType10MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    annualPackage25: {
      name: 'packages.packageType25',
      price: '',
      price_id: null,
      id: 10,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.packageType25MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights25',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    annualPackage50: {
      name: 'packages.packageType50',
      price: '',
      price_id: null,
      id: 11,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.packageType50MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights50',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    monthlyPackageSolo: {
      name: 'packages.packageTypeSolo', // todo update translations
      price: '',
      price_id: null,
      id: 16,
      disable: false,
      annualPackagesMessage: 'packages.monthlyPackagesMessage', // todo update translations
      mainMessage: 'packages.packageTypeSoloMainMessage', // todo update translations
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    monthlyPackage5: {
      name: 'packages.packageType5', // todo update translations
      price: '',
      price_id: null,
      id: 17,
      disable: false,
      annualPackagesMessage: 'packages.monthlyPackagesMessage', // todo update translations
      mainMessage: 'packages.packageType5MainMessage', // todo update translations
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    monthlyPackage10: {
      name: 'packages.packageType10', // todo update translations
      price: '',
      price_id: null,
      id: 12,
      disable: false,
      annualPackagesMessage: 'packages.monthlyPackagesMessage', // todo update translations
      mainMessage: 'packages.packageType10MainMessage', // todo update translations
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights10',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    monthlyPackage25: {
      name: 'packages.packageType25', // todo update translations
      price: '',
      price_id: null,
      id: 13,
      disable: false,
      annualPackagesMessage: 'packages.monthlyPackagesMessage', // todo update translations
      mainMessage: 'packages.packageType25MainMessage', // todo update translations
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights25',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },
    monthlyPackage50: {
      name: 'packages.packageType50', // todo update translations
      price: '',
      price_id: null,
      id: 14,
      disable: false,
      annualPackagesMessage: 'packages.monthlyPackagesMessage', // todo update translations
      mainMessage: 'packages.packageType50MainMessage', // todo update translations
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedRides',
        'packages.freeSmsFlights50',
        'packages.trialPeriod',
        'packages.onboardingFeeOptional',
        'packages.noHiddenCosts',
      ],
      currency: 'EUR',
    },

    planChangeDialog: false,
    selectedPlan: {},
    demoMessage: '',
    quantity: null,
    paddle: null,
    websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    selectedTab: 0,
  }),
  created() {
    this.loadAllPricingRegions();
    let config = {
      token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
    };

    if (process.env.VUE_APP_ENV !== 'production') {
      config.environment = 'sandbox';
    }

    initializePaddle({
      ...config,
      eventCallback: (data) => {
        checkoutSuccessCallBack(data, this.$store);
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        this.paddle = paddleInstance;
      }
    });
  },
  methods: {
    loadAllPricingRegions() {
      let activeSubscriptionType = parseInt(this.$store.getters['auth/subscription']?.subscriptionType?.id);
      if (activeSubscriptionType === 4) {
        this.demoMessage = 'packages.warningThree';
      }
      this.$store.dispatch('subscriptionPackages/getAllPricingRegions').then((res) => {
        this.annualPackageSolo.price = res.solo_price;
        this.annualPackageSolo.price_id = res.solo_paddle_price_id;
        this.annualPackageSolo.currency = res.currency;
        this.annualPackage5.price = res.team_5_price;
        this.annualPackage5.price_id = res.team_5_paddle_price_id;
        this.annualPackage5.currency = res.currency;
        this.annualPackage10.price = res.team_10_price;
        this.annualPackage10.price_id = res.team_10_paddle_price_id;
        this.annualPackage10.currency = res.currency;
        this.annualPackage25.price = res.team_25_price;
        this.annualPackage25.price_id = res.team_25_paddle_price_id;
        this.annualPackage25.currency = res.currency;
        this.annualPackage50.price = res.team_50_price;
        this.annualPackage50.price_id = res.team_50_paddle_price_id;
        this.annualPackage50.currency = res.currency;

        this.monthlyPackageSolo.price = res.solo_price_monthly;
        this.monthlyPackageSolo.price_id = res.solo_paddle_price_id_monthly;
        this.monthlyPackageSolo.currency = res.currency;
        this.monthlyPackage5.price = res.team_5_price_monthly;
        this.monthlyPackage5.price_id = res.team_5_paddle_price_id_monthly;
        this.monthlyPackage5.currency = res.currency;
        this.monthlyPackage10.price = res.team_10_price_monthly;
        this.monthlyPackage10.price_id = res.team_10_paddle_price_id_monthly;
        this.monthlyPackage10.currency = res.currency;
        this.monthlyPackage25.price = res.team_25_price_monthly;
        this.monthlyPackage25.price_id = res.team_25_paddle_price_id_monthly;
        this.monthlyPackage25.currency = res.currency;
        this.monthlyPackage50.price = res.team_50_price_monthly;
        this.monthlyPackage50.price_id = res.team_50_paddle_price_id_monthly;
        this.monthlyPackage50.currency = res.currency;
      });
    },
    handleSelection(value) {
      this.organisationEmail = this.$store.state.auth.user.organisation.email;
      if (value === this.annualPackage5.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage5);
      } else if (value === this.annualPackage10.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage10);
      } else if (value === this.annualPackage25.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage25);
      } else if (value === this.annualPackage50.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage50);
      } else if (value === this.monthlyPackage5.id) {
        this.selectedPlan = Object.assign({}, this.monthlyPackage5);
      } else if (value === this.monthlyPackage10.id) {
        this.selectedPlan = Object.assign({}, this.monthlyPackage10);
      } else if (value === this.monthlyPackage25.id) {
        this.selectedPlan = Object.assign({}, this.monthlyPackage25);
      } else if (value === this.monthlyPackage50.id) {
        this.selectedPlan = Object.assign({}, this.monthlyPackage50);
      }
      this.checkPackageChangeAvailability();
    },
    async checkPackageChangeAvailability() {
      await this.$store
        .dispatch('subscriptionPackages/checkPackageChangeAvailability', { id: this.selectedPlan.id })
        .then((response) => {
          if (response.success) {
            this.openPaddleModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.code)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },
    async openPaddleModal() {
      if (
        this.paddle &&
        this.selectedPlan.price_id &&
        [5, 6, 7, 8, 9, 10, 11, 12, 13, 14].includes(this.selectedPlan.id)
      ) {
        try {
          this.paddle.Checkout.open({
            settings: {
              displayMode: 'overlay',
              theme: 'light',
              allowLogout: false,
            },
            items: [
              {
                priceId: this.selectedPlan.price_id,
              },
            ],
            customer: {
              email: this.organisationEmail,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      }
    },
    async changeSubscription() {
      await this.$store
        .dispatch('subscriptionPackages/changePackage', { subscription_type_id: this.selectedPlan.id })
        .then(async (response) => {
          if (response.success) {
            state.dispatch('auth/setUser', {
              ...this.$store.state.auth.user,
              organisation: response.organisation,
            });
            localStorage.setItem(
              'LimoExpressUser',
              JSON.stringify({
                ...this.$store.state.auth.user,
                organisation: response.organisation,
              })
            );
            this.$store.dispatch('auth/setSubscription', response.data);
            state.dispatch('auth/setSubscriptionTypeId', response.subscription_type_id);
            localStorage.setItem('subscription', response.data ? JSON.stringify(response.data) : '');
            localStorage.setItem('subscription_type_id', response.subscription_type_id);
            state.dispatch('auth/setOrganisationType', response.organisation.type);
            localStorage.setItem('organisation_type', response.organisation.type);
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.planChanged'), color: 'green' });
          }
        });
    },
  },
  watch: {
    '$store.state.auth.paddleState': {
      handler() {
        this.changeSubscription();
      },
    },
  },
};
</script>

<style scoped>
.packages-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.selected-button {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 8px;
}

.not-selected-button {
  background-color: transparent !important;
  border: none;
  color: #333333 !important;
  box-shadow: none;
}
</style>
