import { getHttpClient } from './client';

export const getAllPassengers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/passenger';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const savePassenger = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/passenger', 'POST', data).then(
    (response) => response.data
  );
};

export const updatePassenger = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/passenger', 'POST', data).then(
    (response) => response.data
  );
};

export const deletePassenger = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/passenger/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const searchPassengers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/search-passengers';

  let queryString = '?';
  if (data) {
    if (data.phone) {
      queryString += `phone_number=${data.phone}`;
    }
    if (data.email) {
      queryString += `&email=${data.email}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
