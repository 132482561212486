<template>
  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
    <v-form lazy-validation @submit.prevent="handleSubmit(save)">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" hidden md="6" sm="12">
              <v-text-field v-model="editedItem.id" :label="$t('organisationsPage.id')"></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="organisation">
                <v-text-field
                  v-model="editedItem.name"
                  name="name"
                  autofocus
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.name')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="address">
                <v-text-field
                  v-model="editedItem.address"
                  name="address"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.address')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider
                :rules="{
                  max: 200,
                  required: true,
                  regex: /^\+?[0-9\s\-()]+$/,
                }"
                v-slot="{ errors }"
                name="phone_number">
                <v-text-field
                  type="tel"
                  name="phone"
                  v-model="editedItem.phone_number"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.phoneNumber')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email" ref="emailRef">
                <v-text-field
                  type="email"
                  name="email"
                  v-model="editedItem.email"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.email')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="vat" ref="vatRef">
                <v-text-field
                  v-model="editedItem.vat"
                  name="vat"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.vat')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="company_id" ref="companyIdRef">
                <v-text-field
                  v-model="editedItem.company_id"
                  name="company_id"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.companyId')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="warrant_number">
                <v-text-field
                  v-model="editedItem.warrant_number"
                  name="warrant_number"
                  clearable
                  counter="200"
                  :label="$t('organisationsPage.warrantNumber')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                <v-file-input
                  v-model="editedItem.image"
                  name="image"
                  accept="image/jpg, image/jpeg, image/png"
                  counter
                  :label="$t('organisationsPage.image')"
                  prepend-icon="mdi-camera"
                  show-size
                  small-chips
                  :error-messages="errors"></v-file-input>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-autocomplete
                v-model="editedItem.type"
                :items="organisationTypes"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('organisationsPage.organisationType')"
                required
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-autocomplete
                v-model="editedItem.subscription_type_id"
                :items="subscriptionTypes"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('organisationsPage.subscriptionType')"
                required
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-menu
                v-model="billingDate"
                ref="billingDateFilter"
                :close-on-content-click="false"
                :return-value.sync="editedItem.billing_date"
                min-width="auto"
                offset-y
                transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.billing_date"
                    clearable
                    :label="$t('organisationsPage.billingDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <DatePicker
                  v-model="editedItem.billing_date"
                  :model-config="filterModelConfig"
                  mode="date"
                  :first-day-of-week="firstDay"
                  @input="updateBillingDateFilter(editedItem.billing_date)">
                </DatePicker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <v-menu
                v-model="trialEndsAt"
                ref="trialEndsAtFilter"
                :close-on-content-click="false"
                :return-value.sync="editedItem.trial_ends_at"
                min-width="auto"
                offset-y
                transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.trial_ends_at"
                    clearable
                    :label="$t('organisationsPage.trialEndsAt')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <DatePicker
                  v-model="editedItem.trial_ends_at"
                  :model-config="filterModelConfig"
                  mode="date"
                  :first-day-of-week="firstDay"
                  @input="updateTrialEndsAtFilter(editedItem.trial_ends_at)">
                </DatePicker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="required" v-slot="{ errors }" name="country">
                <v-autocomplete
                  v-model="editedItem.country_id"
                  :items="countries"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('organisationsPage.country')"
                  required
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"
                  :error-messages="errors"></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="required" v-slot="{ errors }" name="language">
                <v-autocomplete
                  name="language"
                  clearable
                  item-text="lang"
                  item-value="abbr"
                  v-model="editedItem.language"
                  :items="languageOptions"
                  :label="$t('organisationsPage.language')"
                  prepend-icon="mdi-web"
                  :error-messages="errors"></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="6" md="6" sm="12">
              <validation-provider rules="required" v-slot="{ errors }" name="referent">
                <v-autocomplete
                  v-model="editedItem.referent_id"
                  :items="referents"
                  clearable
                  item-text="profile.full_name"
                  item-value="id"
                  :label="$t('organisationsPage.referent')"
                  required
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"
                  :error-messages="errors"></v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12" class="gnet-wrapper">
              <validation-provider rules="max:200" v-slot="{ errors }" name="gnet_id" ref="gnetIdRef">
                <v-text-field
                  v-model="editedItem.gnet_id"
                  name="gnet_id"
                  :disabled="editedItem.gnetExists"
                  counter="200"
                  :label="$t('organisationsPage.gnetId')"
                  clearable
                  :error-messages="errors"></v-text-field>
                <v-icon v-if="editedItem.gnetExists && gnetIdValid !== '' && !gnetIdValid" color="green"
                  >mdi-alert-circle</v-icon
                >
                <v-icon v-if="editedItem.gnetExists && gnetIdValid !== '' && gnetIdValid" color="red"
                  >mdi-check-circle</v-icon
                >
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" class="d-flex align-center">
              <v-btn @click="openMapModal">
                <v-icon left>mdi-map-marker</v-icon>
                {{ $t('organisationsPage.selectLocation') }}
              </v-btn>

              <!-- Modal -->
              <v-dialog v-model="mapModalVisible" max-width="700px" :key="mapModalVisible">
                <v-card>
                  <v-card-title class="info title white--text font-weight-bold">
                    <span class="text-h6">{{ $t('organisationsPage.selectLocation') }}</span>
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="closeMapModal"></v-icon>
                  </v-card-title>
                  <v-card-text class="px-0">
                    <!-- Google Map Component -->
                    <organisation-location ref="googleMap" :hq_lat="editedItem.hq_lat" :hq_lng="editedItem.hq_lng" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="primary cancel" @click="closeMapModal">{{ $t('buttons.cancel') }}</v-btn>
                    <v-btn class="primary" @click="confirmLocation">{{ $t('buttons.confirm') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" v-if="editedItem.referral_name">
              <h3>{{ $t('organisationsPage.referral') + ': ' + editedItem.referral_name }}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import { DatePicker } from 'v-calendar/src/components';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';
import { languageOptions } from '@/mixins/language-options';
import store from '@/store';
import { formatDateToDDMM } from '@/utils/formatDate';
import OrganisationLocation from '@/components/organisations/OrganisationLocation.vue';

export default {
  name: 'OrganisationsForm',
  components: { ButtonSubmit, DatePicker, OrganisationLocation },
  props: ['editedItem', 'subscriptionTypes', 'countries', 'dialog', 'referents'],
  data: () => ({
    billingDate: null,
    trialEndsAt: null,
    loading: false,
    filterModelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
    languageOptions: languageOptions,
    gnetIdValid: '',
    organisationTypes: [
      { id: 'regular', name: i18n.t('organisationsPage.regular') },
      { id: 'affiliate_sender', name: i18n.t('organisationsPage.affiliateSender') },
      { id: 'affiliate_receiver', name: i18n.t('organisationsPage.affiliateReceiver') },
      { id: 'dispatcher', name: i18n.t('organisationsPage.dispatcher') },
    ],
    mapModalVisible: false,
    selectedLocation: null,
  }),
  created() {
    this.editedItem.gnetExists && this.checkIfGnetIsValid();
  },
  computed: {},
  methods: {
    createFormData() {
      let formData = new FormData();
      if (this.editedItem.image) {
        formData.append('logo', this.editedItem.image);
      }
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }
      formData.append('name', this.editedItem.name);
      formData.append('address', this.editedItem.address);
      formData.append('phone_number', this.editedItem.phone_number);
      formData.append('email', this.editedItem.email);
      formData.append('vat', this.editedItem.vat ? this.editedItem.vat : '');
      formData.append('company_id', this.editedItem.company_id ? this.editedItem.company_id : '');
      formData.append('warrant_number', this.editedItem.warrant_number ? this.editedItem.warrant_number : '');
      formData.append(
        'billing_date',
        this.editedItem.billing_date ? formatDateToDDMM(this.editedItem.billing_date) : ''
      );
      formData.append(
        'trial_ends_at',
        this.editedItem.trial_ends_at ? formatDateToDDMM(this.editedItem.trial_ends_at) : ''
      );
      formData.append(
        'subscription_type_id',
        this.editedItem.subscription_type_id ? this.editedItem.subscription_type_id : ''
      );
      formData.append('type', this.editedItem.type ? this.editedItem.type : '');
      formData.append('country_id', this.editedItem.country_id ? this.editedItem.country_id : '');
      formData.append('language', this.editedItem.language ? this.editedItem.language : '');
      formData.append('referent_id', this.editedItem.referent_id ? this.editedItem.referent_id : '');
      if (!this.editedItem?.gnetExists) {
        formData.append('gnet_id', this.editedItem.gnet_id ? this.editedItem.gnet_id : '');
      }
      formData.append('hq_lat', this.selectedLocation?.lat ? this.selectedLocation?.lat : '');
      formData.append('hq_lng', this.selectedLocation?.lng ? this.selectedLocation?.lng : '');

      return formData;
    },

    async save() {
      this.loading = true;
      let path = 'saveOrganisation';

      if (this.editedItem.id) {
        path = 'updateOrganisation';
      }
      let formData = this.createFormData();

      await this.$store
        .dispatch('organisations/' + path, formData)
        .then(() => {
          this.$emit('loadAllOrganisations');
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$emit('closeDialog');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (
              error.response.data.errors?.vat ||
              error.response.data.errors?.company_id ||
              error.response.data.errors?.email
            ) {
              if (error.response.data.errors?.vat && error.response.data.errors?.vat[0] === 'ORGANISATION-0017') {
                this.$refs.vatRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.vat'),
                    }),
                  ],
                });
              }
              if (
                error.response.data.errors?.company_id &&
                error.response.data.errors?.company_id[0] === 'ORGANISATION-0018'
              ) {
                this.$refs.companyIdRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.company_id'),
                    }),
                  ],
                });
              }
              if (error.response.data.errors?.email && error.response.data.errors?.email[0] === 'ORGANISATION-0019') {
                this.$refs.emailRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.email'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateBillingDateFilter(billingDate) {
      this.$refs.billingDateFilter.save(billingDate);
      this.selectedBillingDate = billingDate;
    },

    updateTrialEndsAtFilter(trialEndsAt) {
      this.$refs.trialEndsAtFilter.save(trialEndsAt);
    },

    async checkIfGnetIsValid() {
      await this.$store
        .dispatch('gnet/checkHealth')
        .then((res) => {
          if (JSON.parse(res.data).success === true) {
            this.gnetIdValid = true;
          } else {
            this.gnetIdValid = false;
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    openMapModal() {
      this.mapModalVisible = true;
    },
    closeMapModal() {
      this.mapModalVisible = false;
    },
    confirmLocation() {
      // Access the selected location from the map
      const location = this.$refs.googleMap.location;
      if (location) {
        this.selectedLocation = location;
      }
      this.closeMapModal();
    },
  },

  watch: {
    dialog(val) {
      !val && this.$refs.form.reset();

      val && this.editedItem.gnetExists && this.checkIfGnetIsValid();
    },
  },
};
</script>

<style scoped lang="scss">
.stats {
  font-family: 'Inter', 'Roboto', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cell-style {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.active {
  cursor: pointer;
}

.inactive td:not(:last-child) {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.active {
  background-color: white !important;
}

.gnet-wrapper {
  position: relative;

  i {
    position: absolute;
    right: 12px;
    top: 46%;
    transform: translateY(-46%);
  }
}
</style>
