<template>
  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
    <v-form lazy-validation @submit.prevent="handleSubmit(save)">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="first_name">
                <v-text-field
                  v-model="editedItem.first_name"
                  name="first_name"
                  clearable
                  counter="200"
                  autofocus
                  :label="$t('formsAndTables.firstName')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="last_name">
                <v-text-field
                  v-model="editedItem.last_name"
                  name="last_name"
                  clearable
                  counter="200"
                  :label="$t('formsAndTables.lastName')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider
                :rules="{
                  max: 200,
                  required: true,
                  regex: /^\+?[0-9\s\-()]+$/,
                }"
                v-slot="{ errors }"
                name="phone_number">
                <v-text-field
                  v-model="editedItem.phone"
                  name="phone"
                  clearable
                  counter="200"
                  :label="$t('formsAndTables.phoneNumber')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email">
                <v-text-field
                  v-model="editedItem.email"
                  name="email"
                  clearable
                  counter="200"
                  :label="$t('formsAndTables.email')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="passport">
                <v-text-field
                  v-model="editedItem.passport"
                  name="passport"
                  clearable
                  counter="200"
                  :label="$t('formsAndTables.passport')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-autocomplete
                v-model="editedItem.country_id"
                :items="countries"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('formsAndTables.country')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="nationality">
                <v-text-field
                  v-model="editedItem.nationality"
                  name="nationality"
                  clearable
                  counter="200"
                  :label="$t('formsAndTables.nationality')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'PassengersForm',
  components: { ButtonSubmit },
  props: ['editedItem', 'countries'],
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async save() {
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('passengers/savePassenger', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$emit('loadAllPassengers');
          this.$emit('closePassengerEdit');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append('first_name', this.editedItem.first_name ? this.editedItem.first_name : '');
      formData.append('last_name', this.editedItem.last_name ? this.editedItem.last_name : '');
      formData.append('phone', this.editedItem.phone ? this.editedItem.phone : '');
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      formData.append('passport', this.editedItem.passport ? this.editedItem.passport : '');
      formData.append('country_id', this.editedItem.country_id ? this.editedItem.country_id : '');
      formData.append('nationality', this.editedItem.nationality ? this.editedItem.nationality : '');

      return formData;
    },

    resetForm() {
      this.$refs.form.reset();
    },
  },
};
</script>
