import { getHttpClient } from './client';

export const loadDrivingDetails = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/driving-details/';
  return await getHttpClient(baseUrl + data.driving_id).then((response) => response.data);
};

export const getAllVehicleClasses = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/vehicle-class';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getOrganisationCurrencies = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/organisation-currency';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveDriving = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/save-driving-and-proposal', 'POST', data).then(
    (response) => response.data
  );
};

export const cancelDriving = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/cancel-driving/' + data.id;
  return await getHttpClient(baseUrl, 'POST').then((response) => response.data);
};

export const getAllInvoices = async () => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/invoice';
  return await getHttpClient(baseUrl).then((response) => response.data);
};

export const getDriversGpsData = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/get-drivers-gps-data';

  let queryString = '?';
  if (data) {
    if (data.affiliate) {
      queryString += `affiliate=${data.affiliate}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const requestDriverLocation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/customer/request-driver-location/' + data.id).then(
    (response) => response.data
  );
};

export const searchPassengers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/search-passengers';

  let queryString = '?';
  if (data) {
    if (data.phone) {
      queryString += `phone_number=${data.phone}`;
    }
    if (data.email) {
      queryString += `&email=${data.email}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};